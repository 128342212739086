import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import CompanyModel from '@/models/CompanyModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
import User from '@/types/user'
import axios from 'axios'
moment.locale('nl')

Vue.use(Vuex)

export const companyStore: Module<any, any> = {
  state: {
    companyList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentCompany: {
      reference: null,
      name: null,
      address: null,
      zipcode: null,
      city: null,
      po_box: null,
      po_box_zipcode: null,
      po_box_city: null,
      phone: null,
      email: null,
      website: null,
      coc: null,
      vat: null,
      active: true,
      user_ids: [],
      users: [],
      construction_workers: []
    },
    loading: {
      list: false,
      item: false,
      save: false,
      syncing: false
    },
    errors: {}
  },
  mutations: {
    setCurrentCompany (state, { currentCompany }) {
      state.currentCompany = currentCompany
    },
    clearCurrentCompany (state) {
      state.currentCompany = {
        reference: null,
        name: null,
        address: null,
        zipcode: null,
        city: null,
        po_box: null,
        po_box_zipcode: null,
        po_box_city: null,
        phone: null,
        email: null,
        website: null,
        coc: null,
        vat: null,
        active: true,
        user_ids: [],
        users: [],
        construction_workers: []
      }
    },
    setCompanyList (state, { companyList }) {
      state.companyList = companyList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    employeeAddedToCompany (state, { user }) {
      if (!state.currentCompany.user_ids.includes(user.id)) {
        state.currentCompany.user_ids.push(user.id)
        state.currentCompany.users.push(user)
      }
    },
    employeeDeletedFromCompany (state, { id }) {
      state.currentCompany.user_ids.splice(state.currentCompany.user_ids.indexOf(id), 1)

      let userIndex = null
      state.currentCompany.users.forEach((user:User, index:number) => {
        if (user.id === id) {
          userIndex = index
        }
      })

      if (userIndex !== null) {
        state.currentCompany.users.splice(userIndex, 1)
      }
    }
  },
  actions: {
    getCompanyList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let companyModel = new CompanyModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            companyModel = companyModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        companyModel = companyModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        companyModel = companyModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        companyModel = companyModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      companyModel.get().then((companyList) => {
        commit('setCompanyList', { companyList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van bedrijven!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentCompany ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        CompanyModel.$find(id).then((currentCompany) => {
          commit('setCurrentCompany', { currentCompany })
          resolve(currentCompany)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit bedrijf!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentCompany ({ commit }) {
      commit('clearCurrentCompany')
    },
    saveCompany ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const companyModel = new CompanyModel(data)

      // Save company.
      companyModel.save().then((currentCompany) => {
        commit('setErrors', {})
        commit('setCurrentCompany', { currentCompany })
        if (data.id) {
          notification.success({ message: 'Bedrijf opgeslagen.', description: 'Het bedrijf is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bedrijf toegevoegd.', description: 'Het bedrijf is succesvol toegevoegd.', duration: 3 })
          if (currentCompany.id) {
            router.push({ name: 'companyEdit', params: { companyId: currentCompany.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit bedrijf!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit bedrijf!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    employeeAddedToCompany ({ commit }, user) {
      commit('employeeAddedToCompany', { user })
    },
    employeeDeletedFromCompany ({ commit }, id) {
      commit('employeeDeletedFromCompany', { id })
    },
    syncCompany ({ commit }, companyId) {
      commit('setLoading', { type: 'syncing', state: true })
      axios.get(process.env.VUE_APP_API_URL + '/companies/' + companyId + '/sync').then((r) => {
        const currentCompany = r.data.data
        commit('setCurrentCompany', { currentCompany })
      }).catch(() => {
        // TODO: Create error message.
      }).finally(() => {
        commit('setLoading', { type: 'syncing', state: false })
      })
    }
  },
  getters: {
    companyList: state => state.companyList,
    currentCompany: state => state.currentCompany,
    loadingCompany: state => state.loading,
    companyErrors: state => state.errors
  }
}
