import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { notification } from 'ant-design-vue'
import moment from 'moment/moment'
import 'moment/locale/nl'
import router from '@/router'
import QuestionModel from '@/models/QuestionModel'
import SecureLS from 'secure-ls'
import { ServiceStates } from '@/types/serviceItem'
import Question from '@/types/question'
import axios from 'axios'
moment.locale('nl')

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })

export const questionStore: Module<any, any> = {
  state: {
    questionList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    InactiveQuestionColumns: ls.get('questions-columns') || [],
    currentQuestion: new Question(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    questionManagers: []
  },
  mutations: {
    setCurrentQuestion (state, { currentQuestion }) {
      state.currentQuestion = currentQuestion
    },
    clearCurrentQuestion (state) {
      state.currentQuestion = new Question()
    },
    setQuestionList (state, { questionList }) {
      state.questionList = questionList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    setQuestionFiles (state, files) {
      state.currentQuestion.files = files
    },
    enabledQuestionOptionsChanged (state, { enabledOptions }) {
      state.currentQuestion.enabled_options = enabledOptions
    },
    setInactiveQuestionColumns (state, columns) {
      state.InactiveQuestionColumns = columns
    },
    setQuestionEstimate (state, date) {
      state.currentQuestion.estimate = date
    },
    setQuestionProject (state, id) {
      state.currentQuestion.project_id = id
    },
    setQuestionBuildingId (state, id) {
      state.currentQuestion.building_id = id
    },
    setQuestionBuilding (state, building) {
      state.currentQuestion.building = building
    },
    setQuestionManagers (state, managers) {
      state.questionManagers = managers
    },
    setQuestionManager (state, id) {
      state.currentQuestion.manager_id = id
    },
    setQuestionStatus (state, statusId) {
      state.currentQuestion.status = statusId
    }
  },
  actions: {
    getQuestionList ({ commit }, settings) {
      commit('setLoading', { type: 'list', state: true })
      let questionModel = new QuestionModel()

      if (settings.activeFilters) {
        // Check if we want the archived items
        Object.keys(settings.archiveConditions).forEach(key => {
          if (settings.archiveConditions[key].length) {
            questionModel = questionModel.whereIn(key,
              Object.values(ServiceStates).filter(value => settings.archived
                ? settings.archiveConditions[key].includes(value)
                : !settings.archiveConditions[key].includes(value)))
          }
        })

        // Then check if they need to be filtered.
        Object.keys(settings.activeFilters).forEach(key => {
          if (settings.activeFilters[key].length) {
            questionModel = questionModel.whereIn(key, settings.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (settings.search) {
        questionModel = questionModel.where('search', settings.search)
      }

      // Add orderBy if sort is set.
      if (settings.sort) {
        questionModel = questionModel.orderBy(settings.sort.order === 'ascend' ? settings.sort.columnKey : '-' + settings.sort.columnKey)
      }

      if (settings.pagination) {
        questionModel = questionModel.limit(settings.pagination.pageSize).page(settings.pagination.current)
      }

      questionModel.get().then((questionList) => {
        commit('setQuestionList', { questionList: questionList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de vragen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentQuestion ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        QuestionModel.$find(id).then((currentQuestion) => {
          commit('setCurrentQuestion', { currentQuestion: currentQuestion })
          resolve(currentQuestion)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit question!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentQuestion ({ commit }) {
      commit('clearCurrentQuestion')
    },
    saveQuestion ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { has_new_message, comments, ...payload } = data
      const questionModel = new QuestionModel(payload)

      // Save question.
      questionModel.save().then((currentQuestion) => {
        commit('setErrors', {})
        commit('setCurrentQuestion', { currentQuestion: currentQuestion })
        if (data.id) {
          notification.success({ message: 'Vraag opgeslagen.', description: 'De vraag is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Vraag toegevoegd.', description: 'De vraag is succesvol toegevoegd.', duration: 3 })
          if (currentQuestion.id) {
            router.push({ name: 'questionEdit', params: { questionId: currentQuestion.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze vraag!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze vraag!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    getQuestionManagers ({ commit, state }, projectId) {
      if (projectId === undefined) return
      commit('setErrors', {})
      axios.get(`${process.env.VUE_APP_API_URL}/members/${projectId}/mentions`)
        .then((res) => {
          commit('setQuestionManagers', res.data)
          if (state.currentQuestion.manager_id === 0 && res.data.length > 0) {
            state.currentQuestion.manager_id = res.data[0].id
          }
        })
    },
    setQuestionFiles ({ commit }, media) {
      commit('setQuestionFiles', media)
    },
    setInactiveQuestionColumns ({ commit }, columns) {
      ls.set('questions-columns', columns)
      commit('setInactiveQuestionColumns', columns)
    },
    enabledQuestionOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledQuestionOptionsChanged', { enabledOptions })
    },
    setQuestionEstimate ({ commit }, date) {
      commit('setQuestionEstimate', date)
    },
    setQuestionProject ({ commit }, id) {
      commit('setQuestionProject', id)
    },
    setQuestionBuildingId ({ commit }, id) {
      commit('setQuestionBuildingId', id)
    },
    setQuestionBuilding ({ commit }, building) {
      commit('setQuestionBuilding', building)
    },
    setQuestionStatus ({ commit }, statusId) {
      commit('setQuestionStatus', statusId)
    },
    setQuestionManager ({ commit }, id) {
      commit('setQuestionManager', id)
    }
  },
  getters: {
    questionList: state => state.questionList,
    currentQuestion: state => state.currentQuestion,
    questionLoading: state => state.loading,
    questionErrors: state => state.errors,
    questionManagers: state => state.questionManagers,
    InactiveQuestionColumns: state => state.InactiveQuestionColumns
  }
}
