import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import MessageModel from '@/models/MessageModel'
import { notification } from 'ant-design-vue'
import moment from 'moment'
import 'moment/locale/nl'
import router from '@/router'
moment.locale('nl')

Vue.use(Vuex)

export const messageStore: Module<any, any> = {
  state: {
    messageList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentMessage: {
      title: null,
      body: null,
      can_respond: false,
      project_id: null,
      project: {
        name: '',
        reference: ''
      },
      building_id: null,
      building_ids: [], // Used for messageCreate for multiple buildings.
      building: {
        number: 0
      },
      status: 0,
      created_by_user: {
        full_name: '-'
      },
      media: [],
      files: [],
      comments: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentMessage (state, { currentMessage }) {
      currentMessage.scheduled_at = currentMessage.scheduled_at ? new Date(currentMessage.scheduled_at).toLocaleString('nl-NL') : null
      currentMessage.publish_at = currentMessage.publish_at ? new Date(currentMessage.publish_at).toLocaleString('nl-NL') : null
      currentMessage.read_at = currentMessage.read_at ? new Date(currentMessage.read_at).toLocaleString('nl-NL') : null
      currentMessage.created_at = currentMessage.created_at ? new Date(currentMessage.created_at).toLocaleString('nl-NL') : null
      state.currentMessage = currentMessage
    },
    clearCurrentMessage (state) {
      state.currentMessage = {
        title: null,
        body: null,
        can_respond: false,
        project_id: null,
        project: {
          name: '',
          reference: ''
        },
        building_id: null,
        building_ids: [], // Used for messageCreate for multiple buildings.
        building: {
          number: 0
        },
        status: 0,
        created_by_user: {
          full_name: '-'
        },
        media: [],
        files: [],
        comments: []
      }
    },
    setMessageList (state, { messageList }) {
      state.messageList = messageList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getMessageList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let messageModel = new MessageModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            messageModel = messageModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        messageModel = messageModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        messageModel = messageModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        messageModel = messageModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      messageModel.get().then((messageList) => {
        commit('setMessageList', { messageList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van berichten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentMessage ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        MessageModel.$find(id).then((currentMessage) => {
          commit('setCurrentMessage', { currentMessage })
          resolve(currentMessage)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit bericht!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentMessage ({ commit }) {
      commit('clearCurrentMessage')
    },
    saveMessage ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })

      if (data.files.length) {
        data.scheduled_at = data.scheduled_at._d
      }

      const messageModel = new MessageModel(data)

      // Save message.
      messageModel.save().then((currentMessage) => {
        commit('setErrors', {})
        if (data.id) {
          commit('setCurrentMessage', { currentMessage })
          notification.success({ message: 'Bericht opgeslagen.', description: 'Het bericht is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bericht toegevoegd.', description: 'Het bericht is succesvol toegevoegd.', duration: 3 })
          if (currentMessage.id) {
            router.push({ name: 'messages' })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit bericht!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit bericht!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    messageList: state => state.messageList,
    currentMessage: state => state.currentMessage,
    loadingMessage: state => state.loading,
    messageErrors: state => state.errors
  }
}
