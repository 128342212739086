
















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Feedback, { FeedbackClasses, SortedLabels, SortedFeedbackStates, WoononApps } from '@/types/feedback'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import Filelist from '@/components/media/Filelist.vue'
import { notification } from 'ant-design-vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import { Status } from '@/types/serviceItem'
import Uploader from '@/components/media/Uploader.vue'
import FeedbackTasks from '@/views/feedback/FeedbackTasks.vue'
import { Route } from 'vue-router'
import { NavigationGuardNext } from 'vue-router/types/router'

@Component({
  components: { FeedbackTasks, Uploader, ActivityLogTable, Filelist, TextEditor }
})

export default class FeedbackShow extends Vue {
  settings = {
    routeNames: { list: 'feedbacks', show: 'feedbackShow', edit: 'feedbackEdit' },
    routeParamKey: 'feedbackId',
    functionNames: { setCurrent: 'setCurrentFeedback', clearCurrent: 'clearCurrentFeedback', save: 'saveFeedback' },
    getterNames: { current: 'currentFeedback', loading: 'loadingFeedback' }
  }

  feedbackStates = FeedbackClasses
  sortedFeedbackClasses = SortedFeedbackStates
  sortedLabels = SortedLabels

  currentTab:string = new SecureLS({ isCompression: false }).get('feedbackEditActiveTab') || 'details'

  employees:User[] = []
  developers:User[] = []
  woononApps = WoononApps

  categories:{ id:number, name:string, slug:string }[] = []
  showDescriptionEditor = false
  showSolutionEditor = false
  newComment = null

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    this.getEmployees()
    this.getDevelopers()
    this.getCategories()
  }

  get createdBy (): string {
    return this.currentItem.created_by_user ? this.currentItem.created_by_user.full_name : ''
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('feedbacks.edit')
  }

  get canView (): boolean {
    return this.authUser.permissions.includes('feedbacks.view')
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  beforeRouteUpdate (to: Route, from: Route, next: NavigationGuardNext): void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, to.params.feedbackId)
    next()
  }

  refresh (): void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch('saveFeedback', this.currentItem)
    this.showDescriptionEditor = false
    this.showSolutionEditor = false
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.name]=employee&limit=9999').then((res) => {
      this.employees = res.data.data
    })
  }

  getDevelopers () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.name]=developer').then((res) => {
      this.developers = res.data.data
    })
  }

  get sortedCategories (): { id:number, name:string, slug:string }[] {
    return this.categories.sort((a: { name: string }, b: { name: string }) => { return a.name.localeCompare(b.name) })
  }

  getCategories () : void {
    axios.get(process.env.VUE_APP_API_URL + '/feedbacks/categories').then((res) => {
      this.categories = res.data
    })
  }

  getStatus (id: number):Status {
    return FeedbackClasses.filter(s => s.id === id)[0] || FeedbackClasses[0]
  }

  reloadFeedback () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/feedbacks/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.reloadFeedback()
    })
  }

  addComment () : void {
    axios.post(process.env.VUE_APP_API_URL + '/feedbacks/' + this.$route.params[this.settings.routeParamKey] + '/comments', { message: this.newComment }).then(() => {
      this.newComment = null
      notification.success({ message: 'Reactie opgeslagen.', description: 'De reactie is succesvol opgeslagen.', duration: 3 })
      this.reloadFeedback()
    })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('feedbackEditActiveTab', tab)
    this.currentTab = tab
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  disconnect (feedbackId : number): void {
    axios.post(`${process.env.VUE_APP_API_URL}/feedbacks/${feedbackId}/task`, { parent_id: null })
    this.$store.dispatch('disconnectFeedback')
    this.refresh()
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Feedback {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
