
























import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Survey from '@/types/survey'
import axios from 'axios'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist }
})

export default class SurveyEditResultMedia extends Vue {
  @Prop() answer!:{ id:number, answer:string, note:string, media:{ id:number, url:string, name:string, mime_type:string, size:string, note:string|null, created_at:string }[] }

  settings = {
    routeNames: { list: 'surveys', show: 'surveyShow', edit: 'surveyEdit' },
    routeParamKey: 'surveyId',
    functionNames: { setCurrent: 'setCurrentSurvey', save: 'saveSurvey' },
    getterNames: { current: 'currentSurvey', loading: 'loadingSurvey', errors: 'surveyErrors' }
  }

  showPreviewModal = false
  file:{ id:number, url:string, name:string, mime_type:string, size:string, note:string|null, created_at:string }|null = null

  togglePreviewModal (file:{ id:number, url:string, name:string, mime_type:string, size:string, note:string|null, created_at:string }|null) : void {
    if (!this.showPreviewModal && file) {
      if (file.mime_type === 'application/pdf') {
        window.open(file.url, '_blank')
      } else {
        this.file = file
        this.showPreviewModal = true
      }
    } else {
      this.file = null
      this.showPreviewModal = false
    }
  }

  get currentItem () : Survey {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
