
































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import DemoConfig from '@/types/demoConfig'

@Component({
  components: {}
})

export default class DemoConfigShowDetailsCard extends Vue {
  settings = {
    routeNames: { list: 'demoConfigs', show: 'demoConfigShow', edit: 'demoConfigEdit' },
    routeParamKey: 'demoConfigId',
    functionNames: { setCurrent: 'setCurrentDemoConfig', save: 'saveDemoConfig' },
    getterNames: { current: 'currentDemoConfig', loading: 'loadingDemoConfig' }
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : DemoConfig {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get closingDateModular () : string {
    if (this.currentItem.building && this.currentItem.building.end_modular) {
      return new Date(this.currentItem.building.end_modular).toLocaleString('nl-NL')
    } else {
      return '-'
    }
  }

  get closingDateCarcass () : string {
    if (this.currentItem.building && this.currentItem.building.end_carcass) {
      return new Date(this.currentItem.building.end_carcass).toLocaleString('nl-NL')
    } else {
      return '-'
    }
  }

  get closingDateCompletion () : string {
    if (this.currentItem.building && this.currentItem.building.end_completion) {
      return new Date(this.currentItem.building.end_completion).toLocaleString('nl-NL')
    } else {
      return '-'
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
