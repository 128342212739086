import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import CompanyUserModel from '@/models/CompanyUserModel'
import router from '@/router'

Vue.use(Vuex)

export const companyUserStore: Module<any, any> = {
  state: {
    companyUserList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentCompanyUser: {
      firstname: null,
      lastname: null,
      full_name: null,
      username: null,
      password: null,
      password_confirmation: null,
      company_id: null,
      role_ids: [],
      permission_ids: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentCompanyUser (state, { currentCompanyUser }) {
      currentCompanyUser.password = null
      currentCompanyUser.password_confirmation = null
      state.currentCompanyUser = currentCompanyUser
    },
    clearCurrentCompanyUser (state) {
      state.currentCompanyUser = {
        firstname: null,
        lastname: null,
        full_name: null,
        username: null,
        password: null,
        password_confirmation: null,
        company_id: null,
        role_ids: [],
        permission_ids: []
      }
    },
    setCompanyUserList (state, { companyUserList }) {
      state.companyUserList = companyUserList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getCompanyUserList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let companyUserModel = new CompanyUserModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            companyUserModel = companyUserModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        companyUserModel = companyUserModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        companyUserModel = companyUserModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        companyUserModel = companyUserModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      companyUserModel.get().then((companyUserList) => {
        commit('setCompanyUserList', { companyUserList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van externe gebruikers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentCompanyUser ({ commit }, id) {
      commit('setErrors', {})
      commit('setLoading', { type: 'item', state: true })
      CompanyUserModel.$find(id).then((currentCompanyUser) => {
        commit('setCurrentCompanyUser', { currentCompanyUser })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze externe gebruiker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentCompanyUser ({ commit }) {
      commit('clearCurrentCompanyUser')
    },
    saveCompanyUser ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const companyUserModel = new CompanyUserModel(data)

      // Save user.
      companyUserModel.save().then((currentCompanyUser) => {
        commit('setErrors', {})
        commit('setCurrentCompanyUser', { currentCompanyUser })
        if (data.id) {
          notification.success({ message: 'Externe gebruiker opgeslagen.', description: 'De externe gebruiker is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Externe gebruiker toegevoegd.', description: 'De externe gebruiker is succesvol toegevoegd.', duration: 3 })
          if (currentCompanyUser.id) {
            router.push({ name: 'companyUserEdit', params: { companyUserId: currentCompanyUser.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze externe gebruiker!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze externe gebruiker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    companyUserList: state => state.companyUserList,
    currentCompanyUser: state => state.currentCompanyUser,
    loadingCompanyUser: state => state.loading,
    companyUserErrors: state => state.errors
  }
}
