














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})

export default class LoadingCard extends Vue {
  @Prop() title!:string
}
