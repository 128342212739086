























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Element from '@/types/element'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class ElementEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'elements', show: 'elementShow', edit: 'elementEdit' },
    routeParamKey: 'elementId',
    functionNames: { setCurrent: 'setCurrentElement', save: 'saveElement' },
    getterNames: { current: 'currentElement', loading: 'loadingElement', errors: 'elementErrors' }
  }

  contentChanged (key:string, value:string) : void {
    this.currentItem[key] = value
  }

  get currentItem () : Element {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
