











































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField }
})

export default class BuildingEditLotTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get isAfasBuilding () : boolean {
    return this.currentItem.in_afas
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
