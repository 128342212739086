







































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField }
})

export default class CompanyEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'companies', show: 'companyShow', edit: 'companyEdit' },
    routeParamKey: 'companyId',
    functionNames: { setCurrent: 'setCurrentCompany', save: 'saveCompany' },
    getterNames: { current: 'currentCompany', loading: 'loadingCompany', errors: 'projectErrors' }
  }

  get currentItem () : Company {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get isAfasCompany () : boolean {
    return this.currentItem.in_afas
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
