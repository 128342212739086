















































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ProjectWebsite from '@/types/projectWebsite'
import axios from 'axios'
import moment from 'moment'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'
import MediaItem from '@/types/mediaItem'
import Project from '@/types/project'
import Building from '@/types/building'
import ProjectWebsitePartner from '@/types/projectWebsitePartner'

@Component({
  components: { Filelist, Uploader, TextEditor }
})

export default class ProjectWebsiteEditPagesTab extends Vue {
  settings = {
    routeNames: { list: 'projectWebsites', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    functionNames: { setCurrent: 'setCurrentProjectWebsite', clearCurrent: 'clearCurrentProjectWebsite', save: 'saveProjectWebsite' },
    getterNames: { current: 'currentProjectWebsite', loading: 'loadingProjectWebsite', errors: 'projectWebsiteErrors' }
  }

  loading = false

  columns = [
    {
      title: 'Titel',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Onderdeel van',
      key: 'parent',
      dataIndex: 'parent_page.title'
    },
    {
      title: 'Slug',
      key: 'slug',
      dataIndex: 'slug'
    },
    {
      title: 'Layout',
      key: 'page_type',
      dataIndex: 'page_type'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  pages = []

  showAddModal = false
  pageToAdd:{
    page_type: string|null,
    form_type: string|null,
    title: string|null,
    slug: string|null,
    parent_page_id: number|null,
    menu_item: boolean,
    position: number|null,
    active: boolean,
    active_from: moment.Moment|null,
    project_site_form_questions:{
      id:number|null,
      page_id:number|null,
      question_type:string|null,
      question:string|null,
      name:string|null,
      size:string|null,
      required:boolean|null,
      position:number|null,
      note:string|null,
      project_site_form_answers:{
        id:number|null,
        form_question_id:number|null,
        answer:string|null,
        position:number|null
      }[]
    }[]
  } = {
    page_type: null,
    form_type: null,
    title: null,
    slug: null,
    parent_page_id: null,
    menu_item: false,
    position: null,
    active: false,
    active_from: null,
    project_site_form_questions: [
      {
        id: null,
        page_id: null,
        question_type: 'text',
        question: null,
        name: null,
        size: 'col-sm-12',
        required: false,
        position: 1,
        note: null,
        project_site_form_answers: [
          {
            id: null,
            form_question_id: null,
            answer: null,
            position: 1
          }
        ]
      }
    ]
  }

  showEditModal = false
  pageToEdit:{
    id: number|null,
    page_type: string|null,
    form_type: string|null,
    title: string|null,
    slug: string|null,
    parent_page_id: number|null,
    menu_item: boolean,
    position: number|null,
    active: boolean,
    active_from: moment.Moment|null,
    texts: { [key: string]: { text: string|number|null } },
    images: MediaItem[],
    buildings:Building[],
    building_ids:number[],
    partners:ProjectWebsitePartner[],
    partner_ids:number[],
    project_site_form_questions:{
      id:number|null,
      page_id:number|null,
      question_type:string|null,
      question:string|null,
      name:string|null,
      size:string|null,
      required:boolean|null,
      position:number|null,
      note:string|null,
      project_site_form_answers:{
        id:number|null,
        form_question_id:number|null,
        answer:string|null,
        position:number|null
      }[]
    }[]
  } = {
    id: null,
    page_type: null,
    form_type: null,
    title: null,
    slug: null,
    parent_page_id: null,
    menu_item: false,
    position: null,
    active: false,
    active_from: null,
    texts: {},
    images: [],
    buildings: [],
    building_ids: [],
    partners: [],
    partner_ids: [],
    project_site_form_questions: []
  }

  searchTimer = 0
  projects:Project[] = []
  selectedProject = null

  buildings:Building[] = []
  selectedBuildings:number[] = []

  buildingListColumns = [
    {
      title: 'Bouwnummer',
      key: 'number',
      dataIndex: 'number'
    },
    {
      title: 'Project',
      key: 'project',
      dataIndex: 'project.name'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  partners: ProjectWebsitePartner[] = []
  selectedPartners:number[] = []

  partnerListColumns = [
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Website',
      key: 'url',
      dataIndex: 'url'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  projectBuildings:Building[] = []

  showCopyModal = false
  projectWebsites = []
  projectWebsitePages = []
  projectWebsiteIdToCopy = null
  pageIdToFill:number|null = null
  pageIdToCopy = null

  mounted () : void {
    this.getPages()
    this.getPartners()
    this.getProjectBuildings()
    this.getProjectWebsites()
  }

  getPages () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/pages').then((res) => {
      this.pages = res.data

      if (this.pageToEdit.id) {
        this.pages.forEach((page:{ id:number, images:[] }) => {
          if (page.id === this.pageToEdit.id) {
            this.pageToEdit.images = page.images
          }
        })
      }
    }).catch(() => {
      //
    }).finally(() => {
      this.loading = false
    })
  }

  toggleAddModal () : void {
    this.showAddModal = !this.showAddModal
    this.pageToAdd = {
      page_type: null,
      form_type: null,
      title: null,
      slug: null,
      parent_page_id: null,
      menu_item: false,
      position: null,
      active: false,
      active_from: null,
      project_site_form_questions: [
        {
          id: null,
          page_id: null,
          question_type: 'text',
          question: null,
          name: null,
          size: 'col-sm-12',
          required: false,
          position: 1,
          note: null,
          project_site_form_answers: [
            {
              id: null,
              form_question_id: null,
              answer: null,
              position: 1
            }
          ]
        }
      ]
    }
  }

  toggleEditModal (page:any) : void {
    if (page) {
      this.showEditModal = true
      this.pageToEdit = page
      this.pageToEdit.active_from = moment(page.active_from)
    } else {
      this.showEditModal = false
      this.pageToEdit = {
        id: null,
        page_type: null,
        form_type: null,
        title: null,
        slug: null,
        parent_page_id: null,
        menu_item: false,
        position: null,
        active: false,
        active_from: null,
        texts: {},
        images: [],
        buildings: [],
        building_ids: [],
        partners: [],
        partner_ids: [],
        project_site_form_questions: []
      }
      this.getPages()
    }
  }

  storeNewPage () : void {
    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/pages', this.pageToAdd).then(() => {
      this.getPages()
      this.toggleAddModal()
      notification.success({ message: 'Pagina opgeslagen.', description: 'De pagina is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Pagina NIET opgeslagen.', description: 'De pagina is NIET opgeslagen. Probeer het later nogmaals.', duration: 0 })
    })
  }

  storePageSettings () : void {
    axios.put(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/pages/' + this.pageToEdit.id, this.pageToEdit).then(() => {
      this.getPages()
      this.toggleEditModal(null)
      notification.success({ message: 'Pagina opgeslagen.', description: 'De pagina is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Pagina NIET opgeslagen.', description: 'De pagina is NIET opgeslagen. Probeer het later nogmaals.', duration: 0 })
    })
  }

  deletePage (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/pages/' + id).then(() => {
      this.getPages()
      notification.success({ message: 'Pagina verwijderd.', description: 'De pagina is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Pagina NIET verwijderd.', description: 'De pagina is NIET verwijderd. Probeer het later nogmaals.', duration: 0 })
    })
  }

  contentChanged (key:string, value:string) : void {
    this.pageToEdit.texts[key].text = value
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/pages/' + this.pageToEdit.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.getPages()
    })
  }

  searchProject (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/projects?filter[search]=' + val).then((res) => {
          this.projects = res.data.data
        })
      }, 500)
    }
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.selectedProject + '&limit=250').then((res) => {
      this.buildings = res.data.data
    })
  }

  getProjectBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.project_id + '&limit=250').then((res) => {
      this.projectBuildings = res.data.data
    })
  }

  getPartners () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/partners').then((res) => {
      this.partners = res.data
    })
  }

  addBuildingsToPage () : void {
    this.buildings.forEach((building:Building) => {
      if (this.selectedBuildings.includes(building.id)) {
        this.pageToEdit.building_ids.push(building.id)
        this.pageToEdit.buildings.push(building)
      }
    })

    this.selectedBuildings = []
  }

  addAllProjectBuildingsToPage () : void {
    this.buildings.forEach((building:Building) => {
      if (this.pageToEdit.building_ids.indexOf(building.id) === -1) {
        this.pageToEdit.building_ids.push(building.id)
        this.pageToEdit.buildings.push(building)
      }
    })
  }

  addPartnersToPage () : void {
    this.partners.forEach((partner:ProjectWebsitePartner) => {
      if (partner.id && this.selectedPartners.includes(partner.id)) {
        this.pageToEdit.partner_ids.push(partner.id)
        this.pageToEdit.partners.push(partner)
      }
    })

    this.selectedPartners = []
  }

  deleteBuilding (id:number) : void {
    const index = this.pageToEdit.building_ids.indexOf(id)
    this.pageToEdit.building_ids.splice(index, 1)
    this.pageToEdit.buildings.splice(index, 1)
  }

  deletePartner (id:number) : void {
    const index = this.pageToEdit.partner_ids.indexOf(id)
    this.pageToEdit.partner_ids.splice(index, 1)
    this.pageToEdit.partners.splice(index, 1)
  }

  getProjectWebsites () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites').then((r) => {
      this.projectWebsites = r.data.data
    })
  }

  getProjectWebsitePages () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.projectWebsiteIdToCopy + '/pages/' + this.pageIdToFill).then((r) => {
      this.projectWebsitePages = r.data
    })
  }

  toggleCopyModal (pageIdToFill:number|null) : void {
    this.showCopyModal = !this.showCopyModal
    this.projectWebsiteIdToCopy = null
    this.pageIdToFill = pageIdToFill
    this.pageIdToCopy = null
  }

  copyPage () : void {
    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/pages/copy/' + this.pageIdToCopy + '/' + this.pageIdToFill).then(() => {
      this.toggleCopyModal(null)
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  addQuestion (mode:string) : void {
    if (mode === 'add') {
      this.pageToAdd.project_site_form_questions.push({
        id: null,
        page_id: null,
        question_type: 'text',
        question: null,
        name: null,
        size: 'col-sm-12',
        required: false,
        position: this.pageToAdd.project_site_form_questions.length + 1,
        note: null,
        project_site_form_answers: [
          {
            id: null,
            form_question_id: null,
            answer: null,
            position: 1
          }
        ]
      })
    } else {
      this.pageToEdit.project_site_form_questions.push({
        id: null,
        page_id: this.pageToEdit.id,
        question_type: 'text',
        question: null,
        name: null,
        size: 'col-sm-12',
        required: false,
        position: this.pageToEdit.project_site_form_questions.length + 1,
        note: null,
        project_site_form_answers: [
          {
            id: null,
            form_question_id: null,
            answer: null,
            position: 1
          }
        ]
      })
    }
  }

  removeQuestion (id:number, questionIndex:number, mode:string) : void {
    if (mode === 'add') {
      this.pageToAdd.project_site_form_questions.splice(questionIndex, 1)
    } else {
      if (id) {
        axios.delete(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/questions/' + id).then(() => {
          this.pageToEdit.project_site_form_questions.splice(questionIndex, 1)
          notification.success({ message: 'Vraag verwijderd.', description: 'De vraag is succesvol verwijderd.' })
        }).catch(() => {
          notification.error({ message: 'Fout! Vraag niet verwijderd.', description: 'Er is iets mis gegaan tijdens het verwijderen van de vraag. Probeer het nogmaals.', duration: 0 })
        })
      } else {
        this.pageToEdit.project_site_form_questions.splice(questionIndex, 1)
      }
    }
  }

  addAnswer (index:number, mode:string) : void {
    if (mode === 'add') {
      this.pageToAdd.project_site_form_questions[index].project_site_form_answers.push({
        id: null,
        form_question_id: this.pageToAdd.project_site_form_questions[index].id,
        answer: null,
        position: this.pageToAdd.project_site_form_questions[index].project_site_form_answers.length + 1
      })
    } else {
      this.pageToEdit.project_site_form_questions[index].project_site_form_answers.push({
        id: null,
        form_question_id: this.pageToEdit.project_site_form_questions[index].id,
        answer: null,
        position: this.pageToEdit.project_site_form_questions[index].project_site_form_answers.length + 1
      })
    }
  }

  removeAnswer (id:number|null, questionIndex:number, answerIndex:number, mode:string) : void {
    if (mode === 'add') {
      this.pageToAdd.project_site_form_questions[questionIndex].project_site_form_answers.splice(answerIndex, 1)
    } else {
      if (id) {
        axios.delete(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/answers/' + id).then(() => {
          this.pageToEdit.project_site_form_questions[questionIndex].project_site_form_answers.splice(answerIndex, 1)
          notification.success({ message: 'Antwoord verwijderd.', description: 'Het antwoord is succesvol verwijderd.' })
        }).catch(() => {
          notification.error({ message: 'Fout! Antwoord niet verwijderd.', description: 'Er is iets mis gegaan tijdens het verwijderen van het antwoord. Probeer het nogmaals.', duration: 0 })
        })
      } else {
        this.pageToEdit.project_site_form_questions[questionIndex].project_site_form_answers.splice(answerIndex, 1)
      }
    }
  }

  addPresetForm (form:string) : void {
    switch (form) {
      case 'custom_form':
        this.pageToAdd.project_site_form_questions = [
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: null,
            name: null,
            size: 'col-sm-12',
            required: false,
            position: this.pageToAdd.project_site_form_questions.length + 1,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: null,
                position: 1
              }
            ]
          }
        ]
        break
      case 'contact_form':
        this.pageToAdd.title = 'Contact'
        this.pageToAdd.slug = 'contact'
        this.pageToAdd.parent_page_id = null
        this.pageToAdd.project_site_form_questions = [
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Aanhef',
            name: 'aanhef',
            size: 'col-sm-12',
            required: true,
            position: 1,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Dhr.',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Mevr.',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Voorletter(s)',
            name: 'voorletters',
            size: 'col-sm-6',
            required: true,
            position: 2,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Achternaam',
            name: 'achternaam',
            size: 'col-sm-6',
            required: true,
            position: 3,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Adres',
            name: 'adres',
            size: 'col-sm-12',
            required: true,
            position: 4,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Postcode',
            name: 'postcode',
            size: 'col-sm-6',
            required: true,
            position: 5,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Plaats',
            name: 'plaats',
            size: 'col-sm-6',
            required: true,
            position: 6,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Telefoon',
            name: 'telefoon',
            size: 'col-sm-6',
            required: true,
            position: 7,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'E-mailadres',
            name: 'email',
            size: 'col-sm-6',
            required: true,
            position: 8,
            note: null,
            project_site_form_answers: []
          }
        ]
        break
      case 'subscribe_form':
        this.pageToAdd.title = 'Inschrijfformulier'
        this.pageToAdd.slug = 'inschrijfformulier'
        this.pageToAdd.parent_page_id = null
        this.pageToAdd.project_site_form_questions = [
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Persoonsgegevens',
            name: 'persoonsgegevens',
            size: 'col-sm-6',
            required: true,
            position: 1,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Dhr.',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Mevr.',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Persoonsgegevens partner',
            name: 'persoonsgegevens_partner',
            size: 'col-sm-6',
            required: false,
            position: 2,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Dhr.',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Mevr.',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Voornamen',
            name: 'voornamen',
            size: 'col-sm-6',
            required: true,
            position: 3,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Voornamen partner',
            name: 'voornamen_partner',
            size: 'col-sm-6',
            required: false,
            position: 4,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Achternaam',
            name: 'achternaam',
            size: 'col-sm-6',
            required: true,
            position: 5,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Achternaam partner',
            name: 'achternaam_partner',
            size: 'col-sm-6',
            required: false,
            position: 6,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Adres',
            name: 'adres',
            size: 'col-sm-6',
            required: true,
            position: 7,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Adres partner',
            name: 'adres_partner',
            size: 'col-sm-6',
            required: false,
            position: 8,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Postcode',
            name: 'postcode',
            size: 'col-sm-3',
            required: true,
            position: 9,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Plaats',
            name: 'plaats',
            size: 'col-sm-3',
            required: true,
            position: 10,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Postcode partner',
            name: 'postcode_partner',
            size: 'col-sm-3',
            required: false,
            position: 11,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Plaats partner',
            name: 'plaats_partner',
            size: 'col-sm-3',
            required: false,
            position: 12,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Geboorteplaats',
            name: 'geboorteplaats',
            size: 'col-sm-6',
            required: true,
            position: 13,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Geboorteplaats partner',
            name: 'geboorteplaats_partner',
            size: 'col-sm-6',
            required: false,
            position: 14,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Geboortedatum',
            name: 'geboortedatum',
            size: 'col-sm-6',
            required: true,
            position: 15,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Geboortedatum partner',
            name: 'geboortedatum_partner',
            size: 'col-sm-6',
            required: false,
            position: 16,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Telefoon',
            name: 'telefoon',
            size: 'col-sm-6',
            required: true,
            position: 17,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Telefoon partner',
            name: 'telefoon_partner',
            size: 'col-sm-6',
            required: false,
            position: 18,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'E-mailadres',
            name: 'email',
            size: 'col-sm-6',
            required: true,
            position: 19,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'E-mailadres partner',
            name: 'email_partner',
            size: 'col-sm-6',
            required: false,
            position: 20,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 1',
            name: 'voorkeur_1',
            size: 'col-sm-4',
            required: true,
            position: 21,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 2',
            name: 'voorkeur_2',
            size: 'col-sm-4',
            required: false,
            position: 22,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 3',
            name: 'voorkeur_3',
            size: 'col-sm-4',
            required: false,
            position: 23,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 4',
            name: 'voorkeur_4',
            size: 'col-sm-4',
            required: false,
            position: 24,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 5',
            name: 'voorkeur_5',
            size: 'col-sm-4',
            required: false,
            position: 25,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Voorkeur 6',
            name: 'voorkeur_6',
            size: 'col-sm-4',
            required: false,
            position: 26,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Heeft u de financiële haalbaarheid getoetst?',
            name: 'financiele_haalbaarheid_getoetst',
            size: 'col-sm-12',
            required: true,
            position: 27,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Nee',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Ja',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Wilt u een financiële check laten doen door de hypotheekadviseurs van dit project?',
            name: 'financiele_check_door_hypotheekadviseurs',
            size: 'col-sm-12',
            required: true,
            position: 28,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Nee',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Ja',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'checkbox',
            question: 'Onze makelaars laten het resultaat van uw inschrijving aan u weten. Het is dan ook noodzakelijk dat u ons toestemming geeft voor de verstrekking van uw gegevens aan onze makelaars.',
            name: 'akkoord_verstrekking_gegevens',
            size: 'col-sm-12',
            required: true,
            position: 29,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Ja, ik ga akkoord.',
                position: 1
              }
            ]
          }
        ]

        this.pageToAdd.project_site_form_questions.forEach((question:{
          id:number|null,
          page_id:number|null,
          question_type:string|null,
          question:string|null,
          name:string|null,
          size:string|null,
          required:boolean|null,
          position:number|null,
          note:string|null,
          project_site_form_answers:{
            id:number|null,
            form_question_id:number|null,
            answer:string|null,
            position:number|null
          }[]
        }) => {
          if (question.name && question.name.includes('voorkeur_')) {
            this.projectBuildings.forEach((building:Building, index:number) => {
              question.project_site_form_answers.push({
                id: null,
                form_question_id: null,
                answer: building.number.toString(),
                position: index + 1
              })
            })
          }
        })
        break
      case 'spare_list':
        this.pageToAdd.title = 'Reservelijst'
        this.pageToAdd.slug = 'reservelijst'
        this.pageToAdd.parent_page_id = null
        this.pageToAdd.project_site_form_questions = [
          {
            id: null,
            page_id: null,
            question_type: 'select',
            question: 'Aanhef',
            name: 'aanhef',
            size: 'col-sm-12',
            required: true,
            position: 1,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: 'Dhr.',
                position: 1
              },
              {
                id: null,
                form_question_id: null,
                answer: 'Mevr.',
                position: 2
              }
            ]
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Voorletter(s)',
            name: 'voorletters',
            size: 'col-sm-6',
            required: true,
            position: 2,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Achternaam',
            name: 'achternaam',
            size: 'col-sm-6',
            required: true,
            position: 3,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Adres',
            name: 'adres',
            size: 'col-sm-12',
            required: true,
            position: 4,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Postcode',
            name: 'postcode',
            size: 'col-sm-6',
            required: true,
            position: 5,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Plaats',
            name: 'plaats',
            size: 'col-sm-6',
            required: true,
            position: 6,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'Telefoon',
            name: 'telefoon',
            size: 'col-sm-6',
            required: true,
            position: 7,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'text',
            question: 'E-mailadres',
            name: 'email',
            size: 'col-sm-6',
            required: true,
            position: 8,
            note: null,
            project_site_form_answers: []
          },
          {
            id: null,
            page_id: null,
            question_type: 'checkbox',
            question: 'In welk type bent u geïnteresseerd?',
            name: 'geinteresseerd_in',
            size: 'col-sm-12',
            required: true,
            position: 9,
            note: null,
            project_site_form_answers: [
              {
                id: null,
                form_question_id: null,
                answer: null,
                position: null
              }
            ]
          }
        ]
        break
    }
  }

  downloadResults (slug:string) : void {
    axios.get(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.$route.params[this.settings.routeParamKey] + '/results/' + slug).then((res) => {
      window.open(res.data, '_blank')
    })
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : ProjectWebsite {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
