

























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Company from '@/types/company'
import CompanyEditDetailsTab from '@/views/company/tabs/edit/CompanyEditDetailsTab.vue'

@Component({
  components: { CompanyEditDetailsTab }
})

export default class CompanyCreate extends Vue {
  settings = {
    routeNames: { list: 'companies', show: 'companyShow', edit: 'companyEdit' },
    routeParamKey: 'companyId',
    functionNames: { setCurrent: 'setCurrentCompany', clearCurrent: 'clearCurrentCompany', save: 'saveCompany' },
    getterNames: { current: 'currentCompany', loading: 'loadingCompany', errors: 'companyErrors' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('companyCreateActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('companyCreateActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Company {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
