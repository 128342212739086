import Answer from '@/types/formAnswer'

export default class FormQuestionType {
  public id!:number
  public name!:string
  public description!:string
  public number!:number
  public category = 0
  public required!:boolean
  public show_ntb!:boolean
  public inspection_question!:boolean
  public delivery_question!:boolean
  public answer!:Answer
  public surveyQuestionGroupIds!:number[]
  public Set (question: FormQuestionType): void {
    this.id = question.id
    this.name = question.name
    this.description = question.description
    this.number = question.number
    this.category = question.category
    this.required = question.required
    this.show_ntb = question.show_ntb
    this.inspection_question = question.inspection_question
    this.delivery_question = question.delivery_question
    this.answer = question.answer
    this.surveyQuestionGroupIds = question.surveyQuestionGroupIds
  }
}
