



























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingEditEnabledOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  loading = false
  projectOptions = [
    {
      id: 'All',
      label: 'Alles',
      children: []
    }
  ]

  enabledBuildingOptions:number[] = []

  projectBuildings:{ id:number, number:number }[] = []
  copyFrom = null

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingOptions()
      this.getBuildingsInProject()
    }
  }

  getBuildingOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/availableOptionsTree').then((res) => {
      this.projectOptions = res.data
      this.enabledBuildingOptions = this.currentItem.enabled_options
    }).finally(() => {
      this.loading = false
    })
  }

  getBuildingsInProject () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.project_id + '&limit=250').then((res) => {
      this.projectBuildings = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  enabledBuildingOptionsChanged () : void {
    this.$store.dispatch('enabledBuildingOptionsChanged', this.enabledBuildingOptions)
  }

  copyOptions () : void {
    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/copyEnabledOptions', { fromBuilding: this.copyFrom }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'Opties geactiveerd.', description: 'De opties zijn succesvol geactiveerd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Opties NIET geactiveerd..', description: 'De opties zijn NIET geactiveerd!', duration: 0 })
    })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
