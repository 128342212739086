import Feedback from '@/types/feedback'

export default class Release {
    [key: string]: any;
    public id!:number
    public version!:string
    public feedbacks:Feedback[] = []
    public logs : {[key: string]: string} = {
      api: '',
      admin: '',
      portal: '',
      service: '',
      survey: '',
      construction_site: '',
      general: ''
    }

    public released_by!:string
    public released_at!:string
    public created_at!:string
    public updated_at!:string
}

export const PageModes = {
  create: 'create',
  edit: 'edit',
  view: 'view'
}
