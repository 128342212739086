






































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import BuildingShowDetailsTab from '@/views/building/tabs/show/BuildingShowDetailsTab.vue'
import BuildingShowLotTab from '@/views/building/tabs/show/BuildingShowLotTab.vue'
import BuildingShowDatesTab from '@/views/building/tabs/show/BuildingShowDatesTab.vue'
import BuildingShowRoomsTab from '@/views/building/tabs/show/BuildingShowRoomsTab.vue'
import BuildingShowLayoutSettingsTab from '@/views/building/tabs/show/BuildingShowLayoutSettingsTab.vue'
import BuildingShowEnabledOptionsTab from '@/views/building/tabs/show/BuildingShowEnabledOptionsTab.vue'
import BuildingShowOptionSettingsTab from '@/views/building/tabs/show/BuildingShowOptionSettingsTab.vue'
import BuildingShowOrdersTab from '@/views/building/tabs/show/BuildingShowOrdersTab.vue'
import BuildingShowConfigurationsTab from '@/views/building/tabs/show/BuildingShowConfigurationsTab.vue'
import BuildingShowReportsTab from '@/views/building/tabs/show/BuildingShowReportsTab.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import BuildingShowIssuesTab from '@/views/building/tabs/show/BuildingShowIssuesTab.vue'
import BuildingShowSurveysTab from '@/views/building/tabs/show/BuildingShowSurveysTab.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import BuildingShowNewsTab from '@/views/building/tabs/show/BuildingShowNewsTab.vue'
import BuildingUnityViewerTab from '@/views/building/tabs/BuildingUnityViewerTab.vue'
import BuildingDocumentsTab from '@/views/building/tabs/BuildingDocumentsTab.vue'
import ConnectCustomer from '@/components/customers/ConnectCustomer.vue'
import ConnectTenant from '@/components/customers/ConnectTenant.vue'
import BuildingShowFivePercentTab from '@/views/building/tabs/show/BuildingShowFivePercentTab.vue'
import BuildingDrawingsTab from '@/views/building/tabs/BuildingDrawingsTab.vue'
import BuildingFinancialTab from '@/views/building/tabs/BuildingFinancialTab.vue'

@Component({
  components: {
    BuildingFinancialTab,
    BuildingDrawingsTab,
    BuildingShowFivePercentTab,
    ConnectTenant,
    ConnectCustomer,
    BuildingDocumentsTab,
    BuildingUnityViewerTab,
    BuildingShowNewsTab,
    PageHeader,
    BuildingShowSurveysTab,
    BuildingShowIssuesTab,
    ActivityLogTable,
    BuildingShowReportsTab,
    BuildingShowConfigurationsTab,
    BuildingShowOrdersTab,
    BuildingShowOptionSettingsTab,
    BuildingShowEnabledOptionsTab,
    BuildingShowLayoutSettingsTab,
    BuildingShowRoomsTab,
    BuildingShowDatesTab,
    BuildingShowLotTab,
    BuildingShowDetailsTab
  }
})

export default class BuildingShow extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', clearCurrent: 'clearCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('buildingShowActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  setActiveTab (tab:string) : void {
    if (tab === 'unity-model') return // Don't store the viewer tab
    new SecureLS({ isCompression: false }).set('buildingShowActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get pageHeader () : string {
    const header = this.currentItem.project
      ? `Bouwnummer ${this.currentItem.number} (${this.currentItem.reference}) - ${this.currentItem.project.name} (${this.currentItem.project.reference})`
      : `Bouwnummer ${this.currentItem.number} (${this.currentItem.reference})`
    return this.loading ? 'Bouwnummer - Aan het laden..' : header
  }

  get canViewCustomer () : boolean {
    return this.currentItem.customer && this.authUser.permissions.includes('customers.view')
  }

  get canViewTenant () : boolean {
    return this.currentItem.tenant !== null && this.authUser.permissions.includes('tenants.view')
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
