import User from '@/types/user'
import Building from '@/types/building'
import MediaItem from '@/types/mediaItem'

export default class Tenant {
  [key: string]: unknown
  public id!:number
  public reference!:string
  public name!:string
  public address!:string
  public zipcode!:string
  public city!:string
  public email!:string
  public phone!:string
  public user_ids:number[] = []
  public users:User[] = []
  public buildings:Building[] = []
  public media: MediaItem[] = []

  constructor (data?: unknown) {
    if (data) {
      Object.assign(this, data)
    }
  }
}

export const tenantMediaFolders:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean, visibleFor:string[] }[] = [
  {
    name: 'Huurcontract',
    position: 'huurcontract',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Huurder']
  }
]
