



































































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Feedback, { SortedLabels, WoononApps } from '@/types/feedback'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import Uploader from '@/components/media/Uploader.vue'
import UploadQueue from '@/components/media/UploadQueue.vue'

@Component({
  components: { UploadQueue, Uploader, TextEditor }
})

export default class FeedbackCreate extends Vue {
  @Prop() parentId!:number|null

  settings = {
    routeNames: { list: 'feedbacks', show: 'feedbackShow', edit: 'feedbackEdit' },
    routeParamKey: 'feedbackId',
    functionNames: { setCurrent: 'setCurrentFeedback', clearCurrent: 'clearCurrentFeedback', save: 'saveFeedback' },
    getterNames: { current: 'currentFeedback', loading: 'loadingFeedback' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('feedbackEditActiveTab') || 'details'

  sortedLabels = SortedLabels

  employees:User[] = []
  developers:User[] = []
  woononApps = WoononApps

  categories:{ id:number, name:string, slug:string }[] = []

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.getCategories()
    if (this.parentId) {
      this.currentItem.parent_id = this.parentId
    }
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getCategories () : void {
    axios.get(process.env.VUE_APP_API_URL + '/feedbacks/categories').then((res) => {
      this.categories = res.data
    })
  }

  get sortedCategories (): { id:number, name:string, slug:string }[] {
    return this.categories.sort((a: { name: string }, b: { name: string }) => { return a.name.localeCompare(b.name) })
  }

  newFileUploaded (file:File) : void {
    this.currentItem.files.push(file)
  }

  fileDeleted (index:number) : void {
    this.currentItem.files.splice(index, 1)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('feedbackEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  get currentItem () : Feedback {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
