import './component-hooks'
// Vue
import Vue from 'vue'

// Ant design vue
import Antd, { notification } from 'ant-design-vue'
import './assets/styles/theme.less'
import './assets/styles/scss/app.scss'

// App
import App from './App.vue'

// Third party
import router from './router'
import { store } from './store'
import axios, { AxiosStatic } from 'axios'
import SecureLS from 'secure-ls'
import { Model } from 'vue-api-query'
import VueApexCharts from 'vue-apexcharts'

// Other
import './filters'

// Usages
Vue.use(Antd)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// inject global axios instance as http client to Model
Model.$http = axios

const ls = new SecureLS({ isCompression: false })
const token = ls.get('token')
if (token) {
  Model.$http.defaults.headers.common.Authorization = token
}
Model.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Model.$http.defaults.headers.common['Content-Type'] = 'application/json'
Model.$http.defaults.headers.common.Accept = 'application/json'

// Logout when user token is invalid.
axios.interceptors.response.use(function (response) {
  return response
}, function (e) {
  if (e.response.status === 401 && !e.response.config.url.includes('logout') && !e.response.config.url.includes('login')) {
    notification.destroy()
    store.dispatch('logout', true)
  }
  return Promise.reject(e)
})

// Notification config.
notification.config({
  placement: 'bottomRight'
})

Vue.config.productionTip = false

// Set axios as $http for global use.
declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
