


















































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import axios from 'axios'
import Role from '@/types/role'
import User from '@/types/user'
import PermissionMatrix from '@/types/permissionMatrix'

@Component({
  components: {}
})

export default class RoleEdit extends Vue {
  settings = {
    routeNames: { list: 'roles', show: 'roleShow', edit: 'roleEdit' },
    routeParamKey: 'roleId',
    functionNames: { setCurrent: 'setCurrentRole', clearCurrent: 'clearCurrentRole', save: 'saveRole' },
    getterNames: { current: 'currentRole', loading: 'loadingRole' }
  }

  permissionMatrix:{ [key: string]: PermissionMatrix } = {}

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    this.getPermissionMatrix()
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  getPermissionMatrix () : void {
    axios.get(process.env.VUE_APP_API_URL + '/roles/permissionMatrix').then((res) => {
      this.permissionMatrix = res.data
    })
  }

  togglePermission (id:number) : void {
    if (this.currentItem.permissions.indexOf(id) !== -1) {
      this.currentItem.permissions.splice(this.currentItem.permissions.indexOf(id), 1)
    } else {
      this.currentItem.permissions.push(id)
    }
  }

  toggleAllPermissionsInGroup (group:string, action:string) : void {
    // If all permissions in group are selected, uncheck them all. Else check them all.
    if (this.allSelectedInGroup[group][action]) {
      this.allPermissionsIdsInGroup[group][action].forEach((permissionId:number) => {
        this.currentItem.permissions.splice(this.currentItem.permissions.indexOf(permissionId), 1)
      })
    } else {
      this.allPermissionsIdsInGroup[group][action].forEach((permissionId:number) => {
        if (this.currentItem.permissions.indexOf(permissionId) === -1) {
          this.currentItem.permissions.push(permissionId)
        }
      })
    }
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get allPermissionsIdsInGroup () : { [key: string]: { [key: string]: number[] } } {
    const allPermissions:{ [key: string]: { [key: string]: number[] } } = {}
    const actions = ['view', 'edit', 'delete']

    Object.keys(this.permissionMatrix).forEach((group:string) => {
      allPermissions[group] = {}
      actions.forEach((action:string) => {
        if (!allPermissions[group][action]) {
          allPermissions[group][action] = []
        }

        if (this.permissionMatrix[group].permissions.base.actions[action]) {
          allPermissions[group][action].push(this.permissionMatrix[group].permissions.base.actions[action].id)
        }

        Object.keys(this.permissionMatrix[group].permissions.sections).forEach((section:string) => {
          if (this.permissionMatrix[group].permissions.sections[section].actions[action]) {
            allPermissions[group][action].push(this.permissionMatrix[group].permissions.sections[section].actions[action].id)
          }
        })
      })
    })

    return allPermissions
  }

  get allSelectedInGroup () : { [key: string]: { [key: string]: boolean } } {
    const allSelected:{ [key: string]: { [key: string]: boolean } } = {}
    const actions = ['view', 'edit', 'delete']

    Object.keys(this.allPermissionsIdsInGroup).forEach((group:string) => {
      allSelected[group] = {}
      actions.forEach((action:string) => {
        if (!allSelected[group][action]) {
          allSelected[group][action] = true
        }
        this.allPermissionsIdsInGroup[group][action].forEach((permissionId:number) => {
          if (!this.currentItem.permissions.includes(permissionId)) {
            allSelected[group][action] = false
          }
        })
      })
    })

    return allSelected
  }

  get currentItem () : Role {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
