
























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Layout from '@/types/layout'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class LayoutEditElementsTab extends Vue {
  settings = {
    routeNames: { list: 'layouts', show: 'layoutShow', edit: 'layoutEdit' },
    routeParamKey: 'layoutId',
    functionNames: { setCurrent: 'setCurrentLayout', save: 'saveLayout' },
    getterNames: { current: 'currentLayout', loading: 'loadingLayout', errors: 'layoutErrors' }
  }

  agreeToRisk = false

  projects = [
    {
      name: null,
      reference: null,
      buildings: []
    }
  ]

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingsWhereLayoutIsUsed()
    }
  }

  getBuildingsWhereLayoutIsUsed () : void {
    axios.get(process.env.VUE_APP_API_URL + '/layouts/' + this.currentItem.id + '/buildings').then((res) => {
      this.projects = res.data
    })
  }

  destroyLayout () : void {
    axios.delete(process.env.VUE_APP_API_URL + '/layouts/' + this.$route.params.layoutId).then(() => {
      notification.success({ message: 'Layout verwijderd.', description: 'De layout is succesvol verwijderd.', duration: 3 })
      this.$router.push({ name: 'layouts' })
    }).catch(() => {
      notification.error({ message: 'Layout NIET verwijderd.', description: 'De layout is NIET verwijderd. Probeer het later nog eens.', duration: 0 })
    })
  }

  get currentItem () : Layout {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
