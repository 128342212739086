





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Base64MediaFile } from '@/types/media'
import pdf from 'vue-pdf-yzd'

@Component({
  components: { pdf }
})

export default class IssueShowCommentMediaItem extends Vue {
  @Prop() file!: Base64MediaFile
  @Prop() fileIndex!:number

  showPreview = false

  togglePreview () : void {
    this.showPreview = !this.showPreview
  }

  deleteMediaItem () : void {
    this.$emit('deleteMediaItem', this.fileIndex)
  }
}
