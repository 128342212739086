import Model from './Model'

export default class EventTriggerModel extends Model {
  public id: number|undefined
    public name: string|undefined

    resource () : string {
      return 'event-triggers'
    }

    /**
   * add update function that accepts data and an id
   */
    update (id: number, data: any) : Promise<any> {
      return this.request({
        method: 'put',
        url: this.resource() + '/' + id,
        data
      })
    }
}
