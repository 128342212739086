










import { Vue, Component } from 'vue-property-decorator'
import DevToolAfasBuildings from '@/components/devtools/afas/DevToolAfasBuildings.vue'

@Component({
  components: { DevToolAfasBuildings }
})

export default class DevToolAfas extends Vue {}
