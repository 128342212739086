import User from '@/types/user'
import Building from '@/types/building'
import MediaItem from '@/types/mediaItem'

export default class Customer {
  [key: string]: any
  public id!: number
  public reference?: string
  public debtor_number?: string
  public type?: number = 1
  public name?: string
  public address?: string
  public zipcode?: string
  public city?: string
  public email?: string
  public phone?: string
  public coc?: string
  public user_ids: number[] = []
  public users: User[] = []
  public contractor_ids: number[] = []
  public contractors: User[] = []
  public buildings: Building[] = []
  public media: MediaItem[] = []

  constructor (data?: unknown) {
    if (data) {
      Object.assign(this, data)
    }
  }
}

export const customerMediaFolders:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean, visibleFor:string[] }[] = [
  {
    name: 'Contracten',
    position: 'contracten',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Facturen',
    position: 'facturen',
    allowedMimeTypes: ['application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Orders',
    position: 'orders',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Opleverdossier',
    position: 'opleverdossier',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  },
  {
    name: 'Servicemeldingen',
    position: 'servicemeldingen',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true,
    visibleFor: ['Koper']
  }
]
