





















































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingEditConfigurationsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Klant',
      key: 'customer',
      scopedSlots: { customRender: 'customer' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  buildingConfigs = []
  demoConfigs = []
  showDemoModal = false
  customers = []
  customerId = null
  customerTimer = 0

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingConfigs()
      this.getDemoConfigs()
    }
  }

  getBuildingConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildingConfigs?filter[building_id]=' + this.currentItem.id).then((res) => {
      this.buildingConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  getDemoConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/demoConfigs?filter[building_id]=' + this.currentItem.id).then((res) => {
      this.demoConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  destroyDemoConfig (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/demoConfigs/' + id).then(() => {
      notification.success({ message: 'Demo configuratie verwijderd.', description: 'De demo configuratie is succesvol verwijderd.', duration: 3 })
      this.getBuildingConfigs()
    }).catch(() => {
      notification.error({ message: 'Demo configuratie NIET verwijderd.', description: 'De demo configuratie is NIET verwijderd. Probeer het later nog eens.', duration: 3 })
    })
  }

  toggleDemoModal () : void {
    this.showDemoModal = !this.showDemoModal
    this.customerId = null
    this.customers = []
  }

  searchCustomer (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.customerTimer)

      this.customerTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/customers?filter[search]=' + val).then((res) => {
          this.customers = res.data.data
        })
      }, 500)
    }
  }

  createDemo () : void {
    axios.post(process.env.VUE_APP_API_URL + '/customers/' + this.customerId + '/demo', { project_id: this.currentItem.project_id, building_id: this.currentItem.id }).then((r) => {
      notification.success({ message: 'Demo aangemaakt.', description: 'De demo is succesvol aangemaakt.', duration: 3 })
      this.getBuildingConfigs()
      this.getDemoConfigs()
      this.toggleDemoModal()
    }).catch(() => {
      notification.error({ message: 'Demo NIET aangemaakt.', description: 'De demo is NIET aangemaakt. Probeer het later nog eens.', duration: 0 })
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get demoCustomerIds () : number[] {
    const demoCustomerIds:number[] = []
    this.demoConfigs.forEach((bc:{ id:number, customer_id:number }) => {
      demoCustomerIds.push(bc.customer_id)
    })
    return demoCustomerIds
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
