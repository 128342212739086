















































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option, { OptionBudget } from '@/types/option'
import User from '@/types/user'
import Column from '@/types/column'
import axios from 'axios'
import GuardCode from '@/types/guardCode'
import { notification } from 'ant-design-vue'
import { units } from '@/globalValues'

@Component({
  components: {}
})

export default class OptionEditBudgetsTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  columns:Column[] = [
    {
      title: 'Bewakingscode',
      key: 'guard_code',
      scopedSlots: { customRender: 'guard_code' }
    },
    {
      title: 'Omschrijving',
      key: 'description',
      scopedSlots: { customRender: 'description' }
    },
    {
      title: 'Aantal',
      key: 'qty',
      dataIndex: 'qty',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Eenheid',
      key: 'unit',
      scopedSlots: { customRender: 'unit' }
    },
    {
      title: 'Eenheidsprijs',
      key: 'unit_price',
      scopedSlots: { customRender: 'unit_price' }
    },
    {
      title: 'Totaalprijs',
      key: 'total_price',
      scopedSlots: { customRender: 'total_price' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  showModal = false
  searchTimer = 0
  loading = { search: false, saving: false }
  guardCodes:GuardCode[] = []
  optionBudget:{ id?:number, option_id:number, guard_code_id:number|null, description:string|null, qty:number, unit:string, unit_price:number, total_price:number }|null = null

  toggleModal (optionBudget:OptionBudget|null = null) : void {
    this.showModal = !this.showModal

    if (this.showModal && optionBudget) {
      this.searchGuardCodes(optionBudget.guard_code.code)
      this.optionBudget = {
        id: optionBudget.id,
        option_id: parseInt(this.$route.params.optionId),
        guard_code_id: optionBudget.guard_code_id,
        description: optionBudget.description,
        qty: optionBudget.qty,
        unit: optionBudget.unit,
        unit_price: optionBudget.unit_price,
        total_price: optionBudget.total_price
      }
    } else if (this.showModal && !optionBudget) {
      this.optionBudget = {
        option_id: parseInt(this.$route.params.optionId),
        guard_code_id: null,
        description: null,
        qty: 1,
        unit: 'PST',
        unit_price: 0,
        total_price: 0
      }
    } else {
      this.optionBudget = null
      this.guardCodes = []
    }
  }

  searchGuardCodes (val:string) : void {
    if (val.length >= 3) {
      if (val.length >= 3) {
        clearTimeout(this.searchTimer)

        this.searchTimer = setTimeout(() => {
          this.loading.search = true
          axios.get(process.env.VUE_APP_API_URL + '/guardCodes?filter[search]=' + val).then((r) => {
            this.guardCodes = r.data.data
          }).finally(() => {
            this.loading.search = false
          })
        }, 500)
      }
    }
  }

  onGuardCodeChange (id:number) : void {
    if (this.optionBudget) {
      this.optionBudget.guard_code_id = id

      const defaultUnit = this.guardCodes.find((guardCode:GuardCode) => guardCode.id === id)
      this.optionBudget.unit = defaultUnit && defaultUnit.default_unit ? defaultUnit.default_unit : 'PST'
    }
  }

  calculateTotal () : void {
    if (this.optionBudget) {
      if (this.optionBudget.unit === '%') {
        this.optionBudget.total_price = (this.optionBudget.qty / 100) * this.optionBudget.unit_price
      } else {
        this.optionBudget.total_price = this.optionBudget.qty * this.optionBudget.unit_price
      }
    }
  }

  addOptionBudget () : void {
    this.loading.saving = true
    axios.post(process.env.VUE_APP_API_URL + '/optionBudgets', this.optionBudget).then(() => {
      this.toggleModal()
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'Budgetregel toegevoegd.', description: 'De budgetregel is succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET toegevoegd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    }).finally(() => {
      this.loading.saving = false
    })
  }

  updateOptionBudget () : void {
    this.loading.saving = true
    if (this.optionBudget && this.optionBudget.id) {
      axios.patch(process.env.VUE_APP_API_URL + '/optionBudgets/' + this.optionBudget.id, this.optionBudget).then(() => {
        this.toggleModal()
        this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
        notification.success({ message: 'Budgetregel bijgewerkt.', description: 'De budgetregel is succesvol bijgewerkt.', duration: 3 })
      }).catch(() => {
        notification.error({ message: 'Budgetregel NIET bijgewerkt.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
      }).finally(() => {
        this.loading.saving = false
      })
    }
  }

  deleteBudgetFromOption (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/optionBudgets/' + id).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
      notification.success({ message: 'Budgetregel verwijderd.', description: 'De budgetregel is succesvol verwijderd.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Budgetregel NIET verwijderd.', description: 'Er is iets mis gegaan. Probeer het later nog eens. ', duration: 0 })
    })
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get editMode () : boolean {
    return !!(this.optionBudget && this.optionBudget.id)
  }

  get units () : { name:string, value:string }[] {
    return units
  }
}
