

























































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import ProjectEditDetailsTab from '@/views/project/tabs/edit/ProjectEditDetailsTab.vue'
import ProjectEditBlocksTab from '@/views/project/tabs/edit/ProjectEditBlocksTab.vue'
import ProjectEditLayoutSettingsTab from '@/views/project/tabs/edit/ProjectEditLayoutSettingsTab.vue'
import ProjectEditEnabledOptionsTab from '@/views/project/tabs/edit/ProjectEditEnabledOptionsTab.vue'
import ProjectEditOptionSettingsTab from '@/views/project/tabs/edit/ProjectEditOptionSettingsTab.vue'
import ProjectEditExtraOptionsTab from '@/views/project/tabs/edit/ProjectEditExtraOptionsTab.vue'
import ProjectEditDocumentsTab from '@/views/project/tabs/edit/ProjectEditDocumentsTab.vue'
import ProjectMembers from '@/views/project/ProjectMembers.vue'
import axios from 'axios'
import Building, { productTypes } from '@/types/building'
import Column from '@/types/column'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import Issues from '@/views/service/issues/Issues.vue'
import ProjectEditServiceTab from '@/views/project/tabs/ProjectServiceTab.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import ProjectEditReportsTab from '@/views/project/tabs/edit/ProjectEditReportsTab.vue'
import ProjectEditDiariesTab from '@/views/project/tabs/edit/ProjectEditDiariesTab.vue'
import ProjectReportsTab from '@/views/project/tabs/ProjectReportsTab.vue'
import ProjectEditDatesTab from '@/views/project/tabs/edit/ProjectEditDatesTab.vue'
import ProjectEditOptionBudgetsTab from '@/views/project/tabs/edit/ProjectEditOptionBudgetsTab.vue'
import ProjectEditLayoutBudgetsTab from '@/views/project/tabs/edit/ProjectEditLayoutBudgetsTab.vue'
import ProjectEditExtraOptionBudgetsTab from '@/views/project/tabs/edit/ProjectEditExtraOptionBudgetsTab.vue'

@Component({
  components: { ProjectEditExtraOptionBudgetsTab, ProjectEditLayoutBudgetsTab, ProjectEditOptionBudgetsTab, ProjectEditDatesTab, ProjectReportsTab, ProjectEditDiariesTab, ProjectEditReportsTab, PageHeader, ProjectEditServiceTab, Issues, ActivityLogTable, ProjectMembers, ProjectEditDocumentsTab, ProjectEditExtraOptionsTab, ProjectEditOptionSettingsTab, ProjectEditEnabledOptionsTab, ProjectEditLayoutSettingsTab, ProjectEditBlocksTab, ProjectEditDetailsTab }
})

export default class ProjectEdit extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', clearCurrent: 'clearCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('projectEditActiveTab') || 'details'
  currentSubTab:string|null = new SecureLS({ isCompression: false }).get('projectEditActiveSubTab') || 'project_option_budgets'
  buildings:Building[] = []
  loadingBuildings = false
  productTypes = productTypes

  buildingColumns:Column[] = [
    {
      title: 'Bouwnummer',
      key: 'number',
      dataIndex: 'number',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Modulair',
      key: 'modular',
      scopedSlots: { customRender: 'modular' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Ruwbouw',
      key: 'carcass',
      scopedSlots: { customRender: 'carcass' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Afbouw',
      key: 'completion',
      scopedSlots: { customRender: 'completion' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Type',
      key: 'product_type',
      dataIndex: 'product_type',
      scopedSlots: { customRender: 'productType' },
      sorter: false
    },
    {
      title: 'Status',
      key: 'state_label',
      dataIndex: 'state_label',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey]).then(() => {
      this.getBuildings()
    })
  }

  reloadProject () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey]).then(() => {
      this.getBuildings()
    })
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string, subTab:string|null = null) : void {
    new SecureLS({ isCompression: false }).set('projectEditActiveTab', tab)
    this.currentTab = tab

    if (tab === 'budgets') {
      this.setActiveSubTab(subTab || this.currentSubTab || 'project_option_budgets')
    }
  }

  setActiveSubTab (tab:string|null) : void {
    new SecureLS({ isCompression: false }).set('projectEditActiveSubTab', tab)
    this.currentSubTab = tab
  }

  getBuildings () : void {
    this.loadingBuildings = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id + '&limit=250').then((res) => {
      this.buildings = res.data.data
    }).finally(() => {
      this.loadingBuildings = false
    })
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('buildings.edit')) {
            const route = this.$router.resolve({ name: 'buildingEdit', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('buildings.view')) {
            const route = this.$router.resolve({ name: 'buildingShow', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  openExternalUrl (url:string) : void {
    window.open(url, '_blank')
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  syncProject () : void {
    this.$store.dispatch('syncProject', this.currentItem.id)
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get syncing () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].syncing
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
