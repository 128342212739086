














import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingShowEnabledOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  loading = false
  buildingOptions = [
    {
      id: 'All',
      label: 'Alles',
      children: [],
      checkable: false
    }
  ]

  enabledBuildingOptions:number[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingOptions()
    }
  }

  getBuildingOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/availableOptionsTree').then((res) => {
      this.buildingOptions = res.data
      this.buildingOptions[0].checkable = false
      this.buildingOptions[0].children.forEach((group:{ children:{ id:number, disableCheckbox:boolean }[], checkable:boolean }) => {
        group.checkable = false
        group.children.forEach((option:{ id:number, disableCheckbox:boolean }) => {
          option.disableCheckbox = true
        })
      })
      this.enabledBuildingOptions = this.currentItem.enabled_options
    }).finally(() => {
      this.loading = false
    })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
