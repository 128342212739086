




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ServiceItem from '@/types/serviceItem'
import Project from '@/types/project'
import Building from '@/types/building'
import User from '@/types/user'
import axios from 'axios'
import ProjectModel from '@/models/ProjectModel'
import { notification } from 'ant-design-vue'
import BuildingModel from '@/models/BuildingModel'

@Component({})

export default class ServiceBuildingSelector extends Vue {
  @Prop({ required: true }) currentItem!: ServiceItem
  @Prop({ required: true }) group!: string
  @Prop({ required: false }) loading!: boolean
  @Prop({ required: false, type: Boolean, default: false }) editable!: boolean

  @Watch('currentItem', { deep: true })
  currentItemChanged (): void {
    this.getManagers()
    this.selectedManagerId = this.managerId
  }

  managerGroupList: User[] = []
  projectList: Project[] = []
  buildingList: Building[] = []
  selectedManagerId = 0
  managersFetched = false

  async mounted (): Promise<void> {
    this.selectedManagerId = this.currentItem.manager_id as number
    await this.getProjects()
    await this.getBuildings()
  }

  get buildings (): Building[] {
    if (this.buildingList.length === 0) return []
    return this.buildingList
  }

  get projects (): Project[] {
    if (this.projectList.length === 0) return []
    return this.projectList.sort((a, b) => a.name.localeCompare(b.name))
  }

  get managerGroups (): User[] {
    if (this.managerGroupList.length === 0) return []
    return this.managerGroupList
  }

  get managerId (): number {
    return this.currentItem.manager_id as number
  }

  async getProjects (): Promise<void> {
    await new ProjectModel()
      .limit(999)
      .get().then((projects: any) => {
        this.projectList = projects.data
      }).catch(() => {
        notification.error({
          message: 'Fout tijdens het ophalen van projecten!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 3
        })
      })
  }

  getBuildings (): void {
    if (this.currentItem.project_id === 0 || this.currentItem.project_id === undefined) return
    new BuildingModel()
      .limit(999)
      .where('project_id', this.currentItem.project_id)
      .get().then((buildings: any) => {
        this.buildingList = buildings.data
      }).catch(() => {
        notification.error({
          message: 'Fout tijdens het ophalen van bouwnummers!',
          description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
          duration: 3
        })
      })
  }

  async getManagers (): Promise<void> {
    if (this.currentItem.project_id === 0 || this.currentItem.project_id === undefined || this.managersFetched) return
    this.managersFetched = true

    await axios.get(`${process.env.VUE_APP_API_URL}/members/${this.currentItem.project_id}/${this.group}`)
      .then(res => {
        this.managerGroupList = res.data
      }).finally(() => {
        // Try to select first manager
        if (Object.entries(this.managerGroupList).length > 0 && this.currentItem.manager_id === 0) {
          this.selectedManagerId = Object.values(this.managerGroupList)[0][0].id
          this.$emit('manager', this.selectedManagerId)
        }
      })
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onProjectSelected (value: number): void {
    this.getBuildings()
    this.getManagers()
    this.$emit('project', value)
  }

  onBuildingSelected (value: number): void {
    this.$emit('building', value)
  }

  onManagerSelected (): void {
    this.$emit('manager', this.selectedManagerId)
  }
}
