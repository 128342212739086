import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import BuildingConfigModel from '@/models/BuildingConfigModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'

Vue.use(Vuex)

export const buildingConfigStore: Module<any, any> = {
  state: {
    buildingConfigList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentBuildingConfig: {
      modular_total: 0,
      carcass_total: 0,
      completion_total: 0,
      extra_total: 0,
      total: 0,
      building_id: null,
      building: {
        reference: '',
        number: null,
        price: 0,
        project: {
          reference: '',
          name: ''
        },
        received_orders: {
          modular: {
            received: false
          },
          carcass: {
            received: false
          },
          completion: {
            received: false
          },
          extra: {
            received: false
          }
        }
      },
      customer_id: null,
      customer: {
        reference: ''
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentBuildingConfig (state, { currentBuildingConfig }) {
      state.currentBuildingConfig = currentBuildingConfig
    },
    clearCurrentBuildingConfig (state) {
      state.currentBuildingConfig = {
        modular_total: 0,
        carcass_total: 0,
        completion_total: 0,
        extra_total: 0,
        total: 0,
        project_id: null,
        project: {
          reference: '',
          name: ''
        },
        building_id: null,
        building: {
          reference: '',
          number: null,
          price: 0,
          received_orders: {
            modular: {
              received: false
            },
            carcass: {
              received: false
            },
            completion: {
              received: false
            },
            extra: {
              received: false
            }
          }
        },
        customer_id: null,
        customer: {
          reference: ''
        }
      }
    },
    setBuildingConfigList (state, { buildingConfigList }) {
      state.buildingConfigList = buildingConfigList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    }
  },
  actions: {
    getBuildingConfigList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let buildingConfigModel = new BuildingConfigModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            buildingConfigModel = buildingConfigModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        buildingConfigModel = buildingConfigModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        buildingConfigModel = buildingConfigModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        buildingConfigModel = buildingConfigModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      buildingConfigModel.get().then((buildingConfigList) => {
        commit('setBuildingConfigList', { buildingConfigList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de configuraties!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentBuildingConfig ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        BuildingConfigModel.$find(id).then((currentBuildingConfig) => {
          commit('setCurrentBuildingConfig', { currentBuildingConfig })
          resolve(currentBuildingConfig)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze configuratie!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentBuildingConfig ({ commit }) {
      commit('clearCurrentBuildingConfig')
    }
  },
  getters: {
    buildingConfigList: state => state.buildingConfigList,
    currentBuildingConfig: state => state.currentBuildingConfig,
    loadingBuildingConfig: state => state.loading
  }
}
