















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Column from '@/types/column'
import Activity from '@/types/activity'
import axios from 'axios'

@Component({
  components: {}
})

export default class ActivityLogTable extends Vue {
  @Prop() currentItem!:{ id:number }
  @Prop() model!:string

  columns:Column[] = [
    {
      title: 'Door',
      key: 'user.full_name',
      dataIndex: 'user.full_name'
    },
    {
      title: 'Op',
      key: 'created_at',
      dataIndex: 'created_at',
      scopedSlots: { customRender: 'createdAt' }
    },
    {
      title: 'Actie',
      key: 'event',
      dataIndex: 'event'
    },
    {
      title: 'Oude waardes',
      key: 'properties.old',
      dataIndex: 'properties.old',
      scopedSlots: { customRender: 'oldProperties' }
    },
    {
      title: 'Nieuwe waardes',
      key: 'properties.attributes',
      dataIndex: 'properties.attributes',
      scopedSlots: { customRender: 'newProperties' }
    }
  ]

  activities:Activity[] = []

  mounted () : void {
    this.getActivities()
  }

  getActivities () : void {
    axios.get(process.env.VUE_APP_API_URL + '/activities/' + this.model + '/' + this.currentItem.id).then((res) => {
      this.activities = res.data.data
    })
  }

  @Watch('currentItem')
  onCurrentItemChange () : void {
    this.getActivities()
  }
}
