















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Layout from '@/types/layout'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class LayoutShowElementsTab extends Vue {
  settings = {
    routeNames: { list: 'layouts', show: 'layoutShow', edit: 'layoutEdit' },
    routeParamKey: 'layoutId',
    functionNames: { setCurrent: 'setCurrentLayout', save: 'saveLayout' },
    getterNames: { current: 'currentLayout', loading: 'loadingLayout', errors: 'layoutErrors' }
  }

  projects = [
    {
      name: null,
      reference: null,
      buildings: []
    }
  ]

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingsWhereLayoutIsUsed()
    }
  }

  getBuildingsWhereLayoutIsUsed () : void {
    axios.get(process.env.VUE_APP_API_URL + '/layouts/' + this.currentItem.id + '/buildings').then((res) => {
      this.projects = res.data
    })
  }

  get currentItem () : Layout {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
