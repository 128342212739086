













































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingEditOrdersTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'orderId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Order nr.',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Fase',
      key: 'phase',
      dataIndex: 'phase_text'
    },
    {
      title: 'Geplaatst door',
      key: 'user',
      scopedSlots: { customRender: 'user' }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status_text'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  orders = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getBuildingOrders()
    }
  }

  getBuildingOrders () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/orders?filter[building_id]=' + this.currentItem.id + '&filter[trashed]=with').then((res) => {
      this.orders = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  deleteOrder (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/orders/' + id).then(() => {
      notification.success({ message: 'Order succesvol verwijderd!', description: 'Order #' + id + ' is succesvol verwijderd.', duration: 4 })
      this.getBuildingOrders()
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params.buildingId)
    }).catch(() => {
      notification.error({ message: 'Fout tijdens verwijderen van order!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.' })
    }).finally(() => {
      this.loading = false
    })
  }

  restoreOrder (id:number) : void {
    axios.post(process.env.VUE_APP_API_URL + '/orders/' + id + '/restore').then(() => {
      notification.success({ message: 'Order succesvol teruggezet!', description: 'Order #' + id + ' is succesvol teruggezet.', duration: 4 })
      this.getBuildingOrders()
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params.buildingId)
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het terugzetten van order!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.' })
    }).finally(() => {
      this.loading = false
    })
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    const keyboard = window.event as KeyboardEvent
    if (id) {
      const route = this.$router.resolve({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    } else {
      const route = this.$router.resolve({ name: routeName })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (this.authUser.permissions.includes('orders.show')) {
            const keyboard = window.event as KeyboardEvent
            const route = this.$router.resolve({ name: 'orderShow', params: { orderId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
