
























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import Role from '@/types/role'
import PermissionMatrix from '@/types/permissionMatrix'

@Component({
  components: {}
})

export default class UserCreateRolesPermissionsTab extends Vue {
  settings = {
    routeNames: { list: 'users', show: 'userShow', edit: 'userEdit' },
    routeParamKey: 'userId',
    functionNames: { setCurrent: 'setCurrentUser', save: 'saveUser' },
    getterNames: { current: 'currentUser', loading: 'loadingUser', errors: 'userErrors' }
  }

  roles:Role[] = []
  permissionMatrix:{ [key: string]: PermissionMatrix } = {}

  mounted () : void {
    this.getRoles()
    this.getPermissionMatrix()
  }

  getRoles () : void {
    axios.get(process.env.VUE_APP_API_URL + '/roles?sort=display_name').then((res) => {
      this.roles = res.data.data
    })
  }

  getPermissionMatrix () : void {
    axios.get(process.env.VUE_APP_API_URL + '/roles/permissionMatrix').then((res) => {
      this.permissionMatrix = res.data
    })
  }

  togglePermission (id:number) : void {
    if (this.currentItem.permission_ids.indexOf(id) !== -1) {
      this.currentItem.permission_ids.splice(this.currentItem.permission_ids.indexOf(id), 1)
    } else {
      this.currentItem.permission_ids.push(id)
    }
  }

  checkIfRolePermissionIsAssignedDirectly () : void {
    this.roles.forEach((role:Role) => {
      if (this.currentItem.role_ids.includes(role.id)) {
        role.permission_ids.forEach((permissionId:number) => {
          if (this.currentItem.permission_ids.includes(permissionId)) {
            const index = this.currentItem.permission_ids.indexOf(permissionId)
            this.currentItem.permission_ids.splice(index, 1)
          }
        })
      }
    })
  }

  get checkboxOptions () : { label:string, value:number }[] {
    const roles:{ label:string, value:number }[] = []

    this.roles.forEach((role:Role) => {
      roles.push({
        label: role.display_name,
        value: role.id
      })
    })

    return roles
  }

  get rolePermissions () : number[] {
    const rolePermissions:number[] = []
    this.roles.forEach((role:Role) => {
      if (this.currentItem.role_ids.includes(role.id)) {
        role.permission_ids.forEach((permissionId:number) => {
          if (!rolePermissions.includes(permissionId)) {
            rolePermissions.push(permissionId)
          }
        })
      }
    })

    return rolePermissions
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
