





























































































































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class Account extends Vue {
  currentTab:string = new SecureLS({ isCompression: false }).get('accountEditActiveTab') || 'details'

  userDetails:{
    firstname:string|null,
    lastname:string|null,
    full_name:string|null,
    address:string|null,
    zipcode:string|null,
    city:string|null,
    email:string|null,
    phone:string|null,
    username:string|null,
    password:string|null,
    password_confirmation:string|null
  } = {
    firstname: null,
    lastname: null,
    full_name: null,
    address: null,
    zipcode: null,
    city: null,
    email: null,
    phone: null,
    username: null,
    password: null,
    password_confirmation: null
  }

  errors = {}
  loading = false
  newPassword = false

  mounted () : void {
    this.userDetails = new SecureLS({ isCompression: false }).get('authUser')
  }

  save () : void {
    this.loading = true
    this.errors = {}
    axios.post(process.env.VUE_APP_API_URL + '/users/details', this.userDetails).then((res) => {
      new SecureLS({ isCompression: false }).set('authUser', res.data.user)
      notification.success({ message: 'Gegevens opgeslagen.', description: 'Je gegevens zijn succesvol opgeslagen.', duration: 3 })
    }).catch((e) => {
      if (e.response.status === 422) {
        this.errors = e.response.data.errors
        notification.error({ message: 'Fout tijdens het opslaan!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
      } else {
        notification.error({ message: 'Fout tijdens het opslaan!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('accountEditActiveTab', tab)
    this.currentTab = tab
  }

  clearCache () : void {
    const ls = new SecureLS({ isCompression: false })
    const lsKeys = ls.getAllKeys()
    const lsKeysNotToClear = ['token', 'authUser']

    lsKeys.forEach((lsKey:string) => {
      if (!lsKeysNotToClear.includes(lsKey)) {
        ls.remove(lsKey)
      }
    })

    notification.success({ message: 'Cache gewist.', description: 'De cache is succesvol gewist.', duration: 3 })
  }

  navigateTo (routeName:string) : void {
    this.$router.push({ name: routeName })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : { firstname:string } {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
