

















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'

@Component({
  components: {}
})

export default class ProjectShowEnabledOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  loading = false
  projectOptions = [
    {
      id: 'All',
      label: 'Alles',
      children: [],
      checkable: false
    }
  ]

  enabledProjectOptions:number[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getProjectOptions()
    }
  }

  getProjectOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/availableOptionsTree').then((res) => {
      this.projectOptions = res.data
      this.projectOptions[0].checkable = false
      this.projectOptions[0].children.forEach((group:{ children:{ id:number, disableCheckbox:boolean }[], checkable:boolean }) => {
        group.checkable = false
        group.children.forEach((option:{ id:number, disableCheckbox:boolean }) => {
          option.disableCheckbox = true
        })
      })
      this.enabledProjectOptions = this.currentItem.enabled_options
    }).finally(() => {
      this.loading = false
    })
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
