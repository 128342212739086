




























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import UserCreateDetailsTab from '@/views/user/tabs/create/UserCreateDetailsTab.vue'
import UserCreateRolesPermissionsTab from '@/views/user/tabs/create/UserCreateRolesPermissionsTab.vue'

@Component({
  components: { UserCreateRolesPermissionsTab, UserCreateDetailsTab }
})

export default class UserCreate extends Vue {
  settings = {
    routeNames: { list: 'users', show: 'userShow', edit: 'userEdit' },
    routeParamKey: 'userId',
    functionNames: { setCurrent: 'setCurrentUser', clearCurrent: 'clearCurrentUser', save: 'saveUser' },
    getterNames: { current: 'currentUser', loading: 'loadingUser' }
  }

  currentTab = 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
