

































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist, Uploader }
})

export default class ProjectEditDocumentsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  directories:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean }[] = [
    {
      name: 'Verkoopdocumentatie',
      position: 'verkoopdocumentatie',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Contract',
      position: 'contract',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Gebruikershandleiding',
      position: 'gebruikershandleiding',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Revisiedocumenten',
      position: 'revisiedocumenten',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Opleverdossier',
      position: 'opleverdossier',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Kwaliteitdossier',
      position: 'kwaliteitdossier',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Servicemeldingen',
      position: 'servicemeldingen',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    },
    {
      name: 'Overig',
      position: 'overig',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: true
    }
  ]

  activePanel:string = new SecureLS({ isCompression: false }).get('projectEditDocumentsPanel') || null

  newFileUploaded (file:File, key:string, important = false, concept = false) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    if (important) {
      formData.append('important', 'yes')
    }

    if (concept) {
      formData.append('concept', 'yes')
    }

    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  setActivePanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('projectEditDocumentsPanel', panel)
    this.activePanel = panel
  }

  reloadProject () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
