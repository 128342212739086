

































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import DevToolMoveMedia from '@/components/devtools/DevToolMoveMedia.vue'
import User from '@/types/user'
import DevToolAfas from '@/components/devtools/DevToolAfas.vue'

@Component({
  components: { DevToolAfas, DevToolMoveMedia }
})

export default class DevTools extends Vue {
  currentTab:string = new SecureLS({ isCompression: false }).get('devToolsActiveTab') || 'general'

  openTelescope () : void {
    window.open(process.env.VUE_APP_API_URL + '/inloggen', '_blank')
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('devToolsActiveTab', tab)
    this.currentTab = tab
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
