

































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Column from '@/types/column'

type ProjectGroup = {
  id: number
  project: string
  project_id: number
  group: string
  group_id: number
  active: boolean
}

@Component({
  components: {}
})

export default class UserRelatedProjects extends Vue {
  @Prop({ required: true }) userId!: number
  @Prop({ required: false, default: false, type: Boolean }) editable!: boolean

  columns:Column[] = [
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      sorter: (a:{project: string, group: string}, b:{project: string, group: string}) => a.project.localeCompare(b.project),
      scopedSlots: { customRender: 'project' }
    },
    {
      title: 'Groep',
      dataIndex: 'group',
      key: 'group',
      width: 300,
      sorter: (a:{project: string, group: string}, b:{project: string, group: string}) => a.group.localeCompare(b.group)
    },
    {
      title: 'Acties',
      key: 'actions',
      width: 100,
      scopedSlots: { customRender: 'actions' },
      align: 'right'
    }
  ]

  projects:ProjectGroup[] = []
  loading = false

  mounted () : void {
    this.getRelatedProjects()
  }

  getRelatedProjects () : void {
    if (!this.userId) return
    this.loading = true
    const url = `${process.env.VUE_APP_API_URL}/members/${this.userId}/projects`
    axios.get(url).then((res) => {
      this.projects = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  disconnectUser (projectGroup: ProjectGroup) : void {
    if (!this.userId) return
    this.loading = true
    const payload = {
      model_id: this.userId,
      project_id: projectGroup.project_id,
      type: 'user',
      group_id: projectGroup.group_id
    }
    const url = `${process.env.VUE_APP_API_URL}/members/${projectGroup.project_id}`
    axios.patch(url, payload).then(() => {
      this.getRelatedProjects()
    }).finally(() => {
      this.loading = false
    })
  }

  goToProject (projectGroup: ProjectGroup) : void {
    this.$router.push({ name: 'projectEdit', params: { projectId: projectGroup.project_id.toString() } })
  }

  onRowClick (rowData:ProjectGroup) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          const route = this.$router.resolve({ name: this.editable ? 'projectEdit' : 'projectShow', params: { projectId: rowData.project_id.toString() } })
          window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
        }
      }
    }
  }
}
