


















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import InfoHover from '@/components/dashboard/InfoHover.vue'
import { notification } from 'ant-design-vue'

@Component({
  components: { InfoHover }
})

export default class ProjectEditEnabledOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  loading = false
  projectOptions = [
    {
      id: 'All',
      label: 'Alles',
      children: []
    }
  ]

  enabledProjectOptions:number[] = []
  showMissingOptionModal = false
  missingOptions:{ id:number, name:string, external_ref:string }[] = []
  missingOptionIds = []
  buildings:{ id:number, number:number, reference:string }[] = []
  buildingIds = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getProjectOptions()
      this.getMissingOptions()
      this.getBuildings()
    }
  }

  getProjectOptions () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/availableOptionsTree').then((res) => {
      this.projectOptions = res.data
      this.enabledProjectOptions = this.currentItem.enabled_options
    }).finally(() => {
      this.loading = false
    })
  }

  enabledProjectOptionsChanged () : void {
    this.$store.dispatch('enabledProjectOptionsChanged', this.enabledProjectOptions)
  }

  toggleMissingOptionModal () : void {
    this.showMissingOptionModal = !this.showMissingOptionModal
    this.missingOptionIds = []
  }

  getMissingOptions () : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/missingOptions').then((r) => {
      this.missingOptions = r.data
    })
  }

  addMissingOptions () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/missingOptions', { missingOptionIds: this.missingOptionIds, buildingIds: this.buildingIds }).then((r) => {
      notification.success({ message: 'Optie(s) toegevoegd.', description: 'De optie(s) zijn succesvol toegevoegd aan het project. Je kunt ze nu activeren.', duration: 3 })
      this.toggleMissingOptionModal()
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }).catch(() => {
      notification.error({
        message: 'Fout tijdens het toevoegen van opties!',
        description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      this.loading = false
    })
  }

  getBuildings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project.id]=' + this.currentItem.id + '&limit=1000').then((r) => {
      this.buildings = r.data.data
    })
  }

  filterDropdown (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
