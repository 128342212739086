




















































































import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class DevToolMoveMedia extends Vue {
  models:string[] = []
  fromDirectories:string[] = []
  files:{ id:number, name:string }[] = []

  showConfirmationModal = false
  moving = false
  loading = {
    models: false,
    from: {
      directories: false,
      files: false
    }
  }

  fileToMove:{ from: { model:string|null, model_id:number|null, directory:string|null, media_id:number|null }, to: { model:string|null, model_id:number|null, directory:string|null } } = {
    from: {
      model: null,
      model_id: null,
      directory: null,
      media_id: null
    },
    to: {
      model: null,
      model_id: null,
      directory: null
    }
  }

  mounted () : void {
    this.getModels()
  }

  getModels () : void {
    this.loading.models = true
    axios.get(process.env.VUE_APP_API_URL + '/devtools/media/models').then((r) => {
      this.models = r.data
    }).finally(() => {
      this.loading.models = false
    })
  }

  getFromDirectories () : void {
    this.loading.from.directories = true
    axios.post(process.env.VUE_APP_API_URL + '/devtools/media/from/directories', { model: this.fileToMove.from.model, model_id: this.fileToMove.from.model_id }).then((r) => {
      this.fromDirectories = r.data
    }).finally(() => {
      this.loading.from.directories = false
    })
  }

  getFiles () : void {
    this.loading.from.files = true
    axios.post(process.env.VUE_APP_API_URL + '/devtools/media', { model: this.fileToMove.from.model, model_id: this.fileToMove.from.model_id, directory: this.fileToMove.from.directory }).then((r) => {
      this.files = r.data
    }).finally(() => {
      this.loading.from.files = false
    })
  }

  fromModelChanged () : void {
    this.fromDirectories = []
    this.fileToMove.from.directory = null
    this.files = []
    this.fileToMove.from.media_id = null
    if (this.fileToMove.from.model && this.fileToMove.from.model_id) {
      this.getFromDirectories()
    }
  }

  moveFile () : void {
    this.moving = true
    axios.post(process.env.VUE_APP_API_URL + '/devtools/media/' + this.fileToMove.from.media_id + '/move', { model: this.fileToMove.to.model, model_id: this.fileToMove.to.model_id, directory: this.fileToMove.to.directory }).then((r) => {
      this.fileToMove = { from: { model: null, model_id: null, directory: null, media_id: null }, to: { model: null, model_id: null, directory: null } }
      this.fromDirectories = []
      this.files = []
      this.showConfirmationModal = false
      notification.success({ message: 'Bestand verplaatst.', description: 'Het bestand is succesvol verplaatst.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Bestand NIET verplaatst.', description: 'Het bestand is NIET verplaatst. Probeer het later nog een keer.' })
    }).finally(() => {
      this.moving = false
    })
  }

  get currentFileName () : string {
    let filename = ''
    this.files.forEach((f:{ id:number, name:string }) => {
      if (this.fileToMove.from.media_id === f.id) {
        filename = f.name
      }
    })

    return filename
  }

  get buttonDisabled () : boolean {
    return !this.fileToMove.from.model || !this.fileToMove.from.model_id || !this.fileToMove.from.directory || !this.fileToMove.from.media_id || !this.fileToMove.to.model || !this.fileToMove.to.model_id || !this.fileToMove.to.directory || this.moving
  }
}
