
































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import Issue from '@/types/issue'
import Objection from '@/types/objection'
import Question from '@/types/question'

@Component({
  components: {}
})

export default class BuildingShowIssuesTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  activeTab = 'issues'

  get columns (): Column[] {
    const columns = [
      {
        title: '#',
        key: 'id',
        dataIndex: 'id'
      },
      {
        title: 'Titel',
        key: 'title',
        dataIndex: 'title'
      },
      {
        title: 'Gemeld op',
        key: 'created_at',
        dataIndex: 'created_at',
        scopedSlots: { customRender: 'createdAt' }
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        width: 120
      },
      {
        title: 'Actie\'s',
        key: 'action',
        scopedSlots: { customRender: 'action' }
      }
    ]

    // if active tab is issues, add type column
    if (this.activeTab === 'issues') {
      columns.splice(1, 0, {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        scopedSlots: { customRender: 'type' },
        width: 120
      })
    }

    return columns
  }

  loading = {
    issues: false,
    objections: false,
    questions: false
  }

  issues:Issue[] = []
  objections:Objection[] = []
  questions:Question[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getIssues()
      this.getObjections()
      this.getQuestions()
    }
  }

  getIssues () : void {
    this.loading.issues = true
    axios.get(process.env.VUE_APP_API_URL + '/issues?filter[building.id]=' + this.currentItem.id + '&sort=-id').then((res) => {
      this.issues = res.data.data
    }).finally(() => {
      this.loading.issues = false
    })
  }

  getObjections () : void {
    this.loading.objections = true
    axios.get(process.env.VUE_APP_API_URL + '/objections?filter[building.id]=' + this.currentItem.id + '&sort=-id').then((res) => {
      this.objections = res.data.data
    }).finally(() => {
      this.loading.objections = false
    })
  }

  getQuestions () : void {
    this.loading.questions = true
    axios.get(process.env.VUE_APP_API_URL + '/questions?filter[building.id]=' + this.currentItem.id + '&sort=-id').then((res) => {
      this.questions = res.data.data
    }).finally(() => {
      this.loading.questions = false
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    const keyboard = window.event as KeyboardEvent
    if (id) {
      const route = this.$router.resolve({ name: routeName, params: { [paramKey]: id.toString() } })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    } else {
      const route = this.$router.resolve({ name: routeName })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    }
  }

  onIssueRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('issues.edit')) {
            const route = this.$router.resolve({ name: 'issueEdit', params: { issueId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  onObjectionRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('objections.edit')) {
            const route = this.$router.resolve({ name: 'objectionEdit', params: { objectionId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  onQuestionRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('questions.edit')) {
            const route = this.$router.resolve({ name: 'questionEdit', params: { questionId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
