





































































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import User from '@/types/user'
import Column from '@/types/column'
import { notification } from 'ant-design-vue'

class DataEntry {
  id = 0
  name = '---'
  active = false
}

class ProjectSolverEntry {
  group_id = 0
  company = new DataEntry()
  solver = new DataEntry()
}

@Component

export default class ProjectSolvers extends Vue {
  @Prop({ required: true }) projectId!: number
  @Prop({ required: false, type: Boolean, default: false }) canEdit!: boolean

  projectSolversUrl = `${process.env.VUE_APP_API_URL}/solvers/`

  projectSolvers: ProjectSolverEntry[] = []
  companyOptions: DataEntry[] = []
  solverOptions: User[] = []

  modalVisible = false
  loadingCompanies = false
  loadingSolvers = false
  loadingCategories = false

  defaultGroup = new ProjectSolverEntry()

  modalData = {
    group_id: 0,
    company_id: 0,
    solver_id: 0
  }

  columns: Column[] = [
    {
      title: 'Groep',
      dataIndex: 'group_id',
      key: 'group_id',
      scopedSlots: { customRender: 'group' },
      width: 160
    },
    {
      title: 'Leverancier',
      key: 'company',
      dataIndex: 'company',
      scopedSlots: { customRender: 'company' }
    },
    {
      title: 'Medewerker',
      key: 'solver',
      dataIndex: 'solver',
      scopedSlots: { customRender: 'solver' }
    }
  ];

  elementCategories: DataEntry[] = []

  async mounted (): Promise<void> {
    if (!this.projectId) return
    await this.getElementCategories()
    await axios.get(this.projectSolversUrl + this.projectId).then(res => {
      this.projectSolvers = res.data
    })
  }

  get activeColumns (): Column[] {
    if (this.canEdit) { // Add the action column if editable
      this.columns.push({ title: '', key: 'actions', scopedSlots: { customRender: 'actions' }, width: 74, align: 'center' })
    }
    return this.columns
  }

  get activeSolvers (): ProjectSolverEntry[] {
    return this.elementCategories.sort((a, b) => { return a.id - b.id }).map(group => {
      const result = this.projectSolvers.find(d => d.group_id === group.id)

      // return an object with the group id and the company and solver data
      return {
        group_id: group.id,
        company: result ? result.company : new DataEntry(),
        solver: result ? result.solver : new DataEntry()
      }
    })
  }

  showInactiveSolverOptions = true
  get activeSolverOptions (): User[] {
    return this.showInactiveSolverOptions ? this.solverOptions : this.solverOptions.filter((s:User) => s.active)
  }

  FilterInactiveSolvers (open: boolean): void {
    this.showInactiveSolverOptions = !open
  }

  showModal (groupId: number): void {
    const groupData = this.getSelectedGroup(groupId)
    this.groupToModalData(groupData)

    this.modalVisible = true

    this.getCompanies()
    this.getSolvers()
  }

  closeModal (): void {
    this.modalVisible = false
    this.groupToModalData(this.defaultGroup)
  }

  groupToModalData (groupData: ProjectSolverEntry): void {
    this.modalData.group_id = groupData.group_id
    this.modalData.company_id = groupData.company.id
    this.modalData.solver_id = groupData.solver ? groupData.solver.id : 0
  }

  getGroupNameById (groupId: number): string {
    const group = this.elementCategories.find((g) => g.id === groupId)
    return group ? `${group.name} (${group.id})` : '---'
  }

  getSelectedGroup (id: number) :ProjectSolverEntry {
    const group = this.activeSolvers.find(x => x.group_id === id)
    if (group === undefined) return this.defaultGroup

    return group
  }

  SetSelectedCompany (): void {
    if (this.modalData.company_id === 0) {
      this.modalData.solver_id = 0
    } else {
      this.getSolvers()
    }
  }

  clearSolver (groupId: number): void {
    this.modalData.group_id = groupId
    this.modalData.company_id = 0
    this.updateGroupData()
  }

  getCompanies (): void {
    this.loadingCompanies = true
    axios.get(this.projectSolversUrl + 'companies')
      .then(res => {
        this.companyOptions = res.data
      })
      .finally(() => {
        this.loadingCompanies = false
      })
  }

  getSolvers (): void {
    if (this.modalData.company_id === 0) return

    this.loadingSolvers = true
    axios.get(`${process.env.VUE_APP_API_URL}/companies/${this.modalData.company_id}/employees`)
      .then(res => {
        this.solverOptions = res.data
      })
      .finally(() => {
        this.loadingSolvers = false
      })
  }

  async getElementCategories (): Promise<void> {
    this.loadingCategories = true
    await axios.get(this.projectSolversUrl + 'categories')
      .then(res => {
        this.elementCategories = res.data
      })
      .finally(() => {
        this.loadingCategories = false
      })
  }

  updateProjectSolvers (): void {
    this.modalData.company_id !== 0
      ? this.saveGroupData()
      : this.updateGroupData()

    this.closeModal()
  }

  updateGroupData (): void {
    axios.patch(this.projectSolversUrl + this.projectId, { group_id: this.modalData.group_id })
      .then(res => {
        this.projectSolvers = res.data
        notification.success({ message: 'Groep bijgewerkt.', description: '', duration: 3 })
      })
  }

  saveGroupData (): void {
    const payload = {
      group_id: this.modalData.group_id,
      company_id: this.modalData.company_id,
      solver_id: this.modalData.solver_id
    }

    axios.post(this.projectSolversUrl + this.projectId, payload)
      .then(res => {
        this.projectSolvers = res.data
        notification.success({ message: 'Succesvol gekoppeld.', description: '', duration: 3 })
      })
  }

  filterOptions (input: string, option: { componentOptions: { children: { text: string }[] } }): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onRowClick (rowData:ProjectSolverEntry) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (!this.canEdit) return
          this.showModal(rowData.group_id)
        }
      }
    }
  }
}
