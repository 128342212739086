import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import FivePercentRuleModel from '@/models/FivePercentRuleModel'
import { notification } from 'ant-design-vue'

Vue.use(Vuex)

export const fivePercentRuleStore: Module<any, any> = {
  state: {
    fivePercentRuleList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentFivePercentRule: {},
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentFivePercentRule (state, { currentFivePercentRule }) {
      state.currentFivePercentRule = currentFivePercentRule
    },
    clearCurrentFivePercentRule (state) {
      state.currentFivePercentRule = {}
    },
    setFivePercentRuleList (state, { fivePercentRuleList }) {
      state.fivePercentRuleList = fivePercentRuleList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getFivePercentRuleList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let fivePercentRuleModel = new FivePercentRuleModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            fivePercentRuleModel = fivePercentRuleModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        fivePercentRuleModel = fivePercentRuleModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        fivePercentRuleModel = fivePercentRuleModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        fivePercentRuleModel = fivePercentRuleModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      fivePercentRuleModel.get().then((fivePercentRuleList) => {
        commit('setFivePercentRuleList', { fivePercentRuleList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van 5% regelingen!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentFivePercentRule ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        FivePercentRuleModel.$find(id).then((currentFivePercentRule) => {
          commit('setCurrentFivePercentRule', { currentFivePercentRule })
          resolve(currentFivePercentRule)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze 5% regeling!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentFivePercentRule ({ commit }) {
      commit('clearCurrentFivePercentRule')
    }
  },
  getters: {
    fivePercentRuleList: state => state.fivePercentRuleList,
    currentFivePercentRule: state => state.currentFivePercentRule,
    loadingFivePercentRule: state => state.loading,
    fivePercentRuleErrors: state => state.errors
  }
}
