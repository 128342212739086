import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import { notification } from 'ant-design-vue'
import moment from 'moment/moment'
import 'moment/locale/nl'
import router from '@/router'
import SecureLS from 'secure-ls'
import NewsItemModel from '@/models/NewsItemModel'
import NewsItem from '@/types/newsItem'
moment.locale('nl')

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })
export const newsItemStore: Module<any, any> = {
  state: {
    newsItemList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    InactiveNewsItemColumns: ls.get('news-columns') || [],
    currentNewsItem: new NewsItem(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentNewsItem (state, { currentNewsItem }) {
      state.currentNewsItem = currentNewsItem
    },
    clearCurrentNewsItem (state) {
      state.currentNewsItem = new NewsItem()
    },
    setNewsProjectId (state, id) {
      state.currentNewsItem.project_id = id
    },
    setNewsBuildingIds (state, ids) {
      state.currentNewsItem.buildings = ids
    },
    setNewsItemList (state, itemList) {
      state.newsItemList = itemList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    enabledNewsItemOptionsChanged (state, { enabledOptions }) {
      state.currentNewsItem.enabled_options = enabledOptions
    },
    setInactiveNewsItemColumns (state, columns) {
      state.InactiveNewsItemColumns = columns
    },
    setNewsMainImage (state, image) {
      state.currentNewsItem.mainImage = image
    },
    setNewsExtraImages (state, images) {
      state.currentNewsItem.extraImages = images
    },
    removeImageAtIndex (state, index) {
      state.currentNewsItem.extraImages.splice(index, 1)
    }
  },
  actions: {
    getNewsItemList ({ commit }, settings) {
      commit('setLoading', { type: 'list', state: true })
      let newsItemModel = new NewsItemModel()
      newsItemModel = newsItemModel.where('published', settings.archived)

      if (settings.activeFilters) {
        // Then check if they need to be filtered.
        Object.keys(settings.activeFilters).forEach(key => {
          if (settings.activeFilters[key].length) {
            newsItemModel = newsItemModel.whereIn(key, settings.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (settings.search) {
        newsItemModel = newsItemModel.where('search', settings.search)
      }

      // Add orderBy if sort is set.
      if (settings.sort) {
        newsItemModel = newsItemModel.orderBy(settings.sort.order === 'ascend' ? settings.sort.columnKey : '-' + settings.sort.columnKey)
      }

      if (settings.pagination) {
        newsItemModel = newsItemModel.limit(settings.pagination.pageSize).page(settings.pagination.current)
      }

      newsItemModel.get().then((newsItemList) => {
        commit('setNewsItemList', newsItemList)
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van berichten!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentNewsItem ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        NewsItemModel.$find(id).then((currentNewsItem) => {
          commit('setCurrentNewsItem', { currentNewsItem: currentNewsItem })
          resolve(currentNewsItem)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van dit bericht!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    setNewsProjectId ({ commit }, id) {
      commit('setNewsProjectId', id)
    },
    setNewsBuildingIds ({ commit }, ids) {
      commit('setNewsBuildingIds', ids)
    },
    clearCurrentNewsItem ({ commit }) {
      commit('clearCurrentNewsItem')
    },
    saveNewsItem ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const newsItemModel = new NewsItemModel(data)
      // Save news item.
      newsItemModel.save().then((currentNewsItem) => {
        commit('setErrors', {})
        commit('setCurrentNewsItem', { currentNewsItem: currentNewsItem })
        if (data.id) {
          notification.success({ message: 'Bericht opgeslagen.', description: 'Het bericht is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bericht toegevoegd.', description: 'Het bericht is succesvol toegevoegd.', duration: 3 })
          if (currentNewsItem.id) {
            router.push({ name: 'newsEdit', params: { newsItemId: currentNewsItem.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van dit bericht!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van dit bericht!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    setInactiveNewsItemColumns ({ commit }, columns) {
      ls.set('news-columns', columns)
      commit('setInactiveNewsItemColumns', columns)
    },
    enabledNewsItemOptionsChanged ({ commit }, enabledOptions) {
      commit('enabledNewsItemOptionsChanged', { enabledOptions })
    },
    setNewsMainImage ({ commit }, image) {
      commit('setNewsMainImage', image)
    },
    setNewsExtraImages ({ commit }, images) {
      commit('setNewsExtraImages', images)
    },
    removeExtraNewsImage ({ commit }, index) {
      commit('removeImageAtIndex', index)
    }
  },
  getters: {
    newsItemList: state => state.newsItemList,
    currentNewsItem: state => state.currentNewsItem,
    newsItemLoading: state => state.loading,
    newsItemErrors: state => state.errors,
    InactiveNewsItemColumns: state => state.InactiveNewsItemColumns
  }
}
