




























































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import axios from 'axios'
import PageHeader from '@/components/base/PageHeader.vue'
import ColumnOptions from '@/components/listview/ColumnOptions.vue'
import Release from '@/types/release'

@Component({
  components: { ColumnOptions, PageHeader, ActiveFilters }
})

export default class Releases extends Vue {
  ls = new SecureLS({ isCompression: false })

    config = {
      entity: 'release',
      entities: 'releases',
      single: 'Melding',
      plural: 'Meldingen',
      subtitle: 'Overzicht van meldingen.'
    }

  routeNames = { create: 'releaseCreate', show: 'releaseShow', edit: 'releaseEdit' }
  settings = {
    routeParamKey: 'releaseId',
    permissionNames: { show: 'releases.view', edit: 'releases.edit', delete: 'releases.delete' },
    functionNames: { getList: 'getReleaseList' },
    getterNames: { list: 'releaseList', loading: 'loadingFeedback' },
    inactiveColumns: 'InactiveFeedbackColumns'
  }

  localStorageName = 'releaseTableSettings'

  sortOrder = { column: 'id', order: 'descend' }
  conditions = {

  }

  tableSettings:TableSettings = this.ls.get(this.localStorageName) || new TableSettings(this.conditions, this.sortOrder)

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Versie',
      key: 'version',
      dataIndex: 'version',
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Released door',
      key: 'released_by',
      dataIndex: 'released_by',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'released_by' }
    },
    {
      title: 'Released op',
      key: 'released_at',
      scopedSlots: { customRender: 'released_at' },
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  filtersLoaded = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.getFilters()
    this.onTableSettingsChange()
  }

  getRowClassName (record: Record<string, unknown>): string {
    const classStringArray = []

    if (!('tasks' in record && (this.canEdit || this.canView))) {
      classStringArray.push('no-expand-icon')
    }
    if ('read' in record) {
      classStringArray.push(record.read ? 'read' : 'unread')
    }
    return classStringArray.join(' ')
  }

  async getFilters () : Promise<void> {
    this.filtersLoaded = true
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  removedFilter (activeFilters:{[key: string]: number[]|string[]}) : void {
    // Set updated filters.
    this.tableSettings.activeFilters = activeFilters

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  publish (release: Release) : void {
    axios.get(`${process.env.VUE_APP_API_URL}/releases/${release.id}/publish`)
      .then(() => {
        this.$message.success('Release gepubliceerd')
        this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
      }).catch(() => {
        this.$message.error('Release niet gepubliceerd')
      })
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            this.handleRouting(this.routeNames.edit, keyboard, rowData)
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            this.handleRouting(this.routeNames.show, keyboard, rowData)
          }
        }
      }
    }
  }

  handleRouting (routeName:string, keyboard: KeyboardEvent, rowData: { id: number }) :void {
    if (keyboard.ctrlKey) {
      const route = this.$router.resolve({
        name: routeName,
        params: { [this.settings.routeParamKey]: rowData.id.toString() }
      })
      window.open(route.href, '_blank')
    } else {
      this.$router.push({
        name: routeName,
        params: { [this.settings.routeParamKey]: rowData.id.toString() }
      })
    }
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('releases.edit')
  }

  get canView (): boolean {
    return this.authUser.permissions.includes('releases.view')
  }

  canPublish (release: Release): boolean {
    return this.authUser.permissions.includes('releases.edit') && !release.released_at
  }

  // ColumnOptions functions START
  get activeColumns (): Column[] {
    return this.columns.filter(col => !this.$store.getters[this.settings.inactiveColumns].includes(col.key))
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  toggleInactiveItems (checked:boolean): void {
    this.tableSettings.archived = checked
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  get itemList () : { data: Release[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `Bouwnummer ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
