export default class TableSettings {
  public pagination!:{ current:number, pageSize:number }
  public showSizeChanger!:boolean
  public pageSizeOptions!:string[]
  public activeFilters!:Record<string, (string|number|boolean)[]>
  public archiveConditions:{[key: string]: number[]|string[]}
  public sort:{ columnKey: string; order: string } | null
  public search: string
  public archived? = false

  constructor (archiveConditions:{[key: string]: number[]|string[]}, sort?:{column:string, order:string}) {
    this.pagination = { current: 1, pageSize: 25 }
    this.showSizeChanger = true
    this.pageSizeOptions = ['25', '50', '100', '250']
    this.activeFilters = { '': [] }
    this.archiveConditions = archiveConditions
    this.archived = false
    this.search = ''

    if (sort !== undefined) {
      this.sort = {
        columnKey: sort?.column || 'created_at',
        order: sort?.order || 'descend'
      }
    } else {
      this.sort = null
    }
  }
}
