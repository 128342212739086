


























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class BuildingEditSurveysTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'survey_form',
      scopedSlots: { customRender: 'survey_form' }
    },
    {
      title: 'Uitgevoerd op',
      key: 'completed_at',
      dataIndex: 'completed_at',
      scopedSlots: { customRender: 'completed_at' }
    },
    {
      title: 'Uitgevoerd door',
      key: 'completed_by_user',
      dataIndex: 'completed_by_user',
      scopedSlots: { customRender: 'completed_by_user' }
    },
    {
      title: 'Resultaat',
      key: 'result',
      dataIndex: 'result',
      scopedSlots: { customRender: 'result' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  surveys = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getSurveys()
    }
  }

  getSurveys () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/surveys?filter[building.id]=' + this.currentItem.id).then((res) => {
      this.surveys = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
