import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import GuardCodeModel from '@/models/GuardCodeModel'
import { notification } from 'ant-design-vue'
import router from '@/router'

Vue.use(Vuex)

export const guardCodeStore: Module<any, any> = {
  state: {
    guardCodeList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentGuardCode: {
      code: '',
      description: ''
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentGuardCode (state, { currentGuardCode }) {
      state.currentGuardCode = currentGuardCode
    },
    clearCurrentGuardCode (state) {
      state.currentGuardCode = {
        code: '',
        description: ''
      }
    },
    setGuardCodeList (state, { guardCodeList }) {
      state.guardCodeList = guardCodeList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getGuardCodeList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let guardCodeModel = new GuardCodeModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            guardCodeModel = guardCodeModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        guardCodeModel = guardCodeModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        guardCodeModel = guardCodeModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        guardCodeModel = guardCodeModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      guardCodeModel.get().then((guardCodeList) => {
        commit('setGuardCodeList', { guardCodeList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de bewakingscodes!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentGuardCode ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        GuardCodeModel.$find(id).then((currentGuardCode) => {
          commit('setCurrentGuardCode', { currentGuardCode })
          resolve(currentGuardCode)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze bewakingscode!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentGuardCode ({ commit }) {
      commit('clearCurrentGuardCode')
    },
    saveGuardCode ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const guardCodeModel = new GuardCodeModel(data)

      // Save guardCode.
      guardCodeModel.save().then((currentGuardCode) => {
        commit('setErrors', {})
        commit('setCurrentGuardCode', { currentGuardCode })
        if (data.id) {
          notification.success({ message: 'Bewakingscode opgeslagen.', description: 'De bewakingscode is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Bewakingscode toegevoegd.', description: 'De bewakingscode is succesvol toegevoegd.', duration: 3 })
          if (currentGuardCode.id) {
            router.push({ name: 'guardCodeEdit', params: { guardCodeId: currentGuardCode.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze bewakingscode!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze bewakingscode!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    guardCodeList: state => state.guardCodeList,
    currentGuardCode: state => state.currentGuardCode,
    loadingGuardCode: state => state.loading,
    guardCodeErrors: state => state.errors
  }
}
