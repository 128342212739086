































































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import TextEditor from '@/components/base/TextEditor.vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'

interface StringKey {
  [key: string]: any
}

@Component({
  components: { TextEditor }
})

export default class Settings extends Vue {
  currentTab:string = new SecureLS({ isCompression: false }).get('devToolsEditActiveTab') || 'general'
  saving:StringKey = { buyersPortal: { notification: false } }
  appSettings:StringKey = {
    buyersPortal: {
      notification: { title: null, description: null, pages: [] }
    }
  }

  buyersPortalPages = [
    {
      label: 'Login',
      value: 'Login'
    },
    {
      label: 'Dashboard',
      value: 'Dashboard'
    },
    {
      label: 'Service',
      value: 'Service'
    }
  ]

  mounted () : void {
    this.getSettings()
  }

  getSettings () : void {
    axios.get(process.env.VUE_APP_API_URL + '/appSettings').then((r) => {
      this.appSettings.buyersPortal.notification = r.data.buyersPortal.notification || this.appSettings.buyersportal.notification
    })
  }

  store (app:string, setting:string) : void {
    this.saving[app][setting] = true
    axios.post(process.env.VUE_APP_API_URL + '/appSettings/' + app, { settingName: 'notification', settingValue: this.appSettings[app][setting] }).then(() => {
      notification.success({ message: 'Instellingen opgeslagen.', description: 'De instellingen zijn succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Fout! Instellingen NIET opgeslagen.', description: 'Er is iets mis gegaan. Probeer het later nog een keer.' })
    }).finally(() => {
      this.saving[app][setting] = false
    })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('devToolsEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string) : void {
    this.$router.push({ name: routeName })
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get authUser () : { firstname:string } {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
