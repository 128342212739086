




































































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import ProjectSolvers from '@/views/project/ProjectSolvers.vue'

class Entity {
  id!: number
  active!: boolean
  type!: string
  name!: string
}

class MemberGroupInterface {
  id!: number
  role!: string
  label!: string
  multiple!: boolean
  type!:string
  values!: Entity[]
  constructor (id: number, role: string, label:string, multiple:boolean, type:string, values:Entity[]) {
    this.id = id
    this.role = role
    this.label = label
    this.multiple = multiple
    this.type = type
    this.values = values
  }
}

class ModalInfo {
  visible!: boolean
  loading!: boolean
  title!: 'Selecteer een persoon of bedrijf'
  style!: { padding: '10px 25px' }
  selected!: number | null
  selectedType!: string
  group!: string
  groupId!: number
  multiple!: boolean
  ok!: (value:number) => void
  cancel!: ()=> void
}

@Component({
  components: { ProjectSolvers }
})

export default class ProjectEdit extends Vue {
  @Prop({ required: true }) projectId!: number
  @Prop({ required: false, type: Boolean, default: false }) canEdit!: boolean

  membersUrl = `${process.env.VUE_APP_API_URL}/members/${this.projectId}`
  usersUrl = `${process.env.VUE_APP_API_URL}/users`
  companiesUrl = `${process.env.VUE_APP_API_URL}/companies`

  loading = {
    resources: false,
    companies: false
  }

  modal:ModalInfo = {
    visible: false,
    loading: false,
    title: 'Selecteer een persoon of bedrijf',
    style: { padding: '10px 25px' },
    selected: null,
    selectedType: 'user',
    group: '',
    groupId: 0,
    multiple: false,
    ok: this.addEntity,
    cancel: this.closeModal
  }

  sections:{[key: string]: MemberGroupInterface} = {
    projectLeader: new MemberGroupInterface(
      11,
      'project_leader',
      'Projectleider',
      false,
      'user',
      []
    ),
    manager: new MemberGroupInterface(
      0,
      'project_manager',
      'Werkvoorbereider Klant',
      false,
      'user',
      []
    ),
    questions: new MemberGroupInterface(
      1,
      'question_manager',
      'Vragen Behandelaar',
      false,
      'user',
      []
    ),
    objections: new MemberGroupInterface(
      2,
      'objection_manager',
      'Bezwaren Behandelaar',
      false,
      'user',
      []
    ),
    issues: new MemberGroupInterface(
      3,
      'issue_manager',
      'Service Behandelaar (Uitvoerder)',
      false,
      'user',
      []
    ),
    aftercare: new MemberGroupInterface(
      7,
      'issue_manager',
      'Service Behandelaar (Nazorg)',
      false,
      'user',
      []
    ),
    rentals: new MemberGroupInterface(
      8,
      'rental_manager',
      'Verhuur Behandelaar',
      false,
      'user',
      []
    ),
    accountant: new MemberGroupInterface(
      6,
      'finance',
      'Administrateur',
      false,
      'user',
      []
    ),
    fivePercentManager: new MemberGroupInterface(
      9,
      'employee',
      '5% manager',
      false,
      'user',
      []
    ),
    internal: new MemberGroupInterface(
      4,
      'employee',
      'Overige',
      true,
      'user',
      []
    ),
    external: new MemberGroupInterface(
      10,
      'external',
      'Externe personen',
      true,
      'user',
      []
    )
  }

  entities:Entity[] = []
  companies:Entity[] = []

  mounted (): void {
    this.getMembers()
  }

  get entityList (): Entity[] {
    return this.entities.filter(e => {
      if (this.sections[this.modal.group].type === 'user') {
        return !this.sections[this.modal.group].values.some(value => value.name === e.name)
      }
      return !this.sections[this.modal.group].values.some(value => value.name === e.name)
    }) || []
  }

  confirmDelete (e:Event, group:string, id:number): void {
    this.removeEntity(group, id)
  }

  firstOfGroup (group: string): Entity | [] {
    const memberGroup = this.sections[group]
    return memberGroup.values.length > 0 ? memberGroup.values[0] : []
  }

  inactiveTooltip (entity: Entity): string {
    return !entity.active ? 'Gebruiker is niet meer actief' : ''
  }

  showAddEntityModal (group:string): void {
    this.modal.visible = true
    this.modal.group = group
    this.modal.selectedType = this.sections[group].type
    this.modal.multiple = this.sections[group].multiple
    this.entities = []
    this.loadEntityList(this.sections[group].type, group)
  }

  removeEntity (group:string, id:number): void {
    const values = Object.values(this.sections[group].values)
    const member = values.filter((r) => r.id === id)[0]

    const index = values.map(function (x) {
      return x.id
    }).indexOf(id)

    values.splice(index, 1)

    this.sections[group].values = values
    this.updateMember(id, member.type, group)
  }

  addEntity (value: number): void {
    const entity:Entity|undefined = this.entityList.find(e => e.id === value)

    if (entity !== undefined) {
      entity.type = this.modal.selectedType
      this.sections[this.modal.group].values.push(entity)
    }

    this.addMember(value, this.modal.selectedType, this.modal.group)

    if (!this.sections[this.modal.group].multiple) {
      this.closeModal()
    }
  }

  searchEntity (input:string, option: Option): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  closeModal (): void {
    this.modal.visible = false
    this.modal.selected = null
  }

  loadEntityList (type:string, group:string): void {
    this.modal.loading = true

    switch (type) {
      case 'user':
        this.getUsers(this.sections[group].role)
        break

      case 'company':
        this.getCompanies()
        break
    }
  }

  getUsers (role: string): void {
    axios.get(this.usersUrl + `?filter[roles.name]=${role}&limit=2500`)
      .then(res => {
        this.entities = res.data.data.map((e:any) => {
          e.name = e.full_name
          return e
        })
        // No notification needed
      })
      .catch((err:Error) => {
        notification.error({ message: 'Fout bij het ophalen!', description: 'Het is niet gelukt de gegevens op te halen. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
      .finally(() => {
        this.modal.loading = false
      })
  }

  getCompanies (): void {
    axios.get(this.companiesUrl)
      .then((res:any) => {
        this.companies = res.data.data.map((e:any) => {
          e.active = true
          return e
        })
        // No notification needed
      })
      .catch((err:Error) => {
        notification.error({ message: 'Fout bij het ophalen!', description: 'Het is niet gelukt de gegevens op te halen. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
      .finally(() => {
        this.modal.loading = false
        this.loading.companies = false
      })
  }

  getMembers (): void {
    if (!this.projectId) return

    this.loading.resources = true
    axios.get(this.membersUrl)
      .then((res:any) => {
        for (const group in this.sections) {
          this.sections[group].values = res.data[group] || []
        }
        // No notification needed
      })
      .catch((err:Error) => {
        notification.error({ message: 'Fout bij het ophalen!', description: 'Het is niet gelukt de gegevens op te halen. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
      .finally(() => {
        this.loading.resources = !this.loading.resources
      })
  }

  addMember (id:number, type:string, group:string): void {
    if (!this.projectId) return

    const payload = {
      project_id: this.projectId,
      model_id: id,
      type: type,
      group_id: this.sections[group].id
    }
    axios.post(this.membersUrl, payload)
      .then(() => {
        notification.success({ message: 'Succesvol gekoppeld.', description: '', duration: 3 })
      })
      .catch((err:Error) => {
        notification.error({ message: 'Fout bij het koppelen!', description: 'Het koppelen is niet gelukt. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
  }

  updateMember (id:number, type:string, group:string): void {
    if (!this.projectId) return

    const payload = {
      project_id: this.projectId,
      model_id: id,
      type: type,
      group_id: this.sections[group].id
    }

    axios.patch(this.membersUrl, payload)
      .then(() => {
        notification.success({ message: 'Succesvol ontkoppeld.', description: '', duration: 3 })
      })
      .catch((err: Error) => {
        notification.error({ message: 'Fout bij het ontkoppelen!', description: 'Ontkoppelen is mislukt. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
  }
}
