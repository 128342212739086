


















































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer from '@/types/customer'
import CustomerEditDetailsTab from '@/views/customer/tabs/edit/CustomerEditDetailsTab.vue'
import CustomerEditContactsTab from '@/views/customer/tabs/edit/CustomerEditContactsTab.vue'
import Column from '@/types/column'
import CustomerEditConfigurationsTab from '@/views/customer/tabs/edit/CustomerEditConfigurationsTab.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import CustomerDocumentsTab from '@/views/customer/tabs/CustomerDocumentsTab.vue'

@Component({
  components: { CustomerDocumentsTab, ActivityLogTable, CustomerEditConfigurationsTab, CustomerEditContactsTab, CustomerEditDetailsTab }
})

export default class CustomerEdit extends Vue {
  settings = {
    routeNames: { list: 'customers', show: 'customerShow', edit: 'customerEdit' },
    routeParamKey: 'customerId',
    functionNames: { setCurrent: 'setCurrentCustomer', clearCurrent: 'clearCurrentCustomer', save: 'saveCustomer' },
    getterNames: { current: 'currentCustomer', loading: 'loadingCustomer' }
  }

  editable = true

  currentTab:string = new SecureLS({ isCompression: false }).get('customerEditActiveTab') || 'details'

  customers:Customer[] = []

  buildingColumns:Column[] = [
    {
      title: 'Project',
      key: 'project',
      scopedSlots: { customRender: 'project' }
    },
    {
      title: 'Bouwnummer',
      key: 'number',
      scopedSlots: { customRender: 'number' }
    },
    {
      title: 'Adres',
      key: 'address',
      scopedSlots: { customRender: 'address' }
    },
    {
      title: 'Status',
      key: 'state',
      dataIndex: 'state_label',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  userIndexToCopy = null
  showModal = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  showDocumentsTab = true
  get isDeveloper (): boolean {
    return this.authUser.roleNames.includes('developer')
  }

  toggleDocumentsTab () : void {
    this.showDocumentsTab = !this.showDocumentsTab
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  showCopyModal () : void {
    this.showModal = true
  }

  hideCopyModal () : void {
    this.showModal = false
    this.userIndexToCopy = null
  }

  copyUserDetails () : void {
    this.$store.dispatch('copyUserDetailsToCustomer', this.userIndexToCopy)
    this.hideCopyModal()
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('customerEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('buildings.edit')) {
            const route = this.$router.resolve({ name: 'buildingEdit', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('buildings.view')) {
            const route = this.$router.resolve({ name: 'buildingShow', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Customer {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
