




import { Component, Prop, Vue } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'

@Component({
  components: { }
})

export default class ServiceStatusChart extends Vue {
  @Prop({ default: () => [] }) labels?: string[]
  @Prop({
    default: () => {
      return [
        {
          name: 'Meldingen',
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: 'Bezwaren',
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: 'Vragen',
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    }
  }) data?: []|[{ name: string, data: number[] }]

  options ?: ApexOptions = {
    chart: {
      id: 'vuechart-example'
    },
    colors: ['#ffbf69', '#e36414', '#0f4c5c'],
    xaxis: {
      categories: this.userLabels
    }
  }

  get userLabels (): string[] {
    return this.labels || []
  }
}
