













































import { Vue, Component, Prop } from 'vue-property-decorator'
import User from '@/types/user'
import TableSettings from '@/types/tableSettings'
import SecureLS from 'secure-ls'
import axios from 'axios'

import MediaItem from '@/types/mediaItem'
import { split } from 'lodash'
import Column from '@/types/column'
import EventMessageModel from '@/models/EventMessageModel'
import EventMessageModal from '@/views/communication/events/EventMessageModal.vue'

export class EventMessage {
  id: number|null = null
  trigger_id = 0
  name = ''
  type = 1
  preview: File | null = null
  targets = ''
  created_at = ''
}
@Component({
  methods: { split },
  components: { EventMessageModal }
})
export default class EventTriggerOverview extends Vue {
  @Prop({ type: Boolean }) editable!: boolean
  @Prop({ type: Array }) types!: string[]
  @Prop({ type: Array }) targets!: string[]
  @Prop({ type: Number }) triggerId!: number
  @Prop({ type: Boolean, default: false }) showNewButton!: boolean

  ls = new SecureLS({ isCompression: false })
  loading = false
  loadingPreview = false
  messageModalVisible = false
  previewModalVisible = false
  preview: MediaItem = new MediaItem()

  localStorageName = 'eventMessagesTableSettings'
  permissions = { show: 'event_messages.view', edit: 'event_messages.edit', delete: 'event_messages.delete' }
  sortOrder = { column: 'created_at', order: 'descend' }
  conditions = {}
  tableSettings:TableSettings = this.ls.get(this.localStorageName) || new TableSettings(this.conditions, this.sortOrder)

  filters: {
    categories: string[],
    types: string[],
    targets: string[]
  } = {
    categories: [],
    types: [],
    targets: []
  }

  messageId: number|null = null
  messageModel = new EventMessageModel()
  eventMessages: EventMessage[] = []

  timer?:number
  timeout = 600

  get columns (): Column[] {
    return [
      {
        title: 'Bericht',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: EventMessage, b: EventMessage) => a.name.localeCompare(b.name)
      },
      {
        title: 'Doelgroepen',
        dataIndex: 'targets',
        key: 'targets',
        scopedSlots: { customRender: 'targets' },
        sorter: (a: EventMessage, b: EventMessage) => a.targets.localeCompare(b.targets)
      },
      {
        title: 'Soort',
        dataIndex: 'type',
        key: 'type',
        width: 100,
        scopedSlots: { customRender: 'type' },
        sorter: (a: EventMessage, b: EventMessage) => a.type - b.type
      },
      { title: 'Acties', dataIndex: 'id', key: 'action', width: 100, scopedSlots: { customRender: 'action' }, align: 'right' }
    ]
  }

  mounted () : void {
    this.getEventMessages()
  }

  public getEventMessages (): void {
    if (this.triggerId === null || this.loading) return
    this.loading = true
    this.messageModel.where('trigger_id', this.triggerId).get().then((response: any) => {
      this.eventMessages = response.data || []
      this.loading = false
    }).catch((error) => {
      console.log(error)
      this.loading = false
    })
  }

  getPreviewHtml (messageId: number) : void {
    this.loading = true
    const message: EventMessage | undefined = this.eventMessages.find((m) => m.id === messageId)
    if (message === undefined || this.loadingPreview) {
      return
    }

    this.loadingPreview = true
    const payload = {
      id: message.id
    }
    axios.post(process.env.VUE_APP_API_URL + '/event-messages/preview', payload)
      .then((response) => {
        this.preview = response.data
        this.previewModalVisible = true
      }).catch((error) => {
        console.log(error)
        return ''
      })
      .finally(() => {
        this.loadingPreview = false
        this.loading = false
      })
  }

  capitalize (s: string|undefined) : string {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  get activeFilters () : Record<string, (string|number|boolean)[]> {
    return this.tableSettings.activeFilters
  }

  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.$emit('updated')
  }

  onTableChanged (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    this.tableSettings.activeFilters = filters
    this.tableSettings.pagination = pagination
    this.tableSettings.sort = sort
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          if (this.authUser.permissions.includes(this.permissions.edit)) {
            this.messageId = rowData.id
            this.messageModalVisible = true
            this.loading = true
          } else {
            this.getPreviewHtml(rowData.id)
          }
        }
      }
    }
  }

  deleteMessage (id: number) : void {
    this.loading = true
    axios.delete(process.env.VUE_APP_API_URL + '/event-messages/' + id)
      .finally(() => {
        this.loading = false
        this.getEventMessages()
      })
  }

  navigateTo (route: string, id: number) : void {
    this.$router.push({ name: route, params: { id: id.toString() } })
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get canEdit () : boolean {
    return this.authUser.permissions.includes(this.permissions.edit)
  }

  onMessageModalClosed (updated: boolean) : void {
    this.messageModalVisible = false
    this.messageId = null
    this.loading = false

    if (updated) {
      this.getEventMessages()
    }
  }
}
