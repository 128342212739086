import FormChapterType from '@/types/formChapter'

export default class SurveyForm {
  public id!:number
  public reference!:string
  public name?:string
  public type!:number
  public type_label!:string
  public object_type!:number
  public roomTypeIds!:number[]
  public constructionTypeIds!:number[]
  public description!:string
  public created_by_user!:number
  public revision!:string
  public chapters!:FormChapterType[]
  public last_revision_by_user!:number
  public reschedule_after_days!:number|null
  public active!:boolean
  public used!:boolean
  public is_visible_to_all!:boolean
  public created_at!:string
  public updated_at!:string

  constructor () {
    this.reference = ''
    this.name = ''
  }
}
