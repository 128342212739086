



















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Filelist from '@/components/media/Filelist.vue'
import Company from '@/types/company'

@Component({
  components: { Filelist }
})

export default class CompanyEditDocumentsTab extends Vue {
  settings = {
    routeNames: { list: 'companies', show: 'companyShow', edit: 'companyEdit' },
    routeParamKey: 'companyId',
    functionNames: { setCurrent: 'setCurrentCompany', save: 'saveCompany' },
    getterNames: { current: 'currentCompany', loading: 'loadingCompany', errors: 'projectErrors' }
  }

  directories:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean }[] = [
    {
      name: 'Facturen',
      position: 'facturen',
      allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
      maxFileSize: 16,
      allowFileRename: false
    }
  ]

  activePanel:string = new SecureLS({ isCompression: false }).get('companyEditDocumentsPanel') || null

  setActivePanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('companyEditDocumentsPanel', panel)
    this.activePanel = panel
  }

  reloadCompany () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get currentItem () : Company {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
