




































import { Vue, Component, Prop } from 'vue-property-decorator'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import TableSettings from '@/types/tableSettings'
import ServiceItem from '@/types/serviceItem'

function capitalize (str:string) : string {
  return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase()
  })
}

@Component({
  components: {}
})

export default class RelatedServiceItems extends Vue {
  @Prop({ required: true }) currentItem!:ServiceItem
  @Prop({ required: true }) buildingId!:number
  @Prop({ required: true }) entity!:string
  @Prop({ required: true }) entities!:string
  @Prop({ required: true, default: false }) rental!:boolean

  settings = {
    routeNames: { list: this.entities, show: this.entity + 'Show', edit: this.entity + 'Edit' },
    routeParamKey: this.entity + 'Id',
    permissionNames: { show: this.entities + '.view', edit: this.entities + '.edit' },
    functionNames: { getFilters: 'get' + capitalize(this.entity) + 'Filters', getList: 'get' + capitalize(this.entity) + 'List', setCurrent: 'setCurrent' + capitalize(this.entity), save: 'save' + capitalize(this.entity) },
    getterNames: { current: 'current' + capitalize(this.entity), loading: this.entity + 'Loading', errors: this.entity + 'Errors', list: this.entity + 'List', filters: this.entity + 'Filters' }
  }

  conditions = {
    status: []
  }

  tableSettings:TableSettings = new TableSettings(this.conditions)

  columns = [
    {
      title: 'Referentie',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' },
      width: 120
    },
    {
      title: 'Onderwerp',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: 'Actie',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  searchTerm = ''

  mounted (): void {
    this.fetchRelatedItems()
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.edit, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.show, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  fetchRelatedItems (): void {
    this.$store.dispatch('clearIssueList')
    this.tableSettings.activeFilters = { 'building.id': [this.buildingId] }
    this.$store.dispatch(this.settings.functionNames.getList, { settings: this.tableSettings })
  }

  get items () : ServiceItem[] {
    let results:ServiceItem[] = this.$store.getters[this.settings.getterNames.list].data
    if (this.searchTerm !== '') {
      results = this.filterByValue(results, this.searchTerm)
    }
    results = results.filter(s => s.id !== this.currentItem.id)
    return results
  }

  search (value:string): void {
    this.searchTerm = value
  }

  filterByValue (array: ServiceItem[], string: string): any[] {
    string = string.toLowerCase()
    return array.filter((o:any) => {
      const result = Object.keys(o).filter(() => {
        return o.title.toLowerCase().includes(string) || o.id.toString().includes(string)
      })

      if (result.length > 0) {
        return o
      }
    })
  }
}
