























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import BuildingConfig from '@/types/buildingConfig'
import moment from 'moment'
import BuildingConfigShowDetailsCard from '@/views/buildingConfig/cards/BuildingConfigShowDetailsCard.vue'
import BuildingConfigShowConfigurationCard from '@/views/buildingConfig/cards/BuildingConfigShowConfigurationCard.vue'

@Component({
  components: { BuildingConfigShowConfigurationCard, BuildingConfigShowDetailsCard }
})

export default class BuildingConfigShow extends Vue {
  settings = {
    routeNames: { list: 'buildingConfigs', show: 'buildingConfigShow', edit: 'buildingConfigEdit' },
    routeParamKey: 'buildingConfigId',
    functionNames: { setCurrent: 'setCurrentBuildingConfig', clearCurrent: 'clearCurrentBuildingConfig', save: 'saveBuildingConfig' },
    getterNames: { current: 'currentBuildingConfig', loading: 'loadingBuildingConfig' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('buildingConfigEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('customerEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : BuildingConfig {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
