



































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer from '@/types/customer'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class CustomerEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'customers', show: 'customerShow', edit: 'customerEdit' },
    routeParamKey: 'customerId',
    functionNames: { setCurrent: 'setCurrentCustomer', save: 'saveCustomer' },
    getterNames: { current: 'currentCustomer', loading: 'loadingCustomer', errors: 'customerErrors' }
  }

  columns:Column[] = [
    {
      title: 'Naam',
      key: 'full_name',
      dataIndex: 'full_name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'E-mailadres',
      key: 'email',
      dataIndex: 'email',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Telefoon',
      key: 'phone',
      dataIndex: 'phone',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Contractant',
      key: 'contractor',
      scopedSlots: { customRender: 'contractor' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  user = null
  showAddModal = false
  searchTimer = 0
  users:User[] = []

  showAddContactModal () : void {
    this.showAddModal = true
  }

  hideAddContactModal () : void {
    this.showAddModal = false
    this.user = null
  }

  getUserDetails (id:number) : void {
    axios.get(process.env.VUE_APP_API_URL + '/users/' + id).then((res) => {
      this.user = res.data.data
    })
  }

  addContact () : void {
    this.$store.dispatch('userAddedToCustomer', this.user)
    this.hideAddContactModal()
  }

  deleteContact (id:number) : void {
    this.$store.dispatch('userDeletedFromCustomer', id)
  }

  sendPassword (id:number) : void {
    // send password reset mail.
    axios.post(`${process.env.VUE_APP_API_URL}/password/reset/portal`, { user_id: id })
  }

  searchUser (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/customerUsers?filter[search]=' + val).then((res) => {
          this.users = res.data.data
        })
      }, 500)
    }
  }

  contractorsChanged (e:Event) : void {
    if (e.target !== null) {
      const target = e.target as HTMLTextAreaElement
      this.$store.dispatch('contractorsChanged', target.value)
    }
  }

  navigateTo (id:number, edit:boolean|null) : void {
    if (edit) {
      this.$router.push({ name: 'customerUserEdit', params: { customerUserId: id.toString() } })
    } else {
      this.$router.push({ name: 'customerUserShow', params: { customerUserId: id.toString() } })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('customers.users.edit')) {
            const route = this.$router.resolve({ name: 'customerUserEdit', params: { customerUserId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('customers.users.view')) {
            const route = this.$router.resolve({ name: 'customerUserShow', params: { customerUserId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Customer {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
