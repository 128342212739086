import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import OrderModel from '@/models/OrderModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'

Vue.use(Vuex)

export const orderStore: Module<any, any> = {
  state: {
    orderList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentOrder: {
      total: 0,
      status: null,
      status_text: '',
      phase: null,
      phase_text: '',
      project_id: null,
      project: {
        reference: '',
        name: ''
      },
      building_id: null,
      building: {
        reference: '',
        number: null,
        price: 0,
        received_orders: {
          modular: {
            received: false
          },
          carcass: {
            received: false
          },
          completion: {
            received: false
          },
          extra: {
            received: false
          }
        }
      },
      customer_id: null,
      customer: {
        reference: ''
      },
      files: {
        modular: null,
        carcass: null,
        completion: null,
        extra: null
      }
    },
    orderFilters: [],
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentOrder (state, { currentOrder }) {
      state.currentOrder = currentOrder
    },
    clearCurrentOrder (state) {
      state.currentOrder = {
        total: 0,
        status: null,
        status_text: '',
        phase: null,
        phase_text: '',
        project_id: null,
        project: {
          reference: '',
          name: ''
        },
        building_id: null,
        building: {
          reference: '',
          number: null,
          price: 0,
          received_orders: {
            modular: {
              received: false
            },
            carcass: {
              received: false
            },
            completion: {
              received: false
            },
            extra: {
              received: false
            }
          }
        },
        customer_id: null,
        customer: {
          reference: ''
        },
        files: {
          modular: null,
          carcass: null,
          completion: null,
          extra: null
        }
      }
    },
    setOrderList (state, { orderList }) {
      state.orderList = orderList
    },
    setOrderFilters (state, { filters }) {
      state.orderFilters = filters.data
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getOrderList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let orderModel = new OrderModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            orderModel = orderModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        orderModel = orderModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        orderModel = orderModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        orderModel = orderModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      orderModel.get().then((orderList) => {
        commit('setOrderList', { orderList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de kopers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentOrder ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        OrderModel.$find(id).then((currentOrder) => {
          commit('setCurrentOrder', { currentOrder })
          resolve(currentOrder)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze order!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    clearCurrentOrder ({ commit }) {
      commit('clearCurrentOrder')
    },
    getOrderFilters ({ commit }, data) {
      axios.post(process.env.VUE_APP_API_URL + '/orders/filters', data).then((filters) => {
        commit('setOrderFilters', { filters })
      })
    },
    saveOrder ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const orderModel = new OrderModel(data)

      // Save order.
      orderModel.save().then((currentOrder) => {
        commit('setErrors', {})
        commit('setCurrentOrder', { currentOrder })
        notification.success({ message: 'Order opgeslagen.', description: 'De order is succesvol opgeslagen.', duration: 3 })
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze order!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze order!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    orderList: state => state.orderList,
    currentOrder: state => state.currentOrder,
    loadingOrder: state => state.loading,
    orderErrors: state => state.errors
  }
}
