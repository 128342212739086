

















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import ProjectWebsite from '@/types/projectWebsite'
import axios from 'axios'
import Uploader from '@/components/media/Uploader.vue'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist, Uploader }
})

export default class ProjectWebsiteEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'projectWebsites', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    functionNames: { setCurrent: 'setCurrentProjectWebsite', clearCurrent: 'clearCurrentProjectWebsite', save: 'saveProjectWebsite' },
    getterNames: { current: 'currentProjectWebsite', loading: 'loadingProjectWebsite', errors: 'projectWebsiteErrors' }
  }

  toggleMaintenanceMode () : void {
    this.currentItem.maintenance_mode = !this.currentItem.maintenance_mode
  }

  newFileUploaded (file:File, key:string) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    axios.post(process.env.VUE_APP_API_URL + '/projectWebsites/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.reloadProjectSite()
    })
  }

  reloadProjectSite () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  showPreview () : void {
    window.open('https://' + this.currentItem.url + '?previewToken=' + this.currentItem.preview_token, '_blank')
  }

  get currentItem () : ProjectWebsite {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
