






import { Vue, Component } from 'vue-property-decorator'
import SurveyForm from '@/types/surveyForm'
import FormDetails from '@/components/forms/FormDetails.vue'

@Component({
  components: { FormDetails }
})

export default class SurveyFormCreate extends Vue {
  loading = false
  error = false

  mounted () : void {
    this.$store.dispatch('setCurrentForm', {
      id: null,
      name: '',
      reference: '',
      description: null,
      type: null,
      object_type: null,
      is_visible_to_all: false,
      chapters: [{
        id: null,
        name: null,
        number: 1,
        description: null,
        questions: [
          {
            id: null,
            name: null,
            number: 1,
            description: null,
            required: 0,
            show_ntb: 0,
            inspection_question: false,
            delivery_question: false,
            surveyQuestionGroupIds: []
          }
        ]
      }]
    })

    this.$store.dispatch('getQuestionGroups')
  }

  get form () : SurveyForm {
    return this.$store.getters.form
  }
}
