































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import CompanyUserEditDetailsTab from '@/views/companyUser/tabs/edit/CompanyUserEditDetailsTab.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import CompanyUserEditRolesTab from '@/views/companyUser/tabs/edit/CompanyUserEditRolesTab.vue'

@Component({
  components: { CompanyUserEditRolesTab, ActivityLogTable, CompanyUserEditDetailsTab }
})

export default class CompanyUserEdit extends Vue {
  settings = {
    routeNames: { list: 'companyUsers', show: 'companyUserShow', edit: 'companyUserEdit' },
    routeParamKey: 'companyUserId',
    permissionNames: { show: 'companies.users.view', edit: 'companies.users.edit', delete: 'companies.users.delete' },
    functionNames: { setCurrent: 'setCurrentCompanyUser', clearCurrent: 'clearCurrentCompanyUser', save: 'saveCompanyUser' },
    getterNames: { current: 'currentCompanyUser', loading: 'loadingCompanyUser' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('companyUserEditActiveTab') || 'details'

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('companyUserEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : User {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
