






























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Column from '@/types/column'
import axios from 'axios'
import Layout from '@/types/layout'
import { notification } from 'ant-design-vue'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class ProjectEditLayoutSettingsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Layout nr.',
      key: 'external_ref',
      scopedSlots: { customRender: 'external_ref' }
    },
    {
      title: 'Layout',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Standaard budget',
      key: 'layout_total_budget',
      scopedSlots: { customRender: 'layout_total_budget' }
    },
    {
      title: 'Project budget',
      key: 'project_layout_total_budget',
      scopedSlots: { customRender: 'project_layout_total_budget' }
    },
    {
      title: 'Standaard prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: 'Project prijs',
      key: 'project_layouts.price',
      scopedSlots: { customRender: 'project_price' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loading = false
  modalVisible = false
  projectLayouts = []
  currentLayout:{ [key: string]: string|number|null } = {
    project_id: null,
    layout_id: null,
    external_ref: null,
    name: null,
    base_price: null,
    base_purchase_price: null,
    project_price: null,
    project_purchase_price: null,
    project_text_sales: null,
    project_default: 0
  }

  mounted () : void {
    if (this.currentItem.id) {
      this.getProjectLayouts()
    }
  }

  getProjectLayouts () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/layouts').then((res) => {
      this.projectLayouts = res.data
    }).finally(() => {
      this.loading = false
    })
  }

  showModal (item:Layout) : void {
    this.modalVisible = true
    this.currentLayout = {
      project_id: this.currentItem.id,
      layout_id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      base_price: item.price,
      base_purchase_price: item.purchase_price,
      project_price: item.project_layouts && item.project_layouts.price !== null ? item.project_layouts.price : item.price,
      project_purchase_price: item.project_layouts && item.project_layouts.purchase_price !== null ? item.project_layouts.purchase_price : item.purchase_price,
      project_text_sales: item.project_layouts && item.project_layouts.text_sales !== null ? item.project_layouts.text_sales : item.text_sales,
      project_default: item.project_layouts && item.project_layouts.default !== null ? item.project_layouts.default : item.default
    }
  }

  hideModal () : void {
    this.modalVisible = false
    this.currentLayout = {
      project_id: null,
      layout_id: null,
      external_ref: null,
      name: null,
      base_price: null,
      base_purchase_price: null,
      project_price: null,
      project_purchase_price: null,
      project_text_sales: null,
      project_default: 0
    }
  }

  storeLayout () : void {
    this.loading = true
    axios.post(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/layouts', this.currentLayout).then((res) => {
      this.projectLayouts = res.data
      this.hideModal()
      notification.success({ message: 'Layout instellingen opgeslagen.', description: 'De layout instellingen zijn succesvol opgeslagen.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Layout instellingen NIET opgeslagen.', description: 'De layout instellingen zijn NIET opgeslagen.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  contentChanged (key:string, value:string) : void {
    this.currentLayout[key] = value
  }

  openBudgetTab () : void {
    this.$emit('changeTab', { tab: 'budgets', subTab: 'project_layout_budgets' })
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
