















import { Component, Vue, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'

@Component
export default class SaveButton extends Vue {
  @Prop({ default: 'Opslaan' }) saveText!: string
  @Prop({ default: 'Opslaan & Terug' }) saveAndReturnText!: string
  @Prop({ default: false }) loading!: boolean
  selectedOption = new SecureLS({ isCompression: false }).get('save-option') || 1

  setSelectedOption (option: number): void {
    this.selectedOption = option
    new SecureLS({ isCompression: false }).set('save-option', option)
    this.handleClick()
  }

  handleClick (): void {
    if (this.selectedOption === 1) {
      this.$emit('save')
    } else if (this.selectedOption === 2) {
      this.$emit('save-and-return')
    }
  }
}
