






































































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: {}
})

export default class BuildingEditFivePercentTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding', errors: 'projectErrors' }
  }

  generatingPdf = false
  managers: User[] = []

  mounted () : void {
    if (this.currentItem.five_percent_rule.active) {
      this.getManagers()
    }
  }

  fivePercentRuleChanged (active:boolean) : void {
    if (active) {
      this.getManagers()
    }

    this.$store.dispatch('fivePercentRuleChanged', active)
  }

  getManagers () : void {
    if (!this.currentItem.project_id) return

    axios.get(process.env.VUE_APP_API_URL + '/members/' + this.currentItem.project_id + '/managers').then(res => {
      this.managers = res.data
    })
  }

  generateRequest () : void {
    this.generatingPdf = true
    axios.post(process.env.VUE_APP_API_URL + '/fivePercentRules/' + this.currentItem.id + '/request').then(() => {
      notification.success({ message: 'Verzoek verstuurd.', description: 'Het verzoek tot vrijgave van de 5% is succesvol verstuurd.', duration: 3 })
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }).catch(() => {
      notification.error({ message: 'Verzoek NIET verstuurd.', description: 'Er is iets mis gegaan. Probeer het later nog eens.' })
    }).finally(() => {
      this.generatingPdf = false
    })
  }

  generateReminder () : void {
    this.generatingPdf = true
    axios.post(process.env.VUE_APP_API_URL + '/fivePercentRules/' + this.currentItem.id + '/reminder').then(() => {
      notification.success({ message: 'Herinnering verstuurd.', description: 'De herinnering is succesvol verstuurd.', duration: 3 })
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }).catch(() => {
      notification.error({ message: 'Herinnering NIET verstuurd.', description: 'Er is iets mis gegaan. Probeer het later nog eens.' })
    }).finally(() => {
      this.generatingPdf = false
    })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
