










































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import TextEditor from '@/components/base/TextEditor.vue'
import Subelement from '@/types/subelement'

@Component({
  components: { TextEditor }
})

export default class SubelementEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'subelements', show: 'subelementShow', edit: 'subelementEdit' },
    routeParamKey: 'subelementId',
    functionNames: { setCurrent: 'setCurrentSubelement', save: 'saveSubelement' },
    getterNames: { current: 'currentSubelement', loading: 'loadingSubelement', errors: 'subelementErrors' }
  }

  contentChanged (key:string, value:string) : void {
    this.currentItem[key] = value
  }

  get currentItem () : Subelement {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
