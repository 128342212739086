import Vue from 'vue'
import Vuex, { Module } from 'vuex'

Vue.use(Vuex)

export const mainComponentStatesStore: Module<any, any> = {
  state: {
    navigationCollapsed: false,
    activeQuestion: []
  },
  mutations: {
    toggleNavigation (state) {
      state.navigationCollapsed = !state.navigationCollapsed
    },
    setActiveQuestion (state, { activeQuestion }) {
      state.activeQuestion = activeQuestion
    }
  },
  actions: {
    toggleNavigation ({ commit }) {
      commit('toggleNavigation')
    },
    setActiveQuestion ({ commit }, activeQuestion) {
      commit('setActiveQuestion', { activeQuestion })
    }
  },
  getters: {
    navigationCollapsed: state => state.navigationCollapsed,
    activeQuestion: state => state.activeQuestion
  }
}
