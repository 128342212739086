






























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import imageCompression from 'browser-image-compression'
import axios from 'axios'
import SecureLS from 'secure-ls'
import { notification } from 'ant-design-vue'
import ServiceCreateCommentMediaItem from '@/views/service/comments/ServiceCreateCommentMediaItem.vue'
import ServiceComment from '@/types/serviceComment'
import ServiceItem from '@/types/serviceItem'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import TextEditor from '@/components/base/TextEditor.vue'
import { Base64MediaFile } from '@/types/media'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: {
    Filelist,
    PreviewContainer,
    SimpleUploader,
    ServiceCommentBlock,
    ServiceCreateCommentMediaItem,
    VueEditor,
    TextEditor
  }
})

export default class ServiceComments extends Vue {
  @Prop({ required: true }) serviceItem!:ServiceItem
  @Prop({ required: true }) serviceItemType!:string
  @Prop({ required: false, type: Boolean, default: false }) solverSelected!:boolean
  @Prop({ required: false }) mentions!: { id: number, active: boolean, full_name: string, role: string }[]
  @Prop({ required: false, default: false }) canDelete!: boolean
  @Prop({ required: false, type: Boolean, default: false }) isRental!: boolean

  comment: ServiceComment = new ServiceComment()
  comments = this.serviceItem.comments.reverse()
  reversed = true
  mentioned: number[] = []
  saving = false;

  get pinnedComments (): ServiceComment[] {
    return this.comments.filter(comment => comment.is_pinned)
  }

  get unPinnedComments (): ServiceComment[] {
    return [...this.comments]
  }

  get canSaveComment (): boolean {
    return this.comment.body !== ''
  }

  reverseCommentOrder (): void {
    this.reversed = !this.reversed
    this.unPinnedComments.reverse()
  }

  filterPinned (value: {id:number, pinned: boolean}): void {
    this.comments = this.comments.map((comment) => {
      return comment.id === value.id ? { ...comment, is_pinned: value.pinned } : comment
    })
  }

  gotoComment (id: number): void {
    const element = this.$el.querySelector('#comment-' + id)
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  beforeUpload (file: File) : boolean {
    if (!this.isValidType(file)) {
      this.$message.error('Je kan alleen .jpg,.png en .pdf afbeeldingen uploaden!')
      return false
    }

    if (file.type !== 'application/pdf') {
      this.compressImage(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1200,
        useWebWorker: true
      })
    }

    return false
  }

  isValidType (file: File): boolean {
    return file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf'
  }

  contentChanged (key: string, value: string): void {
    this.comment.body = value
  }

  setAwaitStatus (): void {
    this.comment.waitingForResponse = !this.comment.waitingForResponse
    if (this.comment.waitingForResponse) {
      this.comment.shared.client = true
    }
  }

  get showShareWithSolver (): boolean {
    return 'solver_id' in this.serviceItem
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  compressImage (file:File, options:{ maxSizeMB:number, maxWidthOrHeight:number, useWebWorker:boolean }) : void {
    imageCompression(file, options)
      .then((compressedFile) => {
        this.addToFileList(compressedFile)
      })
  }

  addToFileList (file:File) : void {
    this.previewFiles = [...[new Base64MediaFile(file)], ...this.previewFiles]
  }

  deleteMediaItem (index:number) : void {
    this.comment.media.splice(index, 1)
  }

  previewFiles:Base64MediaFile[] = []
  async handleMedia (file: File): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await this.getBase64Async(file)
    previewFile.name = file.name.replace(/\.[^/.]+$/, '')
    this.previewFiles = [...this.previewFiles, ...[previewFile]]
    this.comment.media = this.previewFiles
  }

  getBase64Async (file: File):Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  /**
   * Workaround for ant-design <a-select> setting value as key.
   * **/
  getMentionedUsers (): number[] {
    const mentionedIds: number[] = []
    this.mentioned.forEach((index:number) => {
      mentionedIds.push(this.mentions[index].id)
    })
    return mentionedIds
  }

  saveComment () : void {
    this.saving = true
    this.comment.status = this.serviceItem.status
    this.comment.source = 'admin-app'
    this.comment.user_id = new SecureLS({ isCompression: false }).get('user').id
    this.comment.mentions = this.getMentionedUsers()

    axios.post(process.env.VUE_APP_API_URL + '/comments/' + this.serviceItemType + '/' + this.serviceItem.id, this.comment)
      .then((res) => {
        this.comments = res.data
        if (this.reversed) {
          this.comments.reverse()
        }
        this.$emit('saved', this.comment)
        this.cleanup()
        notification.success({ message: 'Bericht opgeslagen.', description: 'Het bericht is succesvol opgeslagen bij deze melding.', duration: 5 })
      }).catch(() => {
        notification.error({ message: 'Bericht NIET opgeslagen!', description: 'Het bericht is NIET opgeslagen! Probeer het later nogmaals.', duration: 0 })
      }).finally(() => {
        this.saving = false
      })
  }

  cleanup () : void {
    this.comment = new ServiceComment()
    this.mentioned = []
    this.previewFiles = []
  }
}

