








































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer, { customerMediaFolders } from '@/types/customer'
import Filelist from '@/components/media/Filelist.vue'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'

@Component({
  components: {
    Uploader,
    Filelist
  }
})

export default class CustomerDocumentsTab extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean

  allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
  maxFileSize = 16
  allowFileRename = this.editable

  activePanel:string = new SecureLS({ isCompression: false }).get('customerDocumentsPanel') || null
  directories = customerMediaFolders

  newFileUploaded (file:File, key:string, important = false, concept = false) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    if (important) {
      formData.append('important', 'yes')
    }

    if (concept) {
      formData.append('concept', 'yes')
    }

    axios.post(process.env.VUE_APP_API_URL + '/customers/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        this.$store.dispatch('updateCustomer', res.data.data)
      })
  }

  refresh (): void {
    this.$store.dispatch('setCurrentCustomer', this.currentItem.id)
  }

  setActivePanel (panel:string) : void {
    new SecureLS({ isCompression: false }).set('customerDocumentsPanel', panel)
    this.activePanel = panel
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get currentItem () : Customer {
    return this.$store.getters.currentCustomer
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
