













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import FormChapterType from '@/types/formChapter'
import FormQuestion from '@/components/forms/FormQuestion.vue'
import SurveyForm from '@/types/surveyForm'
import { VueEditor } from 'vue2-editor'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: { FormQuestion, VueEditor }
})

export default class FormChapter extends Vue {
  @Prop() form!:SurveyForm
  @Prop() chapter!:FormChapterType
  @Prop() chapterIndex!:number

  @Watch('chapter')
  onChapterUpdate () : void {
    this.currentChapter = this.chapter
  }

  currentChapter:FormChapterType = new FormChapterType()

  mounted () : void {
    this.currentChapter = this.chapter
  }

  addChapter () : void {
    this.$store.dispatch('addChapter')
  }

  updateChapter () : void {
    this.$store.dispatch('updateChapter', {
      chapterIndex: this.chapterIndex,
      chapter: this.currentChapter
    })
  }

  removeChapter () : void {
    this.$store.dispatch('removeChapter', {
      chapterIndex: this.chapterIndex
    })
  }

  addQuestion () : void {
    this.$store.dispatch('addQuestion', {
      chapterIndex: this.chapterIndex
    })
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
