








































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Column from '@/types/column'
import axios from 'axios'
import Issue from '@/types/issue'
import Objection from '@/types/objection'
import Question from '@/types/question'

@Component({
  components: {}
})

export default class ProjectEditServiceTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Titel',
      key: 'title',
      dataIndex: 'title',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Adres',
      key: 'address',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      scopedSlots: { customRender: 'address' }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
      filters: [
        { text: 'Nieuw', value: 0 },
        { text: 'In behandeling', value: 1 },
        { text: 'Opgelost', value: 2 },
        { text: 'Gesloten', value: 4 },
        { text: 'Akkoord', value: 3 },
        { text: 'Niet akkoord', value: 5 },
        { text: 'Heropend', value: 6 },
        { text: 'Gereed', value: 7 },
        { text: 'Afgewezen', value: 8 },
        { text: 'Niet mee eens', value: 9 },
        { text: 'In afwachting', value: 10 },
        { text: 'Doorgestuurd', value: 12 },
        { text: 'Ingepland', value: 11 },
        { text: 'Geschil', value: 13 }
      ],
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      width: 160
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 80
    }
  ]

  loading = {
    issues: false,
    objections: false,
    questions: false
  }

  activeTab = 'issues'

  issues:Issue[] = []
  objections:Objection[] = []
  questions:Question[] = []

  pagination = {
    total: 0,
    current: 1
  }

  search = {
    query: '',
    timer: 0,
    timeout: 800
  }

  mounted (): void {
    this.getData()
  }

  getData (): void {
    this.getIssues(this.search.query)
    this.getObjections(this.search.query)
    this.getQuestions(this.search.query)
  }

  setActiveTab (tab: string): void {
    this.activeTab = tab
    switch (tab) {
      case 'objections':
        this.pagination.total = this.objections.length
        break
      case 'questions':
        this.pagination.total = this.questions.length
        break
      default:
        this.pagination.total = this.issues.length
    }
  }

  get paginationData (): any {
    return {
      total: this.pagination.total,
      current: this.pagination.current,
      pageSize: 10
    }
  }

  getIssues (query = '') : void {
    this.loading.issues = true

    const search = query === '' ? '' : '&filter[search]=' + query
    axios.get(process.env.VUE_APP_API_URL + '/issues?filter[project.id]=' + this.currentItem.id + search + '&sort=-id').then((res) => {
      this.issues = res.data.data
      this.pagination.total = this.issues.length
    }).finally(() => {
      this.loading.issues = false
    })
  }

  getObjections (query = '') : void {
    this.loading.objections = true

    const search = query === '' ? '' : '&filter[search]=' + query
    axios.get(process.env.VUE_APP_API_URL + '/objections?filter[project.id]=' + this.currentItem.id + search + '&sort=-id').then((res) => {
      this.objections = res.data.data
      this.pagination.total = this.objections.length
    }).finally(() => {
      this.loading.objections = false
    })
  }

  getQuestions (query = '') : void {
    this.loading.questions = true

    const search = query === '' ? '' : '&filter[search]=' + query
    axios.get(process.env.VUE_APP_API_URL + '/questions?filter[project.id]=' + this.currentItem.id + search + '&sort=-id').then((res) => {
      this.questions = res.data.data
      this.pagination.total = this.questions.length
    }).finally(() => {
      this.loading.questions = false
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    const keyboard = window.event as KeyboardEvent
    if (id) {
      const route = this.$router.resolve({ name: routeName, params: { [paramKey]: id.toString() } })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    } else {
      const route = this.$router.resolve({ name: routeName })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    }
  }

  onIssueRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('issues.edit')) {
            const route = this.$router.resolve({ name: 'issueEdit', params: { issueId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  onObjectionRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('objections.edit')) {
            const route = this.$router.resolve({ name: 'objectionEdit', params: { objectionId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  onQuestionRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('questions.edit')) {
            const route = this.$router.resolve({ name: 'questionEdit', params: { questionId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    this.pagination.current = pagination.current
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }
  }

  handleSearch () : void {
    if (this.search.timer) {
      clearTimeout(this.search.timer)
      this.search.timer = 0
    }

    this.search.timer = setTimeout(() => {
      this.pagination.current = 1
      this.getData()
    }, this.search.timeout)
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
