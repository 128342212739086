






























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import Room from '@/types/room'

@Component({
  components: {}
})

export default class BuildingEditRoomsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Verdieping',
      key: 'level',
      dataIndex: 'level_name'
    },
    {
      title: 'Positie',
      key: 'position',
      dataIndex: 'position'
    },
    {
      title: 'Ruimte',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      key: 'room_type',
      dataIndex: 'room_type.name'
    },
    {
      title: 'Oppervlakte',
      key: 'square_meter',
      dataIndex: 'square_meter',
      scopedSlots: { customRender: 'square_meter' }
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  showRoomModal = false
  roomTypes:{ id:number, name:string, bim_name:string }[] = []
  room:{
    id: number|null,
    building_id: number|null,
    name: string|null,
    room_type_id: number|null,
    level: number,
    position: number|null,
    square_meter: number|null,
    perimeter: number|null
  } = {
    id: null,
    building_id: null,
    name: null,
    room_type_id: null,
    level: 0,
    position: null,
    square_meter: null,
    perimeter: null
  }

  mounted () : void {
    this.getRoomTypes()
  }

  getRoomTypes () : void {
    axios.get(process.env.VUE_APP_API_URL + '/roomTypes').then((res) => {
      this.roomTypes = res.data
    })
  }

  toggleRoomModal (room:Room|null) : void {
    this.showRoomModal = !this.showRoomModal
    if (room === null) {
      this.room = {
        id: null,
        building_id: this.currentItem.id,
        name: null,
        room_type_id: null,
        level: 0,
        position: null,
        square_meter: null,
        perimeter: null
      }
    } else {
      this.room = room
    }
  }

  storeRoom () : void {
    if (this.room.id) {
      axios.patch(process.env.VUE_APP_API_URL + '/rooms/' + this.room.id, this.room).then(() => {
        this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
        notification.success({
          message: 'Ruimte bijgewerkt.',
          description: 'De ruimte is succesvol bijgewerkt.',
          duration: 3
        })
      }).catch(() => {
        notification.error({
          message: 'Ruimte NIET bijgewerkt.',
          description: 'De ruimte is NIET bijgewerkt. Probeer het later nog eens.'
        })
      })
    } else {
      axios.post(process.env.VUE_APP_API_URL + '/rooms', this.room).then(() => {
        this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
        notification.success({
          message: 'Ruimte toegevoegd.',
          description: 'De ruimte is succesvol toegevoegd.',
          duration: 3
        })
      }).catch(() => {
        notification.error({
          message: 'Ruimte NIET toegevoegd.',
          description: 'De ruimte is NIET toegevoegd. Probeer het later nog eens.'
        })
      })
    }
  }

  deleteRoom (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/rooms/' + id).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
