import Vue from 'vue'
import Vuex from 'vuex'

// General stores.
import { loginStore } from '@/store/loginStore'
import { mainComponentStatesStore } from '@/store/mainComponentStatesStore'

// Entity stores.
import { userStore } from '@/store/userStore'
import { roleStore } from '@/store/roleStore'
import { entityStore } from './entityStore'
import { companyStore } from '@/store/companyStore'
import { projectStore } from '@/store/projectStore'
import { projectWebsiteStore } from '@/store/projectWebsiteStore'
import { buildingStore } from '@/store/buildingStore'
import { customerStore } from '@/store/customerStore'
import { customerUserStore } from '@/store/customerUserStore.ts'
import { tenantStore } from '@/store/tenantStore'
import { tenantUserStore } from '@/store/tenantUserStore.ts'
import { layoutStore } from '@/store/layoutStore'
import { elementStore } from '@/store/elementStore'
import { subelementStore } from '@/store/subelementStore'
import { optionStore } from '@/store/optionStore'
import { buildingConfigStore } from '@/store/buildingConfigStore'
import { orderStore } from '@/store/orderStore'
import { oldWoononOrderStore } from '@/store/oldWoonon/oldWoononOrderStore'
import { objectionStore } from '@/store/objectionStore'
import { questionStore } from '@/store/questionStore'
import { issueStore } from '@/store/issueStore'
import { feedbackStore } from '@/store/feedbackStore'
import { messageStore } from '@/store/messageStore'
import { surveyStore } from '@/store/surveyStore'
import { deliveryStore } from '@/store/deliveryStore'
import { companyUserStore } from '@/store/companyUserStore.ts'
import { newsItemStore } from '@/store/newsStore'
import { constructionWorkerStore } from '@/store/constructionWorkerStore'
import { releaseStore } from '@/store/releaseStore'
import { guardCodeStore } from '@/store/guardCodeStore'
import { surveyFormStore } from '@/store/surveyFormStore'
import { demoConfigStore } from '@/store/demoConfigStore'
import { fivePercentRuleStore } from '@/store/fivePercentRuleStore'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    buildingConfigStore,
    buildingStore,
    companyStore,
    companyUserStore,
    customerStore,
    customerUserStore,
    demoConfigStore,
    tenantStore,
    tenantUserStore,
    elementStore,
    entityStore,
    feedbackStore,
    messageStore,
    issueStore,
    layoutStore,
    loginStore,
    mainComponentStatesStore,
    newsItemStore,
    objectionStore,
    optionStore,
    orderStore,
    oldWoononOrderStore,
    projectStore,
    projectWebsiteStore,
    questionStore,
    roleStore,
    subelementStore,
    surveyStore,
    deliveryStore,
    surveyFormStore,
    userStore,
    constructionWorkerStore,
    releaseStore,
    guardCodeStore,
    fivePercentRuleStore
  }
})
