

















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import Customer from '@/types/customer'
import Building from '@/types/building'

@Component({
  components: {}
})

export default class CustomerEditConfigurationsTab extends Vue {
  settings = {
    routeNames: { list: 'customers', show: 'customerShow', edit: 'customerEdit' },
    routeParamKey: 'customerId',
    functionNames: { setCurrent: 'setCurrentCustomer', save: 'saveCustomer' },
    getterNames: { current: 'currentCustomer', loading: 'loadingCustomer' }
  }

  columns:Column[] = [
    {
      title: 'Project',
      key: 'project.name',
      dataIndex: 'project.name'
    },
    {
      title: 'Bouwnummer',
      key: 'building.number',
      dataIndex: 'building.number'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 140
    }
  ]

  loading = false
  buildingConfigs = []
  demoConfigs = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getDemoConfigs()

      if (this.boughtBuildingIds.length) {
        this.getBuildingConfigs()
      }
    }
  }

  getBuildingConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildingConfigs?filter[building_id]=' + this.boughtBuildingIds.join(',')).then((res) => {
      this.buildingConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  getDemoConfigs () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/demoConfigs?filter[customer_id]=' + this.currentItem.id).then((res) => {
      this.demoConfigs = res.data.data
    }).finally(() => {
      this.loading = false
    })
  }

  navigateTo (routeName:string, paramKey:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  get currentItem () : Customer {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get boughtBuildingIds () : number[] {
    const boughtBuildingIds:number[] = []
    this.currentItem.buildings.forEach((b:Building) => {
      boughtBuildingIds.push(b.id)
    })
    return boughtBuildingIds
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
