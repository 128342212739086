











import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import axios from 'axios'

@Component({
  components: {}
})

export default class BuildingEditReportsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  loading = false

  downloadBuildingSpecification () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/pdf/specification', { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Meterkastlijst_P' + this.currentItem.project?.reference + '_B' + this.currentItem.number + '.pdf'
        link.click()
      }).finally(() => {
        this.loading = false
      })
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
