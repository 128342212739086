import Model from './Model'
import User from '@/types/user'

export default class CompanyModel extends Model {
  public id: number|undefined
  public users!: User[]
  resource () : string {
    return 'companies'
  }
}
