












import { Vue, Component, Prop } from 'vue-property-decorator'
import SurveyForm from '@/types/surveyForm'

@Component({
  components: {}
})

export default class FormFooter extends Vue {
  @Prop() form!:SurveyForm

  saveForm () : void {
    if (this.$route.name === 'surveyFormCreate') {
      this.$store.dispatch('addForm', this.form)
    } else {
      this.$store.dispatch('saveForm', this.form)
    }
  }
}
