import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import UserModel from '@/models/UserModel'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import router from '@/router'

Vue.use(Vuex)

export const userStore: Module<any, any> = {
  state: {
    userList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentUser: {
      firstname: null,
      lastname: null,
      full_name: null,
      username: null,
      password: null,
      password_confirmation: null,
      company_id: null,
      role_ids: [],
      permission_ids: []
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentUser (state, { currentUser }) {
      currentUser.password = null
      currentUser.password_confirmation = null
      state.currentUser = currentUser
    },
    clearCurrentUser (state) {
      state.currentUser = {
        firstname: null,
        lastname: null,
        full_name: null,
        username: null,
        password: null,
        password_confirmation: null,
        company_id: null,
        role_ids: [],
        permission_ids: []
      }
    },
    setUserList (state, { userList }) {
      state.userList = userList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getUserList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let userModel = new UserModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            userModel = userModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        userModel = userModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        userModel = userModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        userModel = userModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      userModel.get().then((userList) => {
        commit('setUserList', { userList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van gebruikers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentUser ({ commit }, id) {
      commit('setErrors', {})
      commit('setLoading', { type: 'item', state: true })
      UserModel.$find(id).then((currentUser) => {
        commit('setCurrentUser', { currentUser })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze gebruiker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentUser ({ commit }) {
      commit('clearCurrentUser')
    },
    saveUser ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const userModel = new UserModel(data)

      // Save user.
      userModel.save().then((currentUser) => {
        commit('setErrors', {})
        commit('setCurrentUser', { currentUser })
        if (data.id) {
          notification.success({ message: 'Gebruiker opgeslagen.', description: 'De gebruiker is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Gebruiker toegevoegd.', description: 'De gebruiker is succesvol toegevoegd.', duration: 3 })
          if (currentUser.id) {
            router.push({ name: 'userEdit', params: { userId: currentUser.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze gebruiker!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze gebruiker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    userList: state => state.userList,
    currentUser: state => state.currentUser,
    loadingUser: state => state.loading,
    userErrors: state => state.errors
  }
}
