
















































import { Vue, Component, Prop } from 'vue-property-decorator'
import ServiceCommentMediaItem from '@/views/service/comments/ServiceCommentMediaItem.vue'
import ServiceComment from '@/types/serviceComment'
import moment from 'moment/moment'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import axios from 'axios'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist, PreviewContainer, ServiceCommentMediaItem }
})

export default class ServiceCommentBlock extends Vue {
  @Prop({ required: true }) comment!:ServiceComment
  @Prop({ required: false, default: false, type: Boolean }) pinned?: boolean
  @Prop({ required: false, default: false, type: Boolean }) canDelete!: boolean

  moment = moment
  deleted = false // Used t

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get pinTheme (): string {
    if (this.comment === undefined) return 'outlined'
    return this.comment.is_pinned ? 'filled' : 'outlined'
  }

  togglePinned (): void {
    if (this.comment === undefined) return
    axios.get(`${process.env.VUE_APP_API_URL}/comments/${this.comment.id}/pin`)
    this.$emit('pinned', { id: this.comment.id, pinned: !this.comment.is_pinned })
  }

  deleteComment (): void {
    axios.delete(`${process.env.VUE_APP_API_URL}/comments/${this.comment.id}`)
      .then(() => {
        this.deleted = true
        this.$message.success('Bericht verwijderd')
      }).catch(() => {
        this.$message.error('Er is iets misgegaan bij het verwijderen van het bericht')
      })
  }

  JumpToComment (): void {
    if (!this.pinned) return
    this.$emit('goto', this.comment?.id)
  }
}
