





























































































































import { Vue, Component } from 'vue-property-decorator'
import { Diary, DiaryRegistration } from '@/types/diary'
import axios from 'axios'
import User from '@/types/user'
import SecureLS from 'secure-ls'
import { notification } from 'ant-design-vue'
import Filelist from '@/components/media/Filelist.vue'

@Component({
  components: { Filelist }
})

export default class DiaryShow extends Vue {
  loading = false
  diary:Diary|null = null

  diaryColumns = [
    {
      title: 'Dag',
      key: 'day',
      scopedSlots: { customRender: 'day' },
      width: 110
    },
    {
      title: 'Werkbaar',
      key: 'workable',
      scopedSlots: { customRender: 'workable' },
      width: 150
    },
    {
      title: 'Omschrijving van de dag',
      key: 'description',
      scopedSlots: { customRender: 'description' }
    }
  ]

  registrationColumns = [
    {
      title: 'Bedrijf',
      key: 'company',
      scopedSlots: { customRender: 'company' },
      width: 200
    },
    {
      title: 'Maandag',
      key: 'monday',
      scopedSlots: { customRender: 'monday' }
    },
    {
      title: 'Dinsdag',
      key: 'tuesday',
      scopedSlots: { customRender: 'tuesday' }
    },
    {
      title: 'Woensdag',
      key: 'wednesday',
      scopedSlots: { customRender: 'wednesday' }
    },
    {
      title: 'Donderdag',
      key: 'thursday',
      scopedSlots: { customRender: 'thursday' }
    },
    {
      title: 'Vrijdag',
      key: 'friday',
      scopedSlots: { customRender: 'friday' }
    },
    {
      title: 'Zaterdag',
      key: 'saturday',
      scopedSlots: { customRender: 'saturday' }
    },
    {
      title: 'Zondag',
      key: 'sunday',
      scopedSlots: { customRender: 'sunday' }
    },
    {
      title: 'Actie\'s',
      key: 'signaling',
      scopedSlots: { customRender: 'signaling' }
    }
  ]

  showSignalingModal = false
  signaling:{ text: string|null, amount: number|null, total: number|null } = {
    text: null,
    amount: null,
    total: null
  }

  mounted () : void {
    this.getDiary()
  }

  getDiary () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/diaries/' + this.$route.params.diaryId).then((r) => {
      this.diary = r.data.data
      this.pushTotals()
    }).catch(() => {
      notification.error({ message: 'Fout tijdens het ophalen van het uitvoerdersdagboek!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 0 })
    }).finally(() => {
      this.loading = false
    })
  }

  toggleSignalingModal (registration:DiaryRegistration|null = null) : void {
    if (registration) {
      this.signaling.text = registration.text_signaling
      this.signaling.amount = registration.amount_signaling
      this.signaling.total = registration.total_amount_signaling
      this.showSignalingModal = true
    } else {
      this.signaling = {
        text: null,
        amount: null,
        total: null
      }
      this.showSignalingModal = false
    }
  }

  pushTotals () : void {
    const counter = { id: 0, diary_id: 0, company: { id: 0, name: 'Totaal' }, monday: 0, tuesday: 0, wednesday: 0, thursday: 0, friday: 0, saturday: 0, sunday: 0, text_signaling: null, amount_signaling: null, total_amount_signaling: 0 }

    if (!this.diary) return

    this.diary.registrations.forEach((r:DiaryRegistration) => {
      counter.monday += r.monday
      counter.tuesday += r.tuesday
      counter.wednesday += r.wednesday
      counter.thursday += r.thursday
      counter.friday += r.friday
      counter.saturday += r.saturday
      counter.sunday += r.sunday
    })

    this.diary.registrations.push(counter)
  }

  openReport (url:string) : void {
    window.open(url, '_blank')
  }

  previousPage () : void {
    this.$router.push({ name: this.authUser.permissions.includes('projects.edit') ? 'projectEdit' : 'projectShow', params: { projectId: this.$route.params.projectId } })
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
