









































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer from '@/types/customer'
import Building from '@/types/building'
import Project from '@/types/project'
import ServiceComments from '@/views/service/comments/ServiceComments.vue'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import ServiceComment from '@/types/serviceComment'
import RelatedServiceItems from '@/views/service/RelatedServiceItems.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import Filelist from '@/components/media/Filelist.vue'
import ServiceObjectInfo from '@/views/service/ServiceObjectInfo.vue'
import ServiceBuildingSelector from '@/views/service/ServiceBuildingSelector.vue'
import storeSettings from '@/types/storeSettings'
import SimpleUploader from '@/components/media/SimpleUploader.vue'
import SimpleFileList from '@/components/media/SimpleFileList.vue'
import { Base64MediaFile } from '@/types/media'
import Question from '@/types/question'
import ServiceDetails from '@/views/service/ServiceDetails.vue'
import ServiceContactInfo from '@/views/service/ServiceContactInfo.vue'
import BuildingModel from '@/models/BuildingModel'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import PrioritySelector from '@/views/service/components/PrioritySelector.vue'

@Component({
  components: {
    PrioritySelector,
    PreviewContainer,
    ServiceContactInfo,
    ServiceDetails,
    SimpleFileList,
    SimpleUploader,
    ServiceBuildingSelector,
    ServiceObjectInfo,
    Filelist,
    ActivityLogTable,
    RelatedServiceItems,
    ServiceCommentBlock,
    ServiceComments,
    ServiceCreateComment: ServiceComments
  }
})

export default class QuestionCreate extends Vue {
  ls = new SecureLS({ isCompression: false })
  config = {}

  settings: storeSettings = {
    routeNames: { list: 'questions', show: 'questionShow', edit: 'questionEdit' },
    routeParamKey: 'questionId',
    permissionNames: { show: 'questions.view', edit: 'questions.edit' },
    functionNames: { getFilters: 'getQuestionFilters', getList: 'getQuestionList', setCurrent: 'setCurrentQuestion', clearCurrent: 'clearCurrentQuestion', save: 'saveQuestion' },
    getterNames: { current: 'currentQuestion', loading: 'questionLoading', errors: 'questionErrors', list: 'list', filters: 'questionFilters' }
  }

  tenantInfoEnabled = false
  selectedStatus = 0

  allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf']
  maxFileSize = 16

  previewFiles:Base64MediaFile[] = []

  mounted ():void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  get customer () : Customer {
    return this.currentItem.building?.customer || new Customer()
  }

  get building () : Building {
    return this.currentItem.building || new Building()
  }

  get project () : Project {
    return this.currentItem.project || new Project()
  }

  get comments () : ServiceComment[] {
    return this.currentItem.comments || [new ServiceComment()]
  }

  isRental (state: number): boolean {
    const rentalStates:number[] = [5, 6, 7]
    return rentalStates.includes(state)
  }

  onBuildingSelected (id: number): void {
    this.$store.dispatch('setQuestionBuildingId', id)

    BuildingModel.where('id', id).first().then(building => {
      this.$store.dispatch('setQuestionBuilding', building)
    }).finally(() => {
      this.tenantInfoEnabled = this.isRental(this.currentItem.building.state)
    })
  }

  onProjectSelected (id: number): void {
    this.$store.dispatch('setQuestionProject', id)
  }

  onManagerSelected (id: number): void {
    this.$store.dispatch('setQuestionManager', id)
  }

  save () : void {
    this.currentItem.user_id = this.authUser.id
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Question {
    const current = this.$store.getters[this.settings.getterNames.current]
    this.selectedStatus = current.status.id
    return current
  }

  async handleMedia (file: File): Promise<void> {
    const previewFile: Base64MediaFile = new Base64MediaFile(file)
    previewFile.url = await this.getBase64Async(file)
    previewFile.name = file.name.replace(/\.[^/.]+$/, '')
    this.previewFiles = [...[previewFile], ...this.previewFiles]
    await this.$store.dispatch('setQuestionFiles', this.previewFiles)
  }

  deleteFile (target: { index: number, file: File}) : void {
    this.previewFiles.splice(target.index, 1)
  }

  getBase64Async (file: File):Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  get authUser () : User {
    return this.ls.get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
