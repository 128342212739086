


























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import FormQuestionType from '@/types/formQuestion'
import SurveyForm from '@/types/surveyForm'
import { VueEditor } from 'vue2-editor'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: { VueEditor }
})

export default class FormQuestion extends Vue {
  @Prop() form!:SurveyForm
  @Prop() chapterIndex!:number
  @Prop() question!:FormQuestionType
  @Prop() questionIndex!:number

  @Watch('question')
  onQuestionUpdate () : void {
    this.currentQuestion = this.question
    this.currentQuestion.category = this.question.category || 0
  }

  currentQuestion:FormQuestionType = new FormQuestionType()

  mounted () : void {
    this.currentQuestion = this.question
    this.currentQuestion.category = this.question.category || 0
  }

  updateQuestion () : void {
    this.$store.dispatch('updateQuestion', {
      chapterIndex: this.chapterIndex,
      questionIndex: this.questionIndex,
      question: this.currentQuestion
    })
  }

  removeQuestion () : void {
    this.$store.dispatch('removeQuestion', {
      chapterIndex: this.chapterIndex,
      questionIndex: this.questionIndex
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get questionGroups () : { id:number, name:string }[] {
    return this.$store.getters.questionGroups
  }

  get questionCategories () : { id:number, name:string }[] {
    return this.$store.getters.questionCategories
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
