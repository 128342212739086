
















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Delivery from '@/types/delivery'
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

@Component({
  components: {}
})

export default class DeliveryShowGeneralChecksCard extends Vue {
  settings = {
    routeNames: { list: 'deliveries', show: 'deliveryShow', edit: 'deliveryEdit' },
    routeParamKey: 'deliveryId',
    functionNames: { setCurrent: 'setCurrentDelivery', save: 'saveDelivery' },
    getterNames: { current: 'currentDelivery', loading: 'loadingDelivery', errors: 'deliveryErrors' }
  }

  get currentItem () : Delivery {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
