import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import TenantModel from '@/models/TenantModel'
import { notification } from 'ant-design-vue'
import axios from 'axios'
import router from '@/router'
import User from '@/types/user'
import Tenant from '@/types/tenant'

Vue.use(Vuex)

export const tenantStore: Module<any, any> = {
  state: {
    tenantList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentTenant: new Tenant(),
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentTenant (state, { currentTenant }) {
      state.currentTenant = new Tenant(currentTenant)
    },
    clearCurrentTenant (state) {
      state.currentTenant = new Tenant()
    },
    setTenantList (state, { tenantList }) {
      state.tenantList = tenantList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    userAddedToTenant (state, { user }) {
      if (!state.currentTenant.user_ids.includes(user.id)) {
        state.currentTenant.user_ids.push(user.id)
        state.currentTenant.users.push(user)
      }
    },
    userDeletedFromTenant (state, { id }) {
      state.currentTenant.user_ids.splice(state.currentTenant.user_ids.indexOf(id), 1)

      let userIndex = null
      state.currentTenant.users.forEach((user:User, index:number) => {
        if (user.id === id) {
          userIndex = index
        }
      })

      if (userIndex !== null) {
        state.currentTenant.users.splice(userIndex, 1)
      }
    },
    copyUserDetailsToTenant (state, { index }) {
      state.currentTenant.name = state.currentTenant.users[index].full_name
      state.currentTenant.reference = state.currentTenant.users[index].full_name
      state.currentTenant.address = state.currentTenant.users[index].address
      state.currentTenant.zipcode = state.currentTenant.users[index].zipcode
      state.currentTenant.city = state.currentTenant.users[index].city
      state.currentTenant.phone = state.currentTenant.users[index].phone
      state.currentTenant.email = state.currentTenant.users[index].email
      notification.success({ message: 'Gegevens gekopieerd.', description: 'De gegevens zijn gekopieerd. Vergeet de huurder niet op te slaan.', duration: 3 })
    }
  },
  actions: {
    getTenantList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let tenantModel = new TenantModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            tenantModel = tenantModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        tenantModel = tenantModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        tenantModel = tenantModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        tenantModel = tenantModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      tenantModel.get().then((tenantList) => {
        commit('setTenantList', { tenantList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van de huurders!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentTenant ({ commit }, id) {
      commit('setErrors', {})
      return new Promise((resolve, reject) => {
        commit('setLoading', { type: 'item', state: true })
        TenantModel.$find(id).then((currentTenant) => {
          commit('setCurrentTenant', { currentTenant })
          resolve(currentTenant)
        }).catch((e) => {
          notification.error({
            message: 'Fout tijdens het ophalen van deze huurder!',
            description: 'Er is iets mis gegaan. Probeer het later nog een keer.',
            duration: 3
          })
          reject(e)
        }).finally(() => {
          commit('setLoading', { type: 'item', state: false })
        })
      })
    },
    updateTenant ({ commit }, currentTenant) {
      commit('setCurrentTenant', { currentTenant })
    },
    clearCurrentTenant ({ commit }) {
      commit('clearCurrentTenant')
    },
    saveTenant ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const tenantModel = new TenantModel(data)

      // Save tenant.
      tenantModel.save().then((currentTenant) => {
        commit('setErrors', {})
        commit('setCurrentTenant', { currentTenant })
        if (data.id) {
          notification.success({ message: 'Huurder opgeslagen.', description: 'De huurder is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Huurder toegevoegd.', description: 'De huurder is succesvol toegevoegd.', duration: 3 })
          if (currentTenant.id) {
            router.push({ name: 'tenantEdit', params: { tenantId: currentTenant.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze huurder!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze huurder!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    },
    userAddedToTenant ({ commit }, user) {
      commit('userAddedToTenant', { user })
    },
    userDeletedFromTenant ({ commit }, id) {
      commit('userDeletedFromTenant', { id })
    },
    copyUserDetailsToTenant ({ commit }, index) {
      commit('copyUserDetailsToTenant', { index })
    }
  },
  getters: {
    tenantList: state => state.tenantList,
    currentTenant: state => state.currentTenant,
    loadingTenant: state => state.loading,
    tenantErrors: state => state.errors
  }
}
