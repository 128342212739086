




















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import OldWoononOrder from '@/types/oldWoonon/oldWoononOrder'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class OldWoononOrderShowItemsCard extends Vue {
  settings = {
    routeNames: { list: 'oldWoononOrders', show: 'oldWoononOrderShow', edit: 'oldWoononOrderEdit' },
    routeParamKey: 'oldWoononOrderId',
    functionNames: { setCurrent: 'setCurrentOldWoononOrder', save: 'saveOldWoononOrder' },
    getterNames: { current: 'currentOldWoononOrder', loading: 'loadingOldWoononOrder' }
  }

  columns:Column[] = [
    {
      title: 'Optie',
      key: 'option',
      dataIndex: 'option',
      sorter: false,
      scopedSlots: { customRender: 'option' }
    },
    {
      title: 'Prijs',
      key: 'option.price',
      dataIndex: 'option.price',
      sorter: false,
      scopedSlots: { customRender: 'option.price' }
    }
  ]

  columnsCompletion:Column[] = [
    {
      title: 'Verdieping',
      key: 'level',
      dataIndex: 'room',
      sorter: false,
      scopedSlots: { customRender: 'level' }
    },
    {
      title: 'Ruimte',
      key: 'room',
      dataIndex: 'room',
      sorter: false,
      scopedSlots: { customRender: 'room' }
    },
    {
      title: 'Element',
      key: 'element',
      dataIndex: 'element',
      sorter: false,
      scopedSlots: { customRender: 'element' }
    },
    {
      title: 'Subelement',
      key: 'subelement',
      dataIndex: 'subelement',
      sorter: false,
      scopedSlots: { customRender: 'subelement' }
    },
    {
      title: 'Optie',
      key: 'option',
      dataIndex: 'option',
      sorter: false,
      scopedSlots: { customRender: 'option' }
    },
    {
      title: 'Prijs',
      key: 'option.price',
      dataIndex: 'option.price',
      sorter: false,
      scopedSlots: { customRender: 'option.price' }
    }
  ]

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : OldWoononOrder {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
