






















import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import MediaItem from '@/types/mediaItem'
import axios from 'axios'

@Component({
  components: {}
})

export default class UploadQueue extends Vue {
  @Prop() mediaItems!:File[]

  deleteMediaItem (index:number) : void {
    this.$emit('fileDeleted', index)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }
}
