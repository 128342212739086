



































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import Building, { productTypes } from '@/types/building'
import Column from '@/types/column'
import ProjectShowDetailsTab from '@/views/project/tabs/show/ProjectShowDetailsTab.vue'
import ProjectShowBlocksTab from '@/views/project/tabs/show/ProjectShowBlocksTab.vue'
import ProjectShowLayoutSettingsTab from '@/views/project/tabs/show/ProjectShowLayoutSettingsTab.vue'
import ProjectShowEnabledOptionsTab from '@/views/project/tabs/show/ProjectShowEnabledOptionsTab.vue'
import ProjectShowOptionSettingsTab from '@/views/project/tabs/show/ProjectShowOptionSettingsTab.vue'
import ProjectShowExtraOptionsTab from '@/views/project/tabs/show/ProjectShowExtraOptionsTab.vue'
import ProjectShowDocumentsTab from '@/views/project/tabs/show/ProjectShowDocumentsTab.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import ProjectShowReportsTab from '@/views/project/tabs/show/ProjectShowReportsTab.vue'
import ProjectEditDiariesTab from '@/views/project/tabs/edit/ProjectEditDiariesTab.vue'
import ProjectShowDiariesTab from '@/views/project/tabs/show/ProjectShowDiariesTab.vue'
import ProjectShowDatesTab from '@/views/project/tabs/show/ProjectShowDatesTab.vue'
import ProjectMembers from '@/views/project/ProjectMembers.vue'

@Component({
  components: { ProjectMembers, ProjectShowDatesTab, ProjectShowDiariesTab, ProjectShowReportsTab, ActivityLogTable, ProjectShowDocumentsTab, ProjectShowExtraOptionsTab, ProjectShowOptionSettingsTab, ProjectShowEnabledOptionsTab, ProjectShowLayoutSettingsTab, ProjectShowBlocksTab, ProjectShowDetailsTab }
})

export default class ProjectShow extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', clearCurrent: 'clearCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('projectShowActiveTab') || 'details'
  buildings:Building[] = []
  loadingBuildings = false
  productTypes = productTypes

  buildingColumns:Column[] = [
    {
      title: 'Bouwnummer',
      key: 'number',
      dataIndex: 'number',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Modulair',
      key: 'modular',
      scopedSlots: { customRender: 'modular' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Ruwbouw',
      key: 'carcass',
      scopedSlots: { customRender: 'carcass' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Afbouw',
      key: 'completion',
      scopedSlots: { customRender: 'completion' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Type',
      key: 'product_type',
      dataIndex: 'product_type',
      scopedSlots: { customRender: 'productType' },
      sorter: false
    },
    {
      title: 'Status',
      key: 'state_label',
      dataIndex: 'state_label',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey]).then(() => {
      this.getBuildings()
    })
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('projectShowActiveTab', tab)
    this.currentTab = tab
  }

  getBuildings () : void {
    this.loadingBuildings = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id + '&limit=250').then((res) => {
      this.buildings = res.data.data
    }).finally(() => {
      this.loadingBuildings = false
    })
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('buildings.edit')) {
            const route = this.$router.resolve({ name: 'buildingEdit', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('buildings.view')) {
            const route = this.$router.resolve({ name: 'buildingShow', params: { buildingId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  openExternalUrl (url:string) : void {
    window.open(url, '_blank')
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
