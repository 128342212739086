



























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import 'moment/locale/nl'
import axios from 'axios'
import Building, { buildingPhaseStates, objectTypes, productTypes, saleStates } from '@/types/building'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'
moment.locale('nl')

@Component({
  methods: {
    buildingPhaseStates () {
      return buildingPhaseStates
    },
    saleStates () {
      return saleStates
    },
    objectTypes () {
      return objectTypes
    },
    productTypes () {
      return productTypes
    }
  },
  components: {}
})

export default class BuildingBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]
  @Prop() selectedBuildings!:Building[]

  saving = false
  downloading = { buildingSpecification: false, pvo: false }

  showUpdateModal = false
  currentTab = 'details'

  bulkUpdate:Record<string, (string|number|boolean|moment.Moment|null)> = {
    product_type: null,
    object_type: null,
    state: null,
    zipcode: null,
    city: null,
    end_modular: null,
    status_modular: null,
    end_carcass: null,
    status_carcass: null,
    end_completion: null,
    status_completion: null,
    ready_for_invoicing: null
  }

  toggleUpdateModal () : void {
    this.showUpdateModal = !this.showUpdateModal
    this.currentTab = 'details'
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|boolean|moment.Moment|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null && this.bulkUpdate[key] !== '') {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/buildings/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        this.toggleUpdateModal()
        notification.success({ message: 'Bouwnummers bijgewerkt.', description: 'De bouwnummers zijn succesvol opgeslagen.', duration: 3 })
        this.resetBulkUpdateValues()
      }).finally(() => {
        this.saving = false
      })
    }
  }

  resetBulkUpdateValues () : void {
    this.bulkUpdate = {
      product_type: null,
      object_type: null,
      state: null,
      zipcode: null,
      city: null,
      end_modular: null,
      status_modular: null,
      end_carcass: null,
      status_carcass: null,
      end_completion: null,
      status_completion: null,
      ready_for_invoicing: null
    }
  }

  downloadBuildingSpecifications () : void {
    this.downloading.buildingSpecification = true
    this.selectedBuildings.forEach((building:Building) => {
      axios.get(process.env.VUE_APP_API_URL + '/buildings/' + building.id + '/pdf/specification', { responseType: 'arraybuffer' })
        .then(res => {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Meterkastlijst_P' + building.project?.reference + '_B' + building.number + '.pdf'
          link.click()
        }).finally(() => {
          this.downloading.buildingSpecification = false
        })
    })
  }

  downloadPvos () : void {
    this.downloading.pvo = true
    axios.post(process.env.VUE_APP_API_URL + '/buildings/bulk/pvo', { building_ids: this.selectedIds }, { responseType: 'arraybuffer' }).then((r) => {
      const blob = new Blob([r.data], { type: 'application/zip' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Export_pvo_' + new Date().toLocaleDateString('nl-NL') + '.zip'
      link.click()
    }).finally(() => {
      this.downloading.pvo = false
    })
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
