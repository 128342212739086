

































import { Vue, Component, Prop } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building, {
  buildingCustomerMediaFolders,
  buildingMediaFolders,
  buildingTenantMediaFolders
} from '@/types/building'
import Uploader from '@/components/media/Uploader.vue'
import axios from 'axios'
import Filelist from '@/components/media/Filelist.vue'
import Project, { projectMediaFolders } from '@/types/project'
import Customer, { customerMediaFolders } from '@/types/customer'
import Tenant, { tenantMediaFolders } from '@/types/tenant'
import ConnectCustomer from '@/components/customers/ConnectCustomer.vue'
import ConnectTenant from '@/components/customers/ConnectTenant.vue'

@Component({
  components: { ConnectTenant, ConnectCustomer, Filelist, Uploader }
})

export default class BuildingDocumentsTab extends Vue {
  @Prop({ type: Boolean, default: false }) editable!: boolean

  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  activeTab:string = new SecureLS({ isCompression: false }).get('buildingEditDrawingsActiveTab') || 'floorplans'

  directories = [
    {
      name: 'Plattegronden',
      position: 'floorplans',
      subDirectories: [
        {
          name: 'Begane grond',
          position: 'floorplanLevel0'
        },
        {
          name: '1e verdieping',
          position: 'floorplanLevel1'
        },
        {
          name: '2e verdieping',
          position: 'floorplanLevel2'
        },
        {
          name: '3e verdieping',
          position: 'floorplanLevel3'
        }
      ]
    },
    {
      name: 'Gevelaanzichten',
      position: 'facades',
      subDirectories: [
        {
          name: 'Voorgevel',
          position: 'frontFacade'
        },
        {
          name: 'Achtergevel',
          position: 'rearFacade'
        },
        {
          name: 'Linker zijgevel',
          position: 'leftFacade'
        },
        {
          name: 'Rechter zijgevel',
          position: 'rightFacade'
        }
      ]
    }
  ]

  newFileUploaded (file:File, key:string, important = false, concept = false) : void {
    const formData = new FormData()
    formData.append('position', key)
    formData.append('file', file)

    if (important) {
      formData.append('important', 'yes')
    }

    if (concept) {
      formData.append('concept', 'yes')
    }

    axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/media', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    })
  }

  setActiveTab (panel:string) : void {
    new SecureLS({ isCompression: false }).set('buildingEditDrawingsActiveTab', panel)
    this.activeTab = panel
  }

  reloadBuilding () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
