










import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import { Base64MediaFile } from '@/types/media'

@Component({
  components: {}
})

export default class SimplePreview extends Vue {
  @Prop({ required: true }) file!:Base64MediaFile|null
  @Prop({ default: false }) permissionToDelete!:boolean

  deleteImage () : void {
    this.$emit('delete')
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }
}
