









































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import moment from 'moment'
import 'moment/locale/nl'
import BuildingEditDatesDeliveryTab from '@/views/building/tabs/edit/BuildingEditDatesDeliveryTab.vue'
moment.locale('nl')

@Component({
  components: { BuildingEditDatesDeliveryTab }
})

export default class BuildingEditDatesTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
