








































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'

@Component({
  components: {}
})

export default class ProjectShowReportsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  openExternalUrl (url:string) : void {
    window.open(url, '_blank')
  }

  downloadFormResults (slug:string) : void {
    axios.get(process.env.VUE_APP_API_URL + '/projects/' + this.currentItem.id + '/formResults/' + slug).then((res) => {
      window.open(res.data, '_blank')
    })
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
