























































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import axios from 'axios'
import moment from 'moment/moment'

class Notification {
  public id!:string
  public read_at!:string|null
  public ago!:string
  public data!:{
    title:string,
    body:string,
    model:string,
    model_id:number,
    sender: string,
    route: string,
    class: string
  }
}

@Component({
  components: {}
})

export default class NotificationCenter extends Vue {
  moment = moment;
  showNotificationCenter = false
  notifications:Notification[] = []
  unreadCount = 0
  interval = 0
  onHold = true

  mounted () : void {
    this.getNotifications()
    this.interval = setInterval(() => {
      if (!this.onHold) {
        this.getNotifications()
      }
    }, 60000)
  }

  getNotifications () : void {
    axios.get(process.env.VUE_APP_API_URL + '/notifications').then((res) => {
      this.notifications = res.data.notifications
      this.unreadCount = res.data.unread_count
    })
  }

  markAsRead (id:string) : void {
    axios.get(process.env.VUE_APP_API_URL + '/notifications/' + id + '/mark').then(() => {
      this.getNotifications()
    })
  }

  markAllAsRead () : void {
    axios.get(process.env.VUE_APP_API_URL + '/notifications/read').then(() => {
      this.getNotifications()
    })
  }

  deleteNotification (id:string) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/notifications/' + id).then(() => {
      this.getNotifications()
    })
  }

  deleteAllNotifications () : void {
    axios.delete(process.env.VUE_APP_API_URL + '/notifications').then(() => {
      this.getNotifications()
    })
  }

  toggleNotificationCenter () : void {
    this.showNotificationCenter = !this.showNotificationCenter
  }

  getRoutePath (notification: Notification): string {
    let route = ''

    switch (notification.data.model) {
      case 'issue':
        route = '/meldingen/' + notification.data.model_id + '/bewerken'
        break
      case 'question':
        route = '/vragen/' + notification.data.model_id + '/bewerken'
        break
      case 'objection':
        route = '/bezwaren/' + notification.data.model_id + '/bewerken'
        break
      case 'feedback':
        route = '/feedback/' + notification.data.model_id
        break
      case 'release':
        route = '/releases/' + notification.data.model_id
        break
    }

    return route
  }

  destroyed () : void {
    clearInterval(this.interval)
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }

  notificationClass (notification: Notification):string {
    let activeClass: string
    switch (notification.data.model) {
      case 'issue':
      case 'question':
      case 'objection':
        activeClass = 'wo-blue'
        break
      case 'feedback':
        activeClass = 'wo-green'
        break
      default:
        activeClass = 'wo-orange'
    }
    return `${activeClass} ${activeClass}-glow`
  }
}
