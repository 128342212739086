











































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import ProjectWebsite from '@/types/projectWebsite'
import User from '@/types/user'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import axios from 'axios'
import PageHeader from '@/components/base/PageHeader.vue'

@Component({
  components: { PageHeader, ActiveFilters }
})

export default class ProjectWebsites extends Vue {
  settings = {
    routeNames: { create: 'projectWebsiteCreate', show: 'projectWebsiteShow', edit: 'projectWebsiteEdit' },
    routeParamKey: 'projectWebsiteId',
    permissionNames: { show: 'projectwebsites.view', edit: 'projectwebsites.edit', delete: 'projectwebsites.delete' },
    functionNames: { getList: 'getProjectWebsiteList' },
    getterNames: { list: 'projectWebsiteList', loading: 'loadingProjectWebsite' },
    localStorageName: 'projectWebsiteTableSettings'
  }

  columns:Column[] = [
    {
      title: 'Project',
      key: 'project',
      dataIndex: 'project',
      scopedSlots: { customRender: 'project' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Url',
      key: 'url',
      dataIndex: 'url',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Onderhoudsmodus',
      key: 'maintenance_mode',
      dataIndex: 'maintenance_mode',
      scopedSlots: { customRender: 'maintenance_mode' },
      filters: [
        {
          text: 'Aan',
          value: 1
        },
        {
          text: 'Uit',
          value: 0
        }
      ],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  tableSettings:TableSettings = new SecureLS({ isCompression: false }).get(this.settings.localStorageName) || {
    pagination: { current: 1, pageSize: 25 },
    showSizeChanger: true,
    pageSizeOptions: ['25', '50', '100'],
    activeFilters: null,
    sort: null,
    search: null
  }

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.onTableSettingsChange()
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  // Required for ActiveFilters component
  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.refresh()
  }

  refresh () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.edit, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            const route = this.$router.resolve({ name: this.settings.routeNames.show, params: { [this.settings.routeParamKey]: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  navigateToExternalUrl (url:string) : void {
    window.open('https://' + url, '_blank')
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get itemList () : { data: ProjectWebsite[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `Koper ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.settings.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.settings.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
