




































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Survey from '@/types/survey'
import SurveyEditDetailsTab from '@/views/survey/tabs/edit/SurveyEditDetailsTab.vue'
import SurveyEditResultMedia from '@/views/survey/components/SurveyEditResultMedia.vue'
import axios from 'axios'
import { notification } from 'ant-design-vue'

@Component({
  components: { SurveyEditResultMedia, SurveyEditDetailsTab }
})

export default class SurveyEdit extends Vue {
  settings = {
    routeNames: { list: 'surveys', show: 'surveyShow', edit: 'surveyEdit' },
    routeParamKey: 'surveyId',
    functionNames: { setCurrent: 'setCurrentSurvey', clearCurrent: 'clearCurrentSurvey', save: 'saveSurvey' },
    getterNames: { current: 'currentSurvey', loading: 'loadingSurvey' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('surveyEditActiveTab') || 'details'
  downloading = false
  regenerating = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  destroyed () : void {
    this.$store.dispatch(this.settings.functionNames.clearCurrent)
  }

  save () : void {
    this.$store.dispatch(this.settings.functionNames.save, this.currentItem)
  }

  downloadSurveyPdf () : void {
    window.open(this.currentItem.media.pdf.url, '_blank')
  }

  regenerateSurveyPdf () : void {
    this.regenerating = true
    axios.get(process.env.VUE_APP_API_URL + '/surveys/' + this.currentItem.id + '/regenerate/pdf').then(() => {
      this.regenerating = false
      notification.success({ message: 'Pdf opnieuw gegenereerd.', description: 'De pdf is succesvol opgeslagen.', duration: 3 })
      this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
    }).catch(() => {
      notification.error({ message: 'Fout tijdens genereren van pdf.', description: 'Er is iets mis gegaan. Probeer het later nog eens.', duration: 0 })
    }).finally(() => {
      this.regenerating = false
    })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('surveyEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get currentItem () : Survey {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
