






































































































































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import Column from '@/types/column'
import axios from 'axios'
import ExtraOption from '@/types/extraOption'
import moment from 'moment'
import Building from '@/types/building'
import TextEditor from '@/components/base/TextEditor.vue'

@Component({
  components: { TextEditor }
})

export default class ProjectEditExtraOptionsTab extends Vue {
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      dataIndex: 'external_ref',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Optie',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Budget',
      key: 'budget',
      scopedSlots: { customRender: 'budget' }
    },
    {
      title: 'Prijs',
      key: 'price',
      scopedSlots: { customRender: 'price' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'BTW',
      key: 'vat_rate',
      scopedSlots: { customRender: 'vat_rate' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Sluitingsdatum',
      key: 'closing_date',
      scopedSlots: { customRender: 'closing_date' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bouwnummer(s)',
      key: 'buildings',
      scopedSlots: { customRender: 'buildings' },
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  loadingExtraOptions = false
  loadingBuildings = false
  showCreateModal = false
  showEditModal = false

  extraOptions:ExtraOption[] = []
  defaultExtraOption:{ [key: string]: string|number|string[]|number[]|moment.Moment|null } = {
    id: null,
    external_ref: null,
    name: null,
    name_sales: null,
    price: 0,
    vat_rate: 21,
    text_sales: null,
    closing_date: null,
    building_ids: [],
    project_id: null
  }

  extraOption = this.defaultExtraOption

  buildings:Building[] = []

  mounted () : void {
    if (this.currentItem.id) {
      this.getExtraOptions()
    }
  }

  getExtraOptions () : void {
    this.loadingExtraOptions = true

    axios.get(process.env.VUE_APP_API_URL + '/extraOptions?filter[project_id]=' + this.currentItem.id).then((res) => {
      this.extraOptions = res.data.data
    }).finally(() => {
      this.loadingExtraOptions = false
    })
  }

  createExtraOption () : void {
    this.showCreateModal = true
    this.getBuildings()
    this.extraOption = {
      id: null,
      external_ref: null,
      name: null,
      name_sales: null,
      price: 0,
      vat_rate: 21,
      text_sales: null,
      closing_date: null,
      building_ids: [],
      project_id: this.currentItem.id
    }
  }

  storeExtraOption () : void {
    axios.post(process.env.VUE_APP_API_URL + '/extraOptions', this.extraOption).then(() => {
      this.getExtraOptions()
      this.hideModal()
    })
  }

  editExtraOption (item:ExtraOption) : void {
    this.showEditModal = true
    this.getBuildings()
    this.extraOption = {
      id: item.id,
      external_ref: item.external_ref,
      name: item.name,
      name_sales: item.name_sales,
      price: item.price,
      vat_rate: item.vat_rate,
      text_sales: item.text_sales,
      closing_date: item.closing_date ? moment(item.closing_date) : null,
      building_ids: item.building_ids,
      project_id: item.project_id
    }
  }

  updateExtraOption () : void {
    axios.patch(process.env.VUE_APP_API_URL + '/extraOptions/' + this.extraOption.id, this.extraOption).then(() => {
      this.getExtraOptions()
      this.hideModal()
    })
  }

  deleteExtraOption (id:number) : void {
    axios.delete(process.env.VUE_APP_API_URL + '/extraOptions/' + id).then(() => {
      this.getExtraOptions()
    })
  }

  hideModal () : void {
    this.showCreateModal = false
    this.showEditModal = false
    this.extraOption = this.defaultExtraOption
  }

  getBuildings () : void {
    this.loadingBuildings = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings?filter[project_id]=' + this.currentItem.id + '&limit=250').then((res) => {
      this.buildings = res.data.data
    }).finally(() => {
      this.loadingBuildings = false
    })
  }

  contentChanged (key:string, value:string) : void {
    this.extraOption[key] = value
  }

  openBudgetTab () : void {
    this.$emit('changeTab', { tab: 'budgets', subTab: 'extra_option_budgets' })
  }

  moment (dateTime:string, format:string) : moment.Moment {
    return moment(dateTime, format)
  }

  get currentItem () : Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
