
































import { Component, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Project from '@/types/project'
import axios from 'axios'
import _ from 'lodash'
import SalesInterestChart, { InterestCategory, InterestData } from '@/components/charts/SalesInterestChart.vue'
import { ApexOptions } from 'apexcharts'
import GeneralReports from '@/views/project/tabs/reports/GeneralReports.vue'
import SalesPreferences from '@/components/charts/SalesPreferences.vue'

@Component({
  components: { SalesPreferences, GeneralReports, SalesInterestChart }
})

export default class ProjectReportsTab extends Vue {
  section = 'reports'
  location = 'projects'
  settings = {
    routeNames: { list: 'projects', show: 'projectShow', edit: 'projectEdit' },
    routeParamKey: 'projectId',
    functionNames: { setCurrent: 'setCurrentProject', save: 'saveProject' },
    getterNames: { current: 'currentProject', loading: 'loadingProject' }
  }

  reportsUrl = 'https://app.powerbi.com/groups/d81858f6-38bc-4d8c-bfb9-440b8a40a223/reports/9a537aec-8855-4da2-a78f-8da1f16c06b0/ReportSection?filter=projects/reference%20eq%20%27' + this.currentItem.reference + '%27'

  loading = {
    interest: false,
    preferences: false
  }

  interestData: InterestData = {
    contact: [],
    inschrijfformulier: [],
    reservelijst: []
  }

  currentTab: string = new SecureLS({ isCompression: false }).get(this.section) || 'general'

  mounted (): void {
    this.getData()
  }

  getData (): void {
    this.getProjectInterestData()
  }

  getProjectInterestData (): void {
    if (this.currentItem.id === undefined) return
    this.loading.interest = true
    axios.get(process.env.VUE_APP_API_URL + '/reports/interest/' + this.currentItem.id).then((res) => {
      this.interestData = _.groupBy(res.data, 'type') as Record<InterestCategory, any>
    }).finally(() => {
      this.loading.interest = false
    })
  }

  get options (): ApexOptions {
    return {
      chart: {
        type: 'bar',
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      noData: {
        text: 'Geen gegevens beschikbaar'
      },
      xaxis: {
        tickPlacement: 'on',
        type: 'category'
      },
      colors: ['#ffd373', '#fd8021', '#e05400', '#8cd0ec', '#4cb6e1', '#1e85ae']
    }
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set(this.section, tab)
    this.currentTab = tab
  }

  openExternalUrl (url: string): void {
    window.open(url, '_blank')
  }

  navigateTo (routeName: string, paramKey: string, id: number | undefined): void {
    const keyboard = window.event as KeyboardEvent
    if (id) {
      const route = this.$router.resolve({ name: routeName, params: { [paramKey]: id.toString() } })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    } else {
      const route = this.$router.resolve({ name: routeName })
      window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
    }
  }

  get currentItem (): Project {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser (): User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get saving (): boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
