






























import { Component, Prop, Vue } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import { Base64MediaFile } from '@/types/media'

@Component({
  components: {}
})

export default class SimpleFileList extends Vue {
  @Prop({ type: Array, default: () => [] }) files!:Base64MediaFile[]
  @Prop({ default: false }) permissionToDelete!:boolean

  showPreviewModal = false
  file:Base64MediaFile|null = null

  togglePreviewModal (file:Base64MediaFile|null) : void {
    if (!this.showPreviewModal && file) {
      if (file.type === 'application/pdf') {
        window.open(file.url, '_blank')
      } else {
        this.file = file
        this.showPreviewModal = true
      }
    } else {
      this.file = null
      this.showPreviewModal = false
    }
  }

  deleteMediaItem (file:Base64MediaFile) : void {
    this.$emit('delete', this.files?.indexOf(file))
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get screenWidth () : number {
    return screen.width
  }
}
