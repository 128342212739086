











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
})

export default class DeliveryEntityRow extends Vue {
  @Prop({ required: true }) label!: string
  @Prop({ required: true }) entity!: { name:string, address:string, zipcode:string, city:string }
}
