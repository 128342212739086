

































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Survey from '@/types/survey'
import Project from '@/types/project'
import axios from 'axios'
import TextEditor from '@/components/base/TextEditor.vue'
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

@Component({
  components: { TextEditor }
})

export default class SurveyShowDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'surveys', show: 'surveyShow', edit: 'surveyEdit' },
    routeParamKey: 'surveyId',
    functionNames: { setCurrent: 'setCurrentSurvey', save: 'saveSurvey' },
    getterNames: { current: 'currentSurvey', loading: 'loadingSurvey', errors: 'surveyErrors' }
  }

  get currentItem () : Survey {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get surveyCompleted () : boolean {
    return this.currentItem.status === 4
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
