


















import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'
import axios from 'axios'

@Component({
  components: {}
})

export default class BuildingEditDeliveryLetterTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  scheduledEvent:{ id:number, event:string, scheduled_at:string }|null = null
  loading = false

  mounted () : void {
    this.checkIfDeliveryLetterIsScheduled()
  }

  sendDeliveryLetter () : void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.currentItem.id + '/deliveryLetter/schedule').then((r) => {
      this.scheduledEvent = r.data
    }).finally(() => {
      this.loading = false
    })
  }

  checkIfDeliveryLetterIsScheduled () : void {
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.$route.params.buildingId + '/deliveryLetter/isScheduled').then((r) => {
      this.scheduledEvent = r.data
    })
  }

  cancelDeliveryLetter () : void {
    if (this.scheduledEvent) {
      this.loading = true
      axios.post(process.env.VUE_APP_API_URL + '/buildings/' + this.$route.params.buildingId + '/deliveryLetter/cancel', {
        scheduled_event_id: this.scheduledEvent.id
      }).then(() => {
        this.scheduledEvent = null
      }).finally(() => {
        this.loading = false
      })
    }
  }

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get showSendDeliveryLetter () : boolean {
    return !!(this.currentItem.delivery && this.currentItem.delivery.inspection_scheduled_at && this.currentItem.delivery.delivery_scheduled_at && this.currentItem.customer)
  }
}
