















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Subelement from '@/types/subelement'
import Element from '@/types/element'
import Column from '@/types/column'
import axios from 'axios'
import User from '@/types/user'

@Component({
  components: {}
})

export default class ElementEditSubelementsTab extends Vue {
  settings = {
    routeNames: { list: 'elements', show: 'elementShow', edit: 'elementEdit' },
    routeParamKey: 'elementId',
    functionNames: { setCurrent: 'setCurrentElement', save: 'saveElement' },
    getterNames: { current: 'currentElement', loading: 'loadingElement', errors: 'elementErrors' }
  }

  columns:Column[] = [
    {
      title: 'Referentie',
      key: 'reference',
      dataIndex: 'reference',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  subelement = null
  showAddModal = false
  searchTimer = 0
  subelements:Subelement[] = []

  showAddSubelementModal () : void {
    this.showAddModal = true
  }

  hideAddSubelementModal () : void {
    this.showAddModal = false
    this.subelement = null
  }

  getSubelementDetails (id:number) : void {
    axios.get(process.env.VUE_APP_API_URL + '/subelements/' + id).then((res) => {
      this.subelement = res.data.data
    })
  }

  addSubelement () : void {
    this.$store.dispatch('subelementAddedToElement', this.subelement)
    this.hideAddSubelementModal()
  }

  deleteSubelement (id:number) : void {
    this.$store.dispatch('subelementDeletedFromElement', id)
  }

  searchSubelement (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/subelements?filter[search]=' + val).then((res) => {
          this.subelements = res.data.data
        })
      }, 500)
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('subelements.edit')) {
            const route = this.$router.resolve({ name: 'subelementEdit', params: { subelementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('subelements.view')) {
            const route = this.$router.resolve({ name: 'subelementShow', params: { subelementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Element {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
