




import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import Building from '@/types/building'

@Component({
  components: {}
})

export default class SyncBuildingCustomerToAfasButton extends Vue {
  @Prop({ required: true }) building!:Building

  syncing = false

  syncBuildingCustomerToAfas () : void {
    this.syncing = true
    axios.get(process.env.VUE_APP_API_URL + '/buildings/' + this.building.id + '/sync/customer').then(() => {
      notification.success({ message: 'Koper gesynchroniseerd naar AFAS.', description: 'De koper is succesvol gesynchroniseerd naar AFAS.', duration: 3 })
    }).catch(() => {
      notification.error({ message: 'Koper NIET gesynchroniseerd naar AFAS!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 0 })
    }).finally(() => {
      this.syncing = false
    })
  }
}
