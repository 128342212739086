






























































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Tenant from '@/types/tenant'

@Component({
  components: {}
})

export default class TenantEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'tenants', show: 'tenantShow', edit: 'tenantEdit' },
    routeParamKey: 'tenantId',
    functionNames: { setCurrent: 'setCurrentTenant', save: 'saveTenant' },
    getterNames: { current: 'currentTenant', loading: 'loadingTenant', errors: 'tenantErrors' }
  }

  get currentItem () : Tenant {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
