





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import _ from 'lodash'
import moment from 'moment'

type SeriesData = { name: string, data: number[], labels: string[] }
type DateType = { date: string, type: string }
export type InterestCategory = 'contact' | 'reservelijst' | 'inschrijfformulier'
export type InterestData = Record<InterestCategory, DateType[]>

@Component({
  components: {}
})

export default class SalesInterestChart extends Vue {
  @Prop({ type: String, default: 'bar' }) type!: 'bar' | 'pie' | 'counter'
  @Prop({ type: Boolean, default: false }) totals!: boolean
  @Prop({ default: () => ['#f38929', '#ffc052', '#4cb6e1'] }) colors!: string[]
  @Prop({ default: () => ['jan', 'feb', 'mar', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'] }) labels?: string[]
  @Prop({
    default: () => [{
      contact: [],
      inschrijfformulier: [],
      reservelijst: []
    }]
  }) data!: InterestData

  get series (): Partial<{ name: string; data: number[]; labels: string[] }>[] {
    return [
      _.pick(this.contactSeries, ['name', 'data']),
      _.pick(this.subscribeSeries, ['name', 'data']),
      _.pick(this.reserveSeries, ['name', 'data'])
    ]
  }

  filterByDay = false
  targetMonth = ''

  get options (): ApexOptions {
    const labels = [
      ...this.contactSeries.labels,
      ...this.subscribeSeries.labels,
      ...this.reserveSeries.labels
    ]
    return {
      chart: {
        type: 'bar',
        events: {
          dataPointSelection: (e: any, chart?: any, options?: any) => {
            let serie: SeriesData
            switch (options.seriesIndex) {
              case 1: serie = this.subscribeSeries
                break
              case 2: serie = this.reserveSeries
                break
              default: serie = this.contactSeries
                break
            }

            this.filterByDay = true
            this.targetMonth = serie.labels[options.dataPointIndex]
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      noData: {
        text: 'Geen gegevens beschikbaar'
      },
      xaxis: {
        categories: _.uniq(labels),
        tickPlacement: 'on'
      },
      colors: this.colors
    }
  }

  get pieOptions (): ApexOptions {
    return {
      chart: {
        type: 'pie',
        events: {
          dataPointSelection: (e: any, chart?: any, options?: any) => {
            console.log(options.dataPointIndex)
            console.log(this.series[options.dataPointIndex].name)
          }
        }
      },
      labels: ['contact', 'inschrijfformulier', 'reservelijst'],
      colors: this.colors
    }
  }

  get statData (): { contacts: DateType[], subscriptions: DateType[], reserves: DateType[] } {
    const subscriptions = this.data.inschrijfformulier || []
    const reserves = this.data.reservelijst || []
    const contacts = this.data.contact || []

    return {
      contacts: contacts,
      subscriptions: subscriptions,
      reserves: reserves
    }
  }

  get pieSeries (): number[] {
    return [
      this.statData.contacts.length,
      this.statData.subscriptions.length,
      this.statData.reserves.length
    ]
  }

  get contactSeries (): SeriesData {
    const values = _.countBy(this.data.contact, ({ date }) => moment(date).format(this.filterFormat()))
    return {
      name: 'contact',
      data: _.values(values),
      labels: _.keys(values)
    }
  }

  get reserveSeries (): SeriesData {
    const values = _.countBy(this.data.reservelijst, ({ date }) => moment(date).format(this.filterFormat()))
    return {
      name: 'reserve',
      data: _.values(values),
      labels: _.keys(values)
    }
  }

  get subscribeSeries (): SeriesData {
    const values = _.countBy(this.data.inschrijfformulier, ({ date }) => moment(date).format(this.filterFormat()))
    return {
      name: 'inschrijving',
      data: _.values(values),
      labels: _.keys(values)
    }
  }

  filterFormat (): string {
    let format = 'MM-YYYY'
    if (this.filterByDay) {
      format = 'DD-MM-YYYY'
    }
    return format
  }
}
