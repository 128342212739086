

























import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Layout from '@/types/layout'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class LayoutEditElementsTab extends Vue {
  settings = {
    routeNames: { list: 'layouts', show: 'layoutShow', edit: 'layoutEdit' },
    routeParamKey: 'layoutId',
    functionNames: { setCurrent: 'setCurrentLayout', save: 'saveLayout' },
    getterNames: { current: 'currentLayout', loading: 'loadingLayout', errors: 'layoutErrors' }
  }

  columns:Column[] = [
    {
      title: 'Element',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Commerciële naam',
      key: 'name_sales',
      dataIndex: 'name_sales'
    },
    {
      title: 'Groep',
      key: 'group_text',
      dataIndex: 'group_text'
    },
    {
      title: 'Positie',
      key: 'pivot.position',
      dataIndex: 'pivot.position'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  navigateTo (routeName:string, paramKey:string|undefined, id:number|undefined) : void {
    if (id && paramKey) {
      this.$router.push({ name: routeName, params: { [paramKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes('elements.edit')) {
            const route = this.$router.resolve({ name: 'elementEdit', params: { elementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          } else if (this.authUser.permissions.includes('elements.view')) {
            const route = this.$router.resolve({ name: 'elementShow', params: { elementId: rowData.id.toString() } })
            window.open(route.href, keyboard.ctrlKey ? '_blank' : '_self')
          }
        }
      }
    }
  }

  get currentItem () : Layout {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
