










import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Building from '@/types/building'
import Column from '@/types/column'

@Component({
  components: {}
})

export default class BuildingShowRoomsTab extends Vue {
  settings = {
    routeNames: { list: 'buildings', show: 'buildingShow', edit: 'buildingEdit' },
    routeParamKey: 'buildingId',
    functionNames: { setCurrent: 'setCurrentBuilding', save: 'saveBuilding' },
    getterNames: { current: 'currentBuilding', loading: 'loadingBuilding' }
  }

  columns:Column[] = [
    {
      title: 'Verdieping',
      key: 'level',
      dataIndex: 'level_name'
    },
    {
      title: 'Positie',
      key: 'position',
      dataIndex: 'position'
    },
    {
      title: 'Ruimte',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      key: 'room_type',
      dataIndex: 'room_type.name'
    },
    {
      title: 'Oppervlakte',
      key: 'square_meter',
      dataIndex: 'square_meter',
      scopedSlots: { customRender: 'square_meter' }
    }
  ]

  get currentItem () : Building {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
