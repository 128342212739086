
















import { Vue, Component, Prop } from 'vue-property-decorator'
import _ from 'lodash'
import axios from 'axios'
import { ApexOptions } from 'apexcharts'

type BuildingPreference = {
  data: any,
  email: string,
  preferences: {
    pref1: string,
    pref2: string,
    pref3: string,
    pref4: string,
    pref5: string,
    pref6: string
  }
}

type TableDataItem = {
  id: string
  1: number
  2: number
  3: number
  4: number
  5: number
  6: number
  count: number
  [key: string]: string | number
}

@Component({})
export default class SalesPreferences extends Vue {
  @Prop({ required: true }) projectId!: number
  @Prop({ type: Array, default: () => ['#ffd373', '#fd8021', '#e05400', '#8cd0ec', '#4cb6e1', '#1e85ae'] }) colors!: string[]

  loading = false

  preferenceData!: Record<string, BuildingPreference>
  salesPreferences: { name: string, data: { x: string, y: number }[] }[] = []
  tableData: TableDataItem[] = []

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '1',
      dataIndex: '1',
      key: '1'
    },
    {
      title: '2',
      dataIndex: '2',
      key: '2'
    },
    {
      title: '3',
      dataIndex: '3',
      key: '3'
    },
    {
      title: '4',
      dataIndex: '4',
      key: '4'
    },
    {
      title: '5',
      dataIndex: '5',
      key: '5'
    },
    {
      title: '6',
      dataIndex: '6',
      key: '6'
    },
    {
      title: 'Totaal',
      dataIndex: 'count',
      key: 'count'
    }
  ]

  mounted (): void {
    this.getProjectPreferenceData()
  }

  generateSalesData (values: BuildingPreference[], preferenceKey: string, preferenceName: string) :void {
    const groupedPreferences = _.groupBy(values, preferenceKey)
    const data: { x: string, y: number }[] = []
    let idCounter = 1

    _.forEach(groupedPreferences, (p: BuildingPreference[]) => {
      const preference = parseInt(_.get(p, `[0].${preferenceKey}`))
      let idValue = idCounter.toString()

      for (let i = idCounter; i < preference; i++) {
        this.addValueToTable(idValue, preferenceKey.slice(-1), 0)
        data.push({ x: idValue, y: 0 })
        idCounter++
        idValue = idCounter.toString()
      }

      this.addValueToTable(idValue, preferenceKey.slice(-1), p.length)
      idCounter++
      data.push({ x: idValue, y: p.length })
    })

    this.salesPreferences.push({ name: preferenceName, data })
  }

  addValueToTable (id: string, preference: string, count: number): void {
    const index = this.tableData.findIndex((item) => item.id === id)
    if (index === -1) {
      this.tableData.push({ id, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, count: 0 })
    }
    const item = this.tableData.find((item) => item.id === id)
    if (item) {
      item[preference] = count
      item.count += count
    }
  }

  getProjectPreferenceData (): void {
    this.loading = true
    axios.get(process.env.VUE_APP_API_URL + '/reports/preference/' + this.projectId).then((res) => {
      this.preferenceData = _.groupBy(res.data, 'email') as Record<string, any>
      const values = _.values(this.preferenceData).flat(1)

      this.salesPreferences = []
      this.tableData = []

      this.generateSalesData(values, 'preferences.pref1', 'voorkeur 1')
      this.generateSalesData(values, 'preferences.pref2', 'voorkeur 2')
      this.generateSalesData(values, 'preferences.pref3', 'voorkeur 3')
      this.generateSalesData(values, 'preferences.pref4', 'voorkeur 4')
      this.generateSalesData(values, 'preferences.pref5', 'voorkeur 5')
      this.generateSalesData(values, 'preferences.pref6', 'voorkeur 6')
      this.checkAndAddMissingIds()
    }).finally(() => {
      // the order of preferenceResults should be by name: 'voorkeur 1' to 'voorkeur 6'
      this.salesPreferences = _.sortBy(this.salesPreferences, 'name')
      this.loading = false
    })
  }

  checkAndAddMissingIds (): void {
    const allIds: string[] = []

    // Collect all unique x values
    this.salesPreferences.forEach(group => {
      group.data.forEach(item => {
        if (!allIds.includes(item.x)) {
          allIds.push(item.x)
        }
      })
    })

    // Check each group for missing x values and add them
    this.salesPreferences.forEach(group => {
      allIds.forEach(id => {
        if (!group.data.some(item => item.x === id)) {
          group.data.push({ x: id, y: 0 })
        }
      })
    })
  }

  get sortedTableData (): TableDataItem[] {
    return this.tableData.sort((a, b) => {
      const aId = parseInt(a.id)
      const bId = parseInt(b.id)
      if (aId < bId) {
        return -1
      }
      if (aId > bId) {
        return 1
      }
      return 0
    })
  }

  get preferenceChartOptions (): ApexOptions {
    return {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false
      },
      noData: {
        text: 'Geen gegevens beschikbaar'
      },
      xaxis: {
        tickPlacement: 'on',
        type: 'category'
      },
      colors: this.colors
    }
  }

  get preferenceSeries (): any[] {
    return this.salesPreferences || []
  }
}
