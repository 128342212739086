









































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import { notification } from 'ant-design-vue'
import User from '@/types/user'
import SecureLS from 'secure-ls'

@Component({
  components: {}
})

export default class SurveyBulkUpdater extends Vue {
  @Prop() selectedIds!:number[]

  saving = false
  downloading = false
  destroying = false

  employees:User[] = []
  bulkUpdate:Record<string, (string|number|null)> = {
    scheduled_user_id: null
  }

  mounted () : void {
    this.getEmployees()
  }

  getEmployees () : void {
    axios.get(process.env.VUE_APP_API_URL + '/users?filter[roles.display_name]=Medewerker&limit=2500').then((res) => {
      this.employees = res.data.data
    })
  }

  update () : void {
    this.saving = true
    const data:Record<string, (string|number|null)> = {}

    Object.keys(this.bulkUpdate).forEach(key => {
      if (this.bulkUpdate[key] !== null) {
        data[key] = this.bulkUpdate[key]
      }
    })

    if (Object.keys(data).length !== 0) {
      axios.post(process.env.VUE_APP_API_URL + '/surveys/bulk', { ids: this.selectedIds, data: data }).then(() => {
        this.$emit('bulkUpdateCompleted')
        notification.success({ message: 'Keuringen bijgewerkt.', description: 'De keuringen zijn succesvol opgeslagen.', duration: 3 })
        this.bulkUpdate = {
          scheduled_user_id: null
        }
      }).finally(() => {
        this.saving = false
      })
    }
  }

  destroy () : void {
    this.destroying = true
    this.selectedIds.forEach((id:number) => {
      axios.delete(process.env.VUE_APP_API_URL + '/surveys/' + id).then(() => {
        this.$emit('bulkUpdateCompleted')
      }).finally(() => {
        this.destroying = false
      })
    })
    notification.success({ message: 'Keuringen verwijderd.', description: 'De keuringen zijn succesvol verwijderd.', duration: 3 })
  }

  downloadZip () : void {
    this.downloading = true
    axios.post(process.env.VUE_APP_API_URL + '/surveys/bulk/download', { survey_ids: this.selectedIds }, { responseType: 'arraybuffer' }).then((r) => {
      const blob = new Blob([r.data], { type: 'application/zip' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Export_keuring_pdfs_' + new Date().toLocaleDateString('nl-NL') + '.zip'
      link.click()
    }).finally(() => {
      this.downloading = false
    })
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }
}
