


































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/base/PageHeader.vue'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Delivery, { Room } from '@/types/delivery'
import DeliveryShowResultMedia from '@/views/delivery/components/DeliveryShowResultMedia.vue'
import DeliveryShowResultIssues from '@/views/delivery/components/DeliveryShowResultIssues.vue'

@Component({
  components: { DeliveryShowResultIssues, DeliveryShowResultMedia, PageHeader, ActiveFilters }
})

export default class Deliveries extends Vue {
  settings = {
    routeNames: { create: 'deliveryCreate', show: 'deliveryShow', edit: 'deliveryEdit' },
    routeParamKey: 'deliveryId',
    permissionNames: { show: 'deliveries.view', edit: 'deliveries.edit', delete: 'deliveries.delete' },
    functionNames: { getList: 'getDeliveryList' },
    getterNames: { list: 'deliveryList', loading: 'loadingDelivery' },
    localStorageName: 'deliveryTableSettings'
  }

  get currentItem () : Delivery {
    return this.$store.getters.currentDelivery
  }

  get allRequiredFilled () : boolean[] {
    const allRequiredFilled: boolean[] = []

    this.currentItem.rooms.forEach((r: Room, index: number) => {
      allRequiredFilled[index] = true
      if (r.survey_form) {
        r.survey_form.chapters.forEach((c) => {
          c.questions.forEach((q) => {
            if (q.required && q.answer.answer === null) {
              allRequiredFilled[index] = false
            }
          })
        })
      }
    })

    return allRequiredFilled
  }

  get allRequiredGood () : boolean[] {
    const allRequiredGood: boolean[] = []

    this.currentItem.rooms.forEach((r:Room, index: number) => {
      allRequiredGood[index] = true
      if (r.survey_form) {
        r.survey_form.chapters.forEach((c) => {
          c.questions.forEach((q) => {
            if (q.required && q.answer.answer === 'no') {
              allRequiredGood[index] = false
            }
          })
        })
      }
    })

    return allRequiredGood
  }
}
