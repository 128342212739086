












import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option from '@/types/element'
import Column from '@/types/column'
import User from '@/types/user'

@Component({
  components: {}
})

export default class OptionShowEnabledIfTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      dataIndex: 'external_ref',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Commerciële naam',
      key: 'name_sales',
      dataIndex: 'name_sales',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    }
  ]

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
