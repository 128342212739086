















































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import Option from '@/types/option'
import Column from '@/types/column'
import axios from 'axios'
import User from '@/types/user'

@Component({
  components: {}
})

export default class OptionEditEnabledIfTab extends Vue {
  settings = {
    routeNames: { list: 'options', show: 'optionShow', edit: 'optionEdit' },
    routeParamKey: 'optionId',
    functionNames: { setCurrent: 'setCurrentOption', save: 'saveOption' },
    getterNames: { current: 'currentOption', loading: 'loadingOption', errors: 'optionErrors' }
  }

  columns:Column[] = [
    {
      title: 'Optie nr.',
      key: 'external_ref',
      dataIndex: 'external_ref',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Naam',
      key: 'name',
      dataIndex: 'name',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Commerciële naam',
      key: 'name_sales',
      dataIndex: 'name_sales',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' }
    }
  ]

  option = null
  showAddModal = false
  searchTimer = 0
  options:Option[] = []

  showAddEnabledIfOptionModal () : void {
    this.showAddModal = true
  }

  hideAddEnabledIfOptionModal () : void {
    this.showAddModal = false
    this.option = null
  }

  getOptionDetails (id:number) : void {
    axios.get(process.env.VUE_APP_API_URL + '/options/' + id).then((res) => {
      this.option = res.data.data
    })
  }

  addEnabledIfOption () : void {
    this.$store.dispatch('enabledIfOptionAddedToOption', this.option)
    this.hideAddEnabledIfOptionModal()
  }

  deleteEnabledIfOption (id:number) : void {
    this.$store.dispatch('enabledIfOptionDeletedFromOption', id)
  }

  searchOption (val:string) : void {
    if (val.length >= 3) {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        axios.get(process.env.VUE_APP_API_URL + '/options?filter[search]=' + val).then((res) => {
          this.options = res.data.data
        })
      }, 500)
    }
  }

  get currentItem () : Option {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
