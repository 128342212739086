
























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Question from '@/types/question'
import Customer from '@/types/customer'
import Building from '@/types/building'
import Project from '@/types/project'
import ServiceComments from '@/views/service/comments/ServiceComments.vue'
import ServiceCommentBlock from '@/views/service/comments/ServiceCommentBlock.vue'
import ServiceComment from '@/types/serviceComment'
import RelatedServiceItems from '@/views/service/RelatedServiceItems.vue'
import ActivityLogTable from '@/components/ActivityLogTable.vue'
import { ServiceClasses, ServiceStates, SortedStates, Status } from '@/types/serviceItem'
import Filelist from '@/components/media/Filelist.vue'
import ServiceObjectInfo from '@/views/service/ServiceObjectInfo.vue'
import ServiceDetails from '@/views/service/ServiceDetails.vue'
import ServiceContactInfo from '@/views/service/ServiceContactInfo.vue'
import ServiceBuildingSelector from '@/views/service/ServiceBuildingSelector.vue'
import SubscribeButton from '@/components/base/SubscribeButton.vue'
import SaveButton from '@/components/base/SaveButton.vue'
import PreviewContainer from '@/components/media/PreviewContainer.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import PrioritySelector from '@/views/service/components/PrioritySelector.vue'

@Component({
  components: {
    PrioritySelector,
    PageHeader,
    PreviewContainer,
    SaveButton,
    SubscribeButton,
    ServiceBuildingSelector,
    ServiceContactInfo,
    ServiceDetails,
    ServiceObjectInfo,
    Filelist,
    ActivityLogTable,
    RelatedServiceItems,
    ServiceCommentBlock,
    ServiceComments,
    ServiceCreateComment: ServiceComments
  }
})

export default class QuestionsEdit extends Vue {
  @Watch('currentItem.project.id')
  onProjectChanged () : void {
    if (this.currentItem.project.id === 0) return
    this.$store.dispatch('getQuestionManagers', this.currentItem.project.id)
  }

  serviceClasses = ServiceClasses
  serviceStates = ServiceStates
  sortedStates = SortedStates
  config = {
    entity: 'question',
    entities: 'questions'
  }

  settings = {
    routeNames: { list: 'questions', show: 'questionShow', edit: 'questionEdit' },
    routeParamKey: 'questionId',
    permissionNames: { show: 'questions.view', edit: 'questions.edit' },
    functionNames: { getFilters: 'getQuestionFilters', getList: 'getQuestionList', setCurrent: 'setCurrentQuestion', save: 'saveQuestion' },
    getterNames: { current: 'currentQuestion', loading: 'questionLoading', errors: 'questionErrors', list: 'list', filters: 'objectionFilters' }
  }

  currentTab:string = new SecureLS({ isCompression: false }).get('questionEditActiveTab') || 'details'
  tabs = [
    {
      key: 'details',
      tab: 'Details'
    },
    {
      key: 'related',
      tab: 'Gerelateerd'
    },
    {
      key: 'activity',
      tab: 'Geschiedenis'
    }
  ]

  onTabChange (key:string): void {
    this.currentTab = key
  }

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.setCurrent, this.$route.params[this.settings.routeParamKey])
  }

  get customer () : Customer {
    return this.currentItem.building?.customer || new Customer()
  }

  get building () : Building {
    return this.currentItem.building || new Building()
  }

  get project () : Project {
    return this.currentItem.project || new Project()
  }

  get comments () : ServiceComment[] {
    return this.currentItem.comments || [new ServiceComment()]
  }

  get managers () : { id: number, active: boolean, full_name: string, role: string }[] {
    return this.$store.getters.questionManagers
  }

  get canDelete () : boolean {
    return this.authUser.permissions.includes('questions.delete')
  }

  onBuildingSelected (id: number): void {
    this.$store.dispatch('setQuestionBuilding', id)
  }

  onProjectSelected (id: number): void {
    this.$store.dispatch('setQuestionProject', id)
  }

  onManagerSelected (id: number): void {
    this.$store.dispatch('setQuestionManager', id)
  }

  onCommentSaved (comment:ServiceComment): void {
    if (comment.waitingForResponse) {
      this.$store.dispatch('setQuestionStatus', this.serviceStates.InAfwachting)
    }
  }

  save () : void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { files, ...payload } = this.currentItem
    this.$store.dispatch(this.settings.functionNames.save, payload)
  }

  saveAndReturn (): void {
    this.save()
    this.$router.push({ name: this.settings.routeNames.list })
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('questionEditActiveTab', tab)
    this.currentTab = tab
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  getStatus (): Status {
    return this.serviceClasses.find(s => s.id === this.currentItem.status) || this.serviceClasses[0]
  }

  filterOptions (input:string, option:{componentOptions:{children:{text:string}[]}}) : boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  get currentItem () : Question {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }
}
