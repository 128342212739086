import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import SurveyFormModel from '@/models/SurveyFormModel'
import { notification } from 'ant-design-vue'
import router from '@/router'
import moment from 'moment'
import axios from 'axios'

Vue.use(Vuex)

export const surveyFormStore: Module<any, any> = {
  state: {
    surveyFormList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {},
    form: null,
    questionGroups: [],
    questionCategories: []
  },
  mutations: {
    setSurveyFormList (state, { surveyFormList }) {
      state.surveyFormList = surveyFormList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    },
    updateForm (state, { form }) {
      state.form = form
    },
    addChapter (state) {
      state.form.chapters.push({
        id: null,
        name: null,
        description: null,
        number: state.form.chapters.length + 1,
        questions: [{
          id: null,
          name: null,
          description: null,
          number: 1,
          required: 0,
          show_ntb: 0
        }]
      })
    },
    updateChapter (state, { data }) {
      state.form.chapters[data.chapterIndex] = data.chapter
    },
    removeChapter (state, { data }) {
      state.form.chapters.splice(data.chapterIndex, 1)
    },
    addQuestion (state, { data }) {
      state.form.chapters[data.chapterIndex].questions.push({
        id: null,
        name: null,
        description: null,
        number: state.form.chapters[data.chapterIndex].questions.length + 1,
        required: 0,
        show_ntb: 0,
        inspection_question: false,
        delivery_question: false,
        surveyQuestionGroupIds: []
      })
    },
    updateQuestion (state, { data }) {
      state.form.chapters[data.chapterIndex].questions[data.questionIndex] = data.question
    },
    removeQuestion (state, { data }) {
      state.form.chapters[data.chapterIndex].questions.splice(data.questionIndex, 1)
    },
    setQuestionGroups (state, { data }) {
      state.questionGroups = data
    },
    setQuestionCategories (state, { data }) {
      state.questionCategories = data
    }
  },
  actions: {
    getSurveyFormList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let surveyFormModel = new SurveyFormModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            surveyFormModel = surveyFormModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        surveyFormModel = surveyFormModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        surveyFormModel = surveyFormModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        surveyFormModel = surveyFormModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      surveyFormModel.get().then((surveyFormList) => {
        commit('setSurveyFormList', { surveyFormList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van keuringsformulieren!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentForm ({ commit }, form) {
      commit('updateForm', { form })
    },
    addForm ({ commit }, form) {
      axios.post(process.env.VUE_APP_API_URL + '/surveys/forms/add', form).then((res) => {
        const form = res.data
        commit('updateForm', { form })
        router.push('/keuringsformulier/' + res.data.id)
        notification.success({ message: 'Keuringsformulier opgeslagen.', description: 'Het keuringsformulier is succesvol opgeslagen.' })
      }).catch(() => {
        notification.error({ message: 'Keuringsformulier NIET opgeslagen!', description: 'Er is een onverwachte fout opgetreden. Probeer het later nogmaals.' })
      })
    },
    updateForm ({ commit }, form) {
      commit('updateForm', { form })
    },
    saveForm ({ commit }, form) {
      axios.post(process.env.VUE_APP_API_URL + '/surveys/forms/update', form).then((res) => {
        const form = res.data
        commit('updateForm', { form })
        notification.success({ message: 'Keuringsformulier opgeslagen.', description: 'Het keuringsformulier is succesvol opgeslagen.', duration: 5 })
      }).catch(() => {
        notification.error({ message: 'Keuringsformulier NIET opgeslagen!', description: 'Er is een onverwachte fout opgetreden. Probeer het later nogmaals.' })
      })
    },
    addChapter ({ commit }) {
      commit('addChapter')
    },
    updateChapter ({ commit }, data) {
      commit('updateChapter', { data })
    },
    removeChapter ({ commit }, data) {
      commit('removeChapter', { data })
    },
    addQuestion ({ commit }, data) {
      commit('addQuestion', { data })
    },
    updateQuestion ({ commit }, data) {
      commit('updateQuestion', { data })
    },
    removeQuestion ({ commit }, data) {
      commit('removeQuestion', { data })
    },
    getQuestionGroups ({ commit }) {
      axios.get(process.env.VUE_APP_API_URL + '/surveys/forms/questionGroups').then((res) => {
        const data = res.data
        commit('setQuestionGroups', { data })
      })
    },
    getQuestionCategories ({ commit }) {
      axios.get(process.env.VUE_APP_API_URL + '/surveys/forms/questions/categories').then((res) => {
        const data = res.data
        commit('setQuestionCategories', { data })
      })
    }
  },
  getters: {
    surveyFormList: state => state.surveyFormList,
    loadingSurveyForm: state => state.loading,
    surveyFormErrors: state => state.errors,
    form: state => state.form,
    questionGroups: state => state.questionGroups,
    questionCategories: state => state.questionCategories
  }
}
