

























































































import { Vue, Component } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Customer from '@/types/customer'
import InfoHover from '@/components/dashboard/InfoHover.vue'
import AfasField from '@/components/afas/AfasField.vue'

@Component({
  components: { AfasField, InfoHover }
})

export default class CustomerEditDetailsTab extends Vue {
  settings = {
    routeNames: { list: 'customers', show: 'customerShow', edit: 'customerEdit' },
    routeParamKey: 'customerId',
    functionNames: { setCurrent: 'setCurrentCustomer', save: 'saveCustomer' },
    getterNames: { current: 'currentCustomer', loading: 'loadingCustomer', errors: 'customerErrors' }
  }

  get nameLabel () : string {
    switch (this.currentItem.type) {
      case (1):
        return 'Naam contactpersoon'
      case (2):
        return 'Verenigingsnaam'
      default:
        return 'Bedrijfsnaam'
    }
  }

  get currentItem () : Customer {
    return this.$store.getters[this.settings.getterNames.current]
  }

  get errors () : { [key:string]: string[] } {
    return this.$store.getters[this.settings.getterNames.errors]
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].item
  }

  get saving () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].save
  }
}
